import { Component, OnInit } from '@angular/core';
import { version } from '../../config/config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: [`
  .footer-app {
    padding: 0.25rem 0;
    text-align: center;
    display: flex;
    background-color: white;
}
`]
})
export class FooterComponent {

  fechaCopyright = new Date();
  version = version;

}
