// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, ignoreElements } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

@Injectable({
  providedIn: 'root'
})
export class FacturacionRepuestosService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName' , urlconsulta
    super(http, _usuarioService, _kendoService, _mensajeService
        , 'consultaFacturacionRepuesto', '', 'consultas/facturacion/repuestos');
  }

  /**
   * Servicio para obtener las fechas de requerimiento del servicio
   */
  facturarServicio(numeroFactura: string, idsServicio: any) {

    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json'
    });

    // Arma la url de la petición
    const url = `${url_servicio}/consultas/facturacion/facturar/repuesto/${numeroFactura}/${idsServicio}`;
    return this.http.put(url, null, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          this._mensajeService.exito(response.mensaje);
        }
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
