// Módulos
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { IntlModule } from '@progress/kendo-angular-intl';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
//import { SortablejsModule } from 'angular-sortablejs';
// Módulo de Servicios
import { ServiceModule } from './services/service.module';
// Rutas
import { appRoutes } from './app.routing';
// Componentes de la aplicación
import { AppComponent } from './app.component';

import '@progress/kendo-angular-intl/locales/es-EC/all';
// Tour del sistema
import { TourNgxBootstrapModule } from 'ngx-tour-ngx-bootstrap';

//Maticon
import { MatIconModule } from "@angular/material/icon";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { NavigationModule } from '@progress/kendo-angular-navigation';





@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    IntlModule,
    MaterialModule,
    ServiceModule,
    SharedModule,
    PagesModule,
    //SortablejsModule.forRoot({ animation: 150 }),
    appRoutes,
    TourNgxBootstrapModule.forRoot(),
    MatIconModule,
    ButtonsModule,
    IconsModule,
    TooltipsModule,
    NavigationModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [{
    provide: LOCALE_ID, useValue: 'es-EC'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
