<div class="wrapper">
    <div class="sidebar" data-color="orange" data-background-color="white">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <div class="main-content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div *ngIf="!isMap()">
            <app-footer></app-footer>
        </div>
    </div>
</div>