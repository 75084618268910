import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ListItemModel } from '@progress/kendo-angular-buttons';
import { GridComponent } from '@progress/kendo-angular-grid';
import { EstadoRegistro } from 'src/app/config/estado-registro.enum';
import { Accion } from 'src/app/models/models.index';
import { StatePersistingKendoService } from 'src/app/services/service.index';
import { ColumnSettings } from 'src/app/services/utilidades/kendo/column-settings.interface';
import { GridSettings } from 'src/app/services/utilidades/kendo/grid-settings.interface';


@Component({
  selector: 'app-template-buscar-grid',
  templateUrl: './template-buscar-grid.component.html',
  styles: [
    `
      :host { width: 100%}
      input[type="radio"] {
          margin-left: 8px;
          margin-right: 2px;
          vertical-align: text-bottom;
        }
    `
  ]
})
export class TemplateBuscarGridComponent implements OnInit {

  @Output('cargarCrear') cargarCrear: EventEmitter<any> = new EventEmitter(); //Inicia carga de elementos
  @Output('urlCrear') urlCrear: EventEmitter<any> = new EventEmitter(); //Inicia carga de elementos
  @Output('buscarInfo') buscarInfo: EventEmitter<any> = new EventEmitter(); //Inicia búsqueda por un término 

  @Input('accion') accion: Accion; //Accesos
  @Input('estado') estado: String = 'ACT'; //Accesos
  @Input('menuConfiguracion') menuConfiguracion = []; //Accesos
  @Input('mostrarBusqueda') mostrarBusqueda: boolean = true; //Condicional si muestra input de búsqueda
  @Input('mostrarCrear') mostrarCrear: boolean = true; //Condicional si muestra modal de agregar
  @Input('mostrarExcel') mostrarExcel: boolean = true; //Condicional si muestra descarga Excel
  @Input('mostrarPDF') mostrarPDF: boolean = true; //Condicional si muestra descarga PDF

  // Persitencia GRID
  @Input('grid') grid: GridComponent; //Accesos
  @Input('gridSettings') gridSettings: GridSettings; //Accesos
  activarVistaGuardada = false;

  // Estados
  estadoRegistro = EstadoRegistro;
  @Output('changeEstado') changeEstado: EventEmitter<any> = new EventEmitter(); //Inicia búsqueda por un término 

  // Vista
  @Output('changeSaveVista') changeSaveVista: EventEmitter<any> = new EventEmitter(); //Inicia búsqueda por un término 
  @Output('changeRestaurarVista') changeRestaurarVista: EventEmitter<any> = new EventEmitter(); //Inicia búsqueda por un término 

  // Configuracion
  public configuracionData: ListItemModel[] = [];

  constructor(
    private statePersistingKendoService: StatePersistingKendoService
  ) { }

  ngOnInit(): void {
    this.menuConfiguracion.forEach(menu => {
      this.configuracionData.push({
        text: menu.nombre,
        iconClass: "material-icons " + menu.icono
      })
    })

    this.validarVistaGuardada();

  }

  /**
   * Función que emite para poder ejecutar la carga de información 
   * desde el componente padre.
   */

  cargarInformacion() {
    this.cargarCrear.emit();
  }


  /**
   * Función que emite para poder ejecutar la búsqueda desde el
   * componente padre 
   * @param termino termino necesario para poder realizar la búsqueda, obtenido del input.
   */

  buscarInformacion(termino: String) {
    termino = termino.replace(/'/g, "''");
    this.buscarInfo.emit(termino);
  }

  cambioEstado(estado) {
    this.estado = estado;
    this.changeEstado.emit(this.estado);
  }



  saveVista(){
    this.changeSaveVista.emit();
    setTimeout(() => {
      this.validarVistaGuardada();
    }, 3000);
  }

  restaurarVista(){
    this.changeRestaurarVista.emit();
    setTimeout(() => {
      this.validarVistaGuardada();
    }, 3000);
  }

  validarVistaGuardada(){
    this.activarVistaGuardada = this.statePersistingKendoService.get('gridSettings' + this.gridSettings?.identificador) != null;       
  }


}