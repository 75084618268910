export class PoliticaSeguridad {

    id: string;
    nombre?: string;
    descripcion?: string;
    fechaVigencia?: Date;
    url?: string;
    politicaFinalizada?: boolean;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.descripcion = null;
        this.fechaVigencia = null;
        this.url = null;
        this.politicaFinalizada = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
    }
}
