// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Configuracion
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { MensajesService } from '../utilidades/mensajes.service';


@Injectable({
  providedIn: 'root'
})
export class OportunidaddcgvService {

  private placeholderLoading: boolean = false;

  
  public get placeholderLoadingOportunidades() : boolean {
    return this.placeholderLoading;
  }
  

  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    private http: HttpClient,
    private _usuarioService: UsuarioService,
    private _mensajeService: MensajesService,
  ) { }

  /**
   * Obtiene las oportunidades por ruc desde el dcgv
   */
  obtenerOportunidadesPorEmpresa(idEmpresa: string, start?: number, size?: number, filter?: string) {
    this.placeholderLoading = true;
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/oportunidaddcgv/porempresa/${idEmpresa}`;
    
    //QueryParamas
    let params: HttpParams = new HttpParams();

    if(start){
      params = params.set('start', start);
    }
    if(size){
      params = params.set('size', size);
    }
    if(filter){
      params = params.set('filter', filter);
    }

    return this.http.get(url, { headers, params }).pipe(
      map((response: any) => {
        this.placeholderLoading = false;
        if(response){
          return JSON.parse(response);
        }else{
          return response;
        }
      }),
      catchError(err => {
        this.placeholderLoading = false;
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
