import { EmpresaContratante } from "../empresa-contratante.model";

export class TerminoCondicion {

    id: string;
    nombre?: string;
    descripcion?: string;
    fechaVigencia?: Date;
    urlContrato?: string;
    urlOferta?: string;
    terminoCondicionFinalizada?: boolean;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresaContratante?: EmpresaContratante;


    constructor() {
        this.id = null;
        this.nombre = null;
        this.descripcion = null;
        this.fechaVigencia = null;
        this.urlContrato = null;
        this.urlOferta = null;
        this.terminoCondicionFinalizada = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresaContratante = null;
    }
}
