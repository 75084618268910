import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';


@Injectable({
  providedIn: 'root'
})
export class CalculoHorasTrabajoEmpleadoService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName' , urlconsulta
    super(http, _usuarioService, _kendoService, _mensajeService
        , 'consultaCalculoHorasTrabajoEmpleado', '', 'consultas/recursoshumano/calculohorastrabajoempleado');
  }

  /**
   * Función para obtener las consulta de nivel de rendimiento
   * @param state state kendo
   */
   obtenerHorasTrabajo(state: State) {
    // State
    this.loadingGrid = true;
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/consultas/recursoshumano/calculohorastrabajoempleado/`;

    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, {headers}).pipe(
      map((response: any) => {
        if (response.data) {
          this.loadingGrid = false;
          return response.data
            ? ({
                data: response.data,
                total: parseInt(response.total, 10),
                aggregates: response.aggregates
              } as GridDataResult)
            : response;
        } else {
          this.loadingGrid = false;
          return response;
        }        
      }),
      catchError(err => {
        this.loadingGrid = false;
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener las consulta de nivel de rendimiento
   * @param state state kendo
   */
   obtenerHorasTrabajoKpi(state: State) {
    // State
    this.loadingGrid = true;
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/consultas/recursoshumano/calculohorastrabajoempleadokpi/`;

    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, {headers}).pipe(
      map((response: any) => {
        if (response.data) {
          this.loadingGrid = false;
          return response.data
            ? ({
                data: response.data,
                total: parseInt(response.total, 10),
                aggregates: response.aggregates
              } as GridDataResult)
            : response;
        } else {
          this.loadingGrid = false;
          return response;
        }        
      }),
      catchError(err => {
        this.loadingGrid = false;
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

}
