// Angular
import { Injectable } from '@angular/core';
import { WorkbookSheetRow } from '@progress/kendo-ooxml';
import { DatePipe } from '@angular/common';
import { DateTimeFormatPipe } from '../../pipes/date-time-format.pipe';

@Injectable({
    providedIn: 'root'
})
export class ExportExcelCabeceraService {
    dateTimeFormatpipe: DateTimeFormatPipe = new DateTimeFormatPipe();
    constructor() { }
    /**
     * Forma la Cabecera del Documento de Excel
     * @param rangoFechas Obligatorio fecha de inicio y fin
     * @param numColumnas
     * @param fechaEmision
     * @param nombreConsulta
     */
    public cabeceraReporteExcel(rangoFechas, numColumnas: number, nombreConsulta: string): WorkbookSheetRow[] {
        const fechaEmision = this.dateTimeFormatpipe.transform(new Date(), 'dateTime');
        let fecha1 = null;
        let fecha2 = null;
        if (rangoFechas) {
            fecha1 = rangoFechas.fechaInicio ? this.dateTimeFormatpipe.transform(rangoFechas.fechaInicio, 'date') : null;
            fecha2 = rangoFechas.fechaFin ? this.dateTimeFormatpipe.transform(rangoFechas.fechaFin, 'date') : null;
        }
        const rowsCabecera: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: nombreConsulta,
                        colSpan: numColumnas,
                        fontSize: 16,
                        bold: true,
                        textAlign: 'center',
                        verticalAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'FECHA DE EMISIÓN DEL REPORTE: ',
                        colSpan: numColumnas - 1, textAlign: 'right',
                        background: '#FFFFFF',
                        verticalAlign: 'center'
                    },
                    {
                        value: fechaEmision,
                        textAlign: 'center',
                        background: '#FFFFFF',
                        verticalAlign: 'center'
                    }
                ]

            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        fontSize: 14,
                        value: 'RANGO DE FECHAS',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        value: (fecha1 ? 'FECHA DESDE: ' + fecha1 : '') + (fecha2 ? '                    FECHA HASTA: ' + fecha2 : ''),
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF',
                        verticalAlign: 'center'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        fontSize: 14,
                        value: 'PARÁMETROS DE BÚSQUEDA',
                        background: '#FFFFFF'
                    }
                ]
            },
            {
                cells: [
                    {
                        colSpan: numColumnas,
                        textAlign: 'center',
                        background: '#FFFFFF'
                    }
                ]
            }
        ];
        return rowsCabecera;
    }
}
