import { Checklist } from './checklist.model';
import { Servicio } from '../servicio/servicio.model';
import { Usuario } from '../seguridad/usuario.model';
import { Elemento } from '../inventario/elemento.model';
import { EquipoSistemaAtendido } from '../servicio/equipo-sistema-atendido.model';

export class ChecklistData {
  id?: string;
  identificacion?: string;
  observacion?: string;
  finalizado?: boolean;
  url?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  checklist?: Checklist;
  servicio?: Servicio;
  equipoSistemaAtendido?: EquipoSistemaAtendido;
  usuario?: Usuario;
  elemento?: Elemento;

  constructor() {
    this.id = null;
    this.identificacion = null;
    this.observacion = null;
    this.finalizado = null;
    this.url = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.checklist = null;
    this.servicio = null;
    this.equipoSistemaAtendido = null;
    this.usuario = null;
    this.elemento = null;
  }
}
