// Angular
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Modelo
import {
  EmpresaContratante,
  ProcesoRegistro,
  TerminoCondicion,
  Consentimiento
} from '../../../../../models/models.index';

// Servicio
import {
  EmpresaContratanteService,
  UsuarioService,
  TerminosCondicionesService,
  ConsentimientoService,
} from '../../../../../services/service.index';
import { MensajesService } from '../../../../../services/utilidades/mensajes.service';



@Component({
  selector: 'app-registro-empresa-suscriptora',
  templateUrl: './registro-empresa-suscriptora.component.html',
  styles: []
})
export class RegistroEmpresaSuscriptoraComponent {

  // Inicialización de variables para el proceso
  enProceso = false;
  placeholderLoading = false;
  procesoRegistro: ProcesoRegistro;
  idEmpresaContratante: string;
  empresaContratante: EmpresaContratante;
  genericForm: UntypedFormGroup;
  terminoCondicion: TerminoCondicion;
  aceptacionTerminos: boolean;


  constructor(
    public _empresaContratanteService: EmpresaContratanteService,
    private _formBuilder: UntypedFormBuilder,
    private _terminosCondicionesService: TerminosCondicionesService,
    private _consentimientoService: ConsentimientoService,
    private _usuarioService: UsuarioService,
    private _mensajesService: MensajesService,
    public dialogRef: MatDialogRef<RegistroEmpresaSuscriptoraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.placeholderLoading = true;
    this.procesoRegistro = new ProcesoRegistro();
  }

  ngOnInit(): void {
    this.idEmpresaContratante = this.data.idEmpresaContratante;
    this.getEmpresaContratantePorId(this.idEmpresaContratante);
    this.inicializarFomulario();
    this.getUltimoTerminosCondicionesFinalizados().then(() => {
      this.placeholderLoading = false;
    });
  }

  inicializarFomulario() {
    this.genericForm = this._formBuilder.group({
      id: new UntypedFormControl(null),
      fechaAceptacion: new UntypedFormControl(null),
      usuario: new UntypedFormControl(null),
      politicaSeguridad: new UntypedFormControl(null),
      terminoCondicion: new UntypedFormControl(null),
      fechaCreacion: new UntypedFormControl(null),
      version: new UntypedFormControl(null),
      aceptacionTerminos: new UntypedFormControl(false),
    })
  };

  save() {
    if (
      this.genericForm.dirty &&
      this.genericForm.valid
    ) {
      this.procesoRegistro.enEjecucion();

      const consentimiento: Consentimiento = {
        id: this.genericForm.controls.id.value,
        fechaAceptacion: this.genericForm.controls.fechaAceptacion.value,
        usuario: this.genericForm.controls.usuario.value,
        politicaSeguridad: null,
        terminoCondicion: { id: this.terminoCondicion.id } || null,
        fechaCreacion: this.genericForm.controls.fechaCreacion.value,
        version: this.genericForm.controls.version.value
      };
      this._consentimientoService.save(consentimiento).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.procesoRegistro.finalizado();
            this.updateEmpresaContratante(this.empresaContratante);
          }
        }, error: (err) => {
          console.error(err);
          this.dialogRef.close(false);
        }
      });
    } else if (!this.genericForm.valid) {
      this.genericForm.markAllAsTouched();
    }
  }

  updateEmpresaContratante(empresaContratante: EmpresaContratante) {
    return new Promise((resolve: any, reject) => {
      this._empresaContratanteService.aceptoTerminosCondiciones(empresaContratante, false, this.terminoCondicion.id)
        .subscribe({
          next: (response: any) => {
            if (response.success) {
              this._usuarioService.generarNuevoToken().subscribe(() => {
                this._mensajesService.toastSuccess("Los terminos y condiciones han sido aceptados. Bienvenido.!");
                setTimeout(() => {
                  this._usuarioService.setEmpresaContratanteAceptacionTerminos();
                }, 500);
                location.reload();
                this.procesoRegistro.finalizado();
                resolve();
              });
            }
          }, error: (err) => {
            console.error(err);
          }
        });
    });
  }

  getEmpresaContratantePorId(idEmpresaContratante: string) {
    return new Promise((resolve: any, reject) => {
      this._empresaContratanteService.getByID(idEmpresaContratante).subscribe(resp => {
        this.empresaContratante = resp;
        resolve();
      })
    });
  }

  async getUltimoTerminosCondicionesFinalizados() {
    return new Promise((resolve: any, reject) => {
      this._terminosCondicionesService.getUltimosTerminosCondiciones(this.idEmpresaContratante)
        .subscribe((terminoCondicion: TerminoCondicion) => {
          if (terminoCondicion !== null) {
            this.terminoCondicion = terminoCondicion;
            resolve(this.terminoCondicion);
          } else {
            this.dialogRef.close(false);
            this._mensajesService.advertenciaPersonalizada("Los terminos y condiciones no han sido finalizadas. Comuniquese con el Administrador");
            setTimeout(() => {
              this._usuarioService.logout();
            }, 200);
          }
        });
    });
  }

}
