
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Modelo
import { Acceso } from '../../models/models.index';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { CrudBasicoService } from '../utilidades/crud-basico.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

// Kendo Jquery
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

// SweetAlert
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AccesoService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'acceso', 'acceso');
  }

  /**
  * Función para ordenar los accesos
  * @param {Acceso[]} accesos - Accesos a ordenar
  */
 ordenar(accesos: Acceso[]) {
  // Headers
  const headers = new HttpHeaders({
    'token_autorizado': this._usuarioService.token_autorizado,
    'Content-Type': 'application/json'
  });
  // URL
  const url = `${url_servicio}/${this.tableName}/orden`;
    return this.http.put(url, accesos, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
              Swal.fire('Información', 'Accesos ordenados con éxito', 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
}

  /**
  * Obtiene los accesos principales de acuerdo al perfil del usuario
  */
  obtenerMenuPrincipal() {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/acceso/menuprincipal`;
    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      }));
  }

  /**
  * Obtiene los accesos que se visualizan dentro de cada carta de acuerdo al perfil del usuario
  */
  obtenerMenuSecundario(rutaRaiz: string) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/acceso/menusecundario?rutaRaiz=${rutaRaiz}`;
    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        return response;
      }), catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      }));
  }

  /**
   * Servicio para obtener la ultima accion del orden de acceso
   */
  getLogOrden(): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/logauditoria/${this.tableName}/ACCESO_ORDEN`;
    // Petición get para obtener el ultimo log de la entidad

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

}
