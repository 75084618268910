import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject, interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// Configuración
import { url_servicio } from '../../../config/config';

// Kendo
import { GridDataResult, FilterCellComponent } from '@progress/kendo-angular-grid';
import { toODataString, State, process } from '@progress/kendo-data-query';

// Servicios
import { UsuarioService } from '../../seguridad/usuario.service';
import { KendoUtilsService } from './kendo-utils.service';
import { map, takeUntil, take } from 'rxjs/operators';
import { StateConsulta } from '../../../models/models.index';

@UntilDestroy()
@Injectable()
export class KendoService implements OnDestroy {

  state: State = {
    skip: 0,
    take: 5,
    sort: [ // Orden por defecto
      { dir: 'asc', field: 'nombre' }
    ],
    group: []
  };
  //private _destroyed$ = new Subject();
  private _destroyed$ = new Subject<void>()

  constructor(
    private http: HttpClient,
    private _usuarioService: UsuarioService,
    public _kendoUtilsService: KendoUtilsService) {
    interval(1000).pipe(untilDestroyed(this)).subscribe();
  }
  public ngOnDestroy(): void {
    this._destroyed$.next();
    //this._destroyed$.next(null);
    this._destroyed$.complete();
  }
  // Función para obtener información para la tabla de kendo principal de cada módulo
  public queryGrid(tableName: string, state: State = this.state, url?: string): Observable<GridDataResult> {

    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    if (!url) {
      url = `${url_servicio}/${tableName.toLowerCase()}/kendo/`;
      url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    }

    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        if (isNaN(state.take)) {
          state.take = response.data.length;
        }
        return (<GridDataResult>{
          data: process(response.data, {
            group: state.group
          }).data,
          total: parseInt(response.total, 10),
          aggregates: response.aggregates
        });
      }), takeUntil(this._destroyed$));
  }

  public queryGridRefactoring(tableName: string, state: State = this.state, url?: string): Observable<GridDataResult> {

    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    // this._kendoUtilsService.customizeStateGrid(tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    if (!url) {
      url = `${url_servicio}/${tableName.toLowerCase()}/kendo/`;
      url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    }

    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        return response.data ?
          (<any>{
            data: response.data,
            total: parseInt(response.total, 10),
            aggregates: response.aggregates,
          }) : response;
      }), takeUntil(this._destroyed$));
  }

  public queryGridPlanificador(tableName: string, state: State = this.state, url?: string): Observable<GridDataResult> {

    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    // this._kendoUtilsService.customizeStateGrid(tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    if (!url) {
      url = `${url_servicio}/${tableName.toLowerCase()}`;
      url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    }

    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        return response.data ?
          (<any>{
            data: response.data,
            total: parseInt(response.total, 10),
            aggregates: response.aggregates,
          }) : response;
      }), takeUntil(this._destroyed$));
  }

  // Función para obtener información para la tabla de kendo desde modal
  public queryModal(tableName: String, state: State = this.state, url?: string): Observable<GridDataResult> {

    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    if (!url) {
      url = `${url_servicio}/${tableName.toLowerCase()}/modal/`;
      url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    }

    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        return (<GridDataResult>{
          data: response.data,
          total: parseInt(response.total, 10)
        });
      }), takeUntil(this._destroyed$));
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  // Función para obtener información para la tabla de kendo principal de cada módulo
  public queryGridConsulta(tableName: string, state: StateConsulta, url?: string): Observable<any> {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    if (!url) {
      url = `${url_servicio}/${tableName.toLowerCase()}/kendo/`;
      url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    } else {
      url = `${url_servicio}/${url}/`;
      url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    }
    return this.http.get(url, { headers })
      .pipe(map((response: any) => {
        return response.data ?
          (<any>{
            data: response.data,
            total: parseInt(response.total, 10),
            aggregates: response.aggregates,
          }) : response;
      }), takeUntil(this._destroyed$));
  }

}
