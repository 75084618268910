// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// ENUM
import { EstadoRegistro } from '../../config/estado-registro.enum';
import { MensajeSistema } from '../../config/mensaje-sistema.enum';

// SweetAlert
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class MensajesService {
  constructor(private router: Router) {}

  /**
   * Función para mostrar mensaje cuando el procedimiento a sido completado con éxito.
   * @param {string} mensaje - Información a mostrar al usuario
   */
  exito(mensaje) {
    Swal.fire('Información', mensaje, 'success');
  }

  cerrarVentana(){
    Swal.fire({
      position: "top-end",
      icon: "success",
      toast: true,
      html: `Registro guardado con éxito. <br> La ventana se cerrará automáticamente`,
      showConfirmButton: false,
      timer: 3000,
    });
  }

  /**
   * Función para mostrar mensaje cuando el procedimiento a sido completado con éxito.
   * @param {string} mensaje - Información a mostrar al usuario
   */
  exitoHTML(mensaje) {
    Swal.fire({html: mensaje, icon : 'success'});
  }

  /**
   * Función para mostrar mensaje cuando existe un error menor en el sistema.
   * @param {string} error - Información a mostrar al usuario
   */
  error(error: any) {
    Swal.fire(error.mensaje, error.detalle, 'error');
  }

  /**
   * Función para mostrar mensaje cuando existe un error menor en el sistema.
   * @param {string} error - Información a mostrar al usuario
   */
  informacionRequerida(mensaje: any) {
    Swal.fire('Existen campos obligatorios.', mensaje, 'warning');
  }

  /**
   * Función para mostrar mensaje cuando existe un error crítico en el sistema.
   */
  errorCritico() {
    Swal.fire({
      html: 'Algo salió mal, favor actualiza la página. <br> Si el error persiste comunícate con el Administrador del Sistema.',
      icon: 'warning'
    });
  }

  /**
   * Función para mostrar mensaje de confirmación cuando se requiere cambiar estado de una entidad
   * @param {any} entidad - Entidad a cambiar de estado
   */
  confirmacionCambiarEstado(entidad: any): Promise<any> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Advertencia',
        text:
          entidad.estado === EstadoRegistro.Activo
            ? MensajeSistema.ConfirmacionBorrar
            : MensajeSistema.ConfirmacionRestaurar,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then(confirm => {
        resolve(confirm.value);
      });
    });
  }

  /**
   * Función para mostrar mensaje de confirmación cuando se requiere eliminar una entidad
   */
  confirmacionEliminar(): Promise<any> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Advertencia',
        text: MensajeSistema.ConfirmacionBorrar,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then(confirm => {
        resolve(confirm.value);
      });
    });
  }

  /**
   * Función para mostrar mensaje de confirmación cuando se requiere eliminar una entidad
   */
  confirmacionDuplicar(): Promise<any> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Advertencia',
        text: '¿Está seguro que desea crear una copia?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then(confirm => {
        resolve(confirm.value);
      });
    });
  }

  /**
   * Función para mostrar mensaje de confirmación personalizado
   */
  confirmacionPersonalizado(texto: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Advertencia',
        text: texto,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then(confirm => {
        resolve(confirm.value);
      });
    });
  }

  /**
   * Función para mostrar mensaje de confirmación cuando se requiere cambiar estado de una entidad
   * @param {boolean} redireccionar - Propiedad para redireccionar al dashboard o no
   * @param {string} ruta - ruta de redirreción (Default - /dashboard)
   */
  accesoDenegado(redireccionar: boolean = true, ruta: string = '/dashboard') {
    Swal.fire(
      'Acceso denegado',
      'No tiene permiso para acceder a este módulo',
      'error'
    );
    if (redireccionar) {
      this.router.navigate([`${ruta}`]);
    }
  }
  
  /**
   * Función para mostrar mensaje de confirmación cuando se requiere cambiar estado de una entidad
   * @param {boolean} redireccionar - Propiedad para redireccionar al dashboard o no
   */
  permisoDenegado() {
    Swal.fire(
      'Acceso denegado',
      'No tiene permiso para crear un registro',
      'error'
    );
  }
  /**
   * Función para mostrar mensaje de verificación de dirección cuando se seleciona o se busca una ubicacion en google maps
   */
  advertenciaDireccion() {
    Swal.fire({
      title: 'Advertencia Google Maps',
      text: 'Verificar que la información registrada automáticamente sea la correcta.',
      icon: 'warning'
    });
  }

  /**
   * Función para mostrar mensaje de advertencia con un mensaje personalizado
   */
  advertenciaPersonalizada(mensaje: string, title = 'Advertencia') {
    Swal.fire({
      title: title,
      html: mensaje,
      icon: 'warning'
    });
  }

  /**
   * Función para mostrar mensaje de advertencia cuando existen campos obliggaotiros sin llenar
   */
  advertenciaCamposObligatorios() {
    Swal.fire({
      title: 'Advertencia',
      text: 'Existen campos obligatorios sin información, por favor verificar.',
      icon: 'warning'
    });
  }

  /**
   * Función para mostrar mensaje cuando existe un error crítico en el sistema.
   */
  registroDuplicado(identificador: String) {
    Swal.fire(
      'Registro duplicado',
      'Ya existe un registro con el identificador: ' + identificador,
      'error'
    );
  }
  /**
   * Función para mostrar mensaje de error con un mensaje y titulo personalizado
   */
  errorPersonalizada(mensaje: string, titulo: string) {
    Swal.fire({
      title: titulo,
      html: mensaje,
      icon: 'error'
    });
  }

  // Reestructura
  camposObligatorios() {
    Swal.fire({
      position: "top-end",
      icon: "error",
      toast: true,
      title: "Existen campos requeridos sin registrar",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  /**
   * Función para mostrar mensaje de confirmación personalizado
   */
   confirmacionClosePageNavigate(dirty: boolean, url: string[]) {
    if (dirty) {
      Swal
        .fire({
          icon: "warning",
          text: 'Los cambios efectuados en el formulario no se guardarán. ¿Está seguro de que desea continuar?',
          showCancelButton: true,
          confirmButtonColor: "#ffa726",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            document.body.style.overflow = "";
            this.router.navigate(url);
          }
        });
    } else {
      this.router.navigate(url);
    }
  }

  toastSuccess(mensaje){
    Swal.fire({
      position: "top-end",
      icon: "success",
      toast: true,
      html: `${mensaje}`,
      showConfirmButton: false,
      timer: 5000,
    });
  }

  toastWarning(titulo, mensaje){
    Swal.fire({
      title: titulo,
      position: "top-end",
      icon: "warning",
      toast: true,
      html: `${mensaje}`,
      showConfirmButton: false,
      timer: 3000,
    });
  }

   /**
   * Función para mostrar mensaje cuando el token esta caducado
   */
   tokenCaducado() {
    return Swal.fire({
      toast: true,
      position: 'top-end',
      html: '<b>Sesión caducada!</b> <br> Inicie sesión nuevamente!',
      showConfirmButton: false,
      timer: 3000
    });
  }

  /**
   * Función para mostrar mensaje de la generación del backup
   */
  infoBackup() {
    Swal.fire({
      title: "Generando Backup",
      text: "Una vez finalizado el proceso de backup, recibirá un correo electrónico con el enlace de descarga de los tickets de servicio generados.",
      icon: "info"
    });
  }

}
