<div class="card" *ngIf="url">
    <div class="card-header card-header-icon card-header-warning">
        <div class="card-icon" data-background-color="orange">
            <i class="material-icons">smart_display</i>
        </div>
        <h4 class="card-title">
            {{title}}
        </h4>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <div class="col-12 text-center">
                <iframe width="560" height="315" [src]="url" title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen>
                </iframe>
            </div>
        </mat-dialog-content>
    </div>
    <div class="card-footer">
        <div class="col-12 text-right">
            <button type="button" (click)="closeModal()"
                    class="btn btn-sm btn-danger">
                <i class="material-icons">close</i>
            </button>
        </div>
    </div>
</div>