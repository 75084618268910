<nav #navbar class="navbar navbar-expand-lg navbar-transparent  navbar-absolute">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button mat-raised-button (click)="minimizeSidebar()"
                        class="btn btn-just-icon btn-white btn-fab btn-round">
                    <i
                       class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                    <i
                       class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button>
            </div>
            <!-- ====== -->
            <!-- TITULO -->
            <!-- ====== -->
            <a class="navbar-brand" tourAnchor="nav.titulo">
                <i class="material-icons md-36">{{_navbarService.icon}}</i> {{_navbarService.title}}

                <a *ngIf="_navbarService.titleInfo?.length > 0"><i class="material-icons md-24 help"
                       matTooltip="{{_navbarService.titleInfo}}"
                       matTooltipPosition="above">info</i></a>
            </a>

            <!-- ============ -->
            <!-- FIN - TITULO -->
            <!-- ============ -->
        </div>
        <a class="navbar-toggler" (click)="sidebarToggle()">
            <i class="material-icons md-36">menu</i>
        </a>

        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <!-- <form class="navbar-form">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button mat-raised-button type="submit"
                            class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </form> -->
            <ul class="navbar-nav">
                <li class="nav-item dropdown"
                    *ngIf="_navbarService.materialSoporte && _navbarService.materialSoporte.length > 0">
                    <a class="nav-link" id="navbarDropdownMenuMaterialSoporte"
                       [style.width]="empresasContratantes.total > 1 ? '32px !important' : 'auto'"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons help md-24">contact_support</i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right"
                         aria-labelledby="navbarDropdownMenuMaterialSoporte">
                        <div style="padding: 8px; font-size: 12px;">
                            <b>Aprende como:</b>
                        </div>
                        <a class="dropdown-item"
                           *ngFor="let materialSoporte of _navbarService.materialSoporte"
                           (click)="cargarMaterialSoporte(materialSoporte)">
                            <i class="material-icons help md-18">smart_display</i>
                            <span style="margin-left: 8px;">
                                {{materialSoporte.titulo}}
                            </span>
                        </a>
                    </div>
                </li>

                <li class="nav-item dropdown cursor" *ngIf="empresasContratantes.total > 1">
                    <a class="nav-link" id="navbarDropdownMenuMaterialSoporte"
                       [matTooltip]="'Actualmente se encuentra usando el entorno del proveedor de servicios: ' + empresaContratanteActual"
                       matTooltipPosition="above"
                       matTooltipClass="tooltip-modal"
                       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons help md-24">change_circle</i>
                        <span style="text-decoration: underline !important;">
                            <b>{{empresaContratanteActual}}</b>
                        </span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right"
                         aria-labelledby="navbarDropdownMenuMaterialSoporte">
                        <div style="padding: 8px; font-size: 12px;">
                            <b>Proveedor de servicios:</b>
                        </div>
                        <a class="dropdown-item"
                           *ngFor="let empresaContratante of empresasContratantes.data"
                           (click)="cambiarEmpresaContrante(empresaContratante)">
                            <i class="material-icons help md-18"
                               *ngIf="_usuarioService.idEmpresaContratanteGlobal === empresaContratante.id">radio_button_checked</i>
                            <i class="material-icons help md-18"
                               *ngIf="_usuarioService.idEmpresaContratanteGlobal !== empresaContratante.id">radio_button_unchecked</i>
                            <span style="margin-left: 8px;">
                                {{empresaContratante.nombreCorto}}
                            </span>
                        </a>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</nav>