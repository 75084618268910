// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

@Injectable({
  providedIn: 'root'
})
export class ChecklistDetalleObservacionService  extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'checklistDetalleObservacion', 'checklist_detalle_observacion');
  }

      /**
   * Servicio para obtener los checklist por tipo de categoria
   */
  getByChecklistDataByPregunta(idChecklistData: string, idPregunta: string): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/${idChecklistData}/${idPregunta}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
