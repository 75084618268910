import { EmpresaContratante } from '../empresa-contratante.model';

export class Rol {
    id: string;
    nombre?: string;
    observacion?: string;
    superAdministrador?: boolean;
    administrador?: boolean;
    supervisorServicio?: boolean;
    tecnico?: boolean;
    cliente?: boolean;
    restringirServicios?: boolean;
    recursosHumanos?: boolean;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresaContratante?: EmpresaContratante;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.superAdministrador = null;
        this.administrador = null;
        this.supervisorServicio = null;
        this.tecnico = null;
        this.cliente = null;
        this.recursosHumanos = null;
        this.restringirServicios = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresaContratante = null;
    }
}
