import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placeholder-loading-wizard',
  templateUrl: './placeholder-loading-wizard.component.html',
  styles: []
})
export class PlaceholderLoadingWizardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
