import { Usuario } from "../seguridad/usuario.model";
import { Servicio } from "../servicio/servicio.model";

export class NotificacionEmail {
    id: string;
    titulo?: string;
    descripcion?: string;
    servicio?: Servicio;
    usuario?: Usuario;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;

    constructor() {
        this.id = null;
        this.titulo = null;
        this.descripcion = null;
        this.servicio = null;
        this.usuario = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
    }
}
