<app-placeholder-loading-menu *ngIf="placeholderLoading"></app-placeholder-loading-menu>

<!-- ===== -->
<!-- LOGOS -->
<!-- ===== -->
<div class="logo" [hidden]="placeholderLoading" tourAnchor="start.tour">
    <a [routerLink]="['/dashboard']" class="simple-text ancho-total text-center"
       *ngIf="_navbarService.sidebarOpen">
        <img [src]="_usuarioService.usuario?.empresaContratante?.logoSecundario"
             style='width: 50px;'>
    </a>
    <a [routerLink]="['/dashboard']" class="simple-text ancho-total text-center"
       *ngIf="!_navbarService.sidebarOpen">
        <img [src]="_usuarioService.usuario?.empresaContratante?.logoPrincipal"
             style='max-width: 200px; max-height: 100px !important;'>
    </a>
</div>
<!-- =========== -->
<!-- FIN - LOGOS -->
<!-- =========== -->

<div class="sidebar-wrapper" [hidden]="placeholderLoading">
    <div class="user">
        <!-- ============================ -->
        <!-- FOTOGRAFÍA USUARIO / SUBMENU -->
        <!-- ============================ -->
        <div class="photo">
            <img [src]="_usuarioService.usuario.persona.fotografia" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span style="white-space: normal;">
                    {{_usuarioService.usuario.persona.nombreCompleto}}
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample" style="cursor: pointer;">
                <ul class="nav">
                    <li class="nav-item">
                        <a (click)="cargarPerfil()" class="nav-link">
                            <span class="sidebar-mini">
                                <i class="material-icons">account_circle</i>
                            </span>
                            <span class="sidebar-normal">Mi Perfil</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="_usuarioService.isAdministrador()">
                        <a (click)="cargarConfiguracionEmpresaSuscriptora()" class="nav-link">
                            <span class="sidebar-mini">
                                <i class="material-icons">location_city</i>
                            </span>
                            <span class="sidebar-normal">Configuración Sistema</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a (click)="_usuarioService.logout()" class="nav-link">
                            <span class="sidebar-mini">
                                <i class="material-icons">power_settings_new</i>
                            </span>
                            <span class="sidebar-normal">Cerrar Sesión</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- ================================== -->
        <!-- FIN - FOTOGRAFÍA USUARIO / SUBMENU -->
        <!-- ================================== -->
    </div>
    <!-- =========== -->
    <!-- MENU MOVIL -->
    <!-- =========== -->
    <div *ngIf="isMobileMenu()">
        <ul class="nav">
            <li class="nav-item dropdown cursor" *ngIf="empresasContratantes.total > 1">
                <a class="nav-link" id="navbarDropdownMenuMaterialSoporte"
                   [matTooltip]="'Actualmente se encuentra usando el entorno del proveedor de servicios: ' + empresaContratanteActual"
                   matTooltipPosition="above"
                   matTooltipClass="tooltip-modal"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons help md-24">change_circle</i>
                    <span style="text-decoration: underline !important;">
                        <b>{{empresaContratanteActual}}</b>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right"
                     aria-labelledby="navbarDropdownMenuMaterialSoporte">
                    <div style="padding: 8px; font-size: 12px;">
                        <b>Proveedor de servicios:</b>
                    </div>
                    <a class="dropdown-item"
                       *ngFor="let empresaContratante of empresasContratantes.data"
                       (click)="cambiarEmpresaContrante(empresaContratante)">
                        <i class="material-icons help md-18"
                           *ngIf="_usuarioService.idEmpresaContratanteGlobal === empresaContratante.id">radio_button_checked</i>
                        <i class="material-icons help md-18"
                           *ngIf="_usuarioService.idEmpresaContratanteGlobal !== empresaContratante.id">radio_button_unchecked</i>
                        <span style="margin-left: 8px;">
                            {{empresaContratante.nombreCorto}}
                        </span>
                    </a>
                </div>
            </li>
        </ul>        
    </div>
    <!-- ================ -->
    <!-- FIN - MENU MOVIL -->
    <!-- ================ -->

    <!-- ==================== -->
    <!-- MENU PANTALLA NORMAL -->
    <!-- ==================== -->
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
            <!--Si no tiene submenus-->
            <a [routerLink]="[menuitem.raiz.ruta]" tourAnchor="{{menuitem.raiz.id}}"
               *ngIf="menuitem.hijos.length === 0" class="nav-link">
                <i class="material-icons">{{menuitem.raiz.icono}}</i>
                <p>
                    {{menuitem.raiz.nombre}}
                    <!-- <span style="margin-left: 24px !important"
                          class="material-icons animate__animated animate__flash animate__slow animate__infinite"
                          *ngIf="menuitem.raiz.id ==='6480ccd3a27a38271c1a87f952'">
                        fiber_new
                    </span> -->                    
                </p>
            </a>
            <!--Si tiene submenus-->
            <a data-toggle="collapse" href="#{{menuitem.raiz.id}}" *ngIf="menuitem.hijos.length > 0"
               (click)="updatePS()" class="nav-link">
                <i class="material-icons">{{menuitem.raiz.icono}}</i>
                <p>{{menuitem.raiz.nombre}}
                    <b class="caret"></b>
                </p>
            </a>

            <!--Muestra los submenus-->
            <div id="{{menuitem.raiz.id}}" class="collapse" *ngIf="menuitem.hijos.length > 0">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.hijos"
                        class="nav-item">
                        <a [routerLink]="['/'+menuitem.raiz.ruta, childitem.ruta]" class="nav-link">
                            <span class="sidebar-mini">
                                <i class="material-icons">{{childitem.icono}}</i>
                            </span>
                            <span class="sidebar-normal">{{childitem.nombre}}</span>
                        </a>
                    </li>
                </ul>
            </div>

        </li>
        <li>
            <!--Soporte a Usuarios-->
            <hr>
            <a (click)="cargarModalSoporte()" class="nav-link"
               *ngIf="_usuarioService.isMatrizEmpresaContratante()">
                <i class="material-icons">help_center</i>
                <p>
                    Material Soporte                    
                </p>
            </a>
        </li>
        <li>
            <a href="https://dcgservicios.statuspage.io/" target="_blank"
               class="nav-link">
                <i class="material-icons">wifi_tethering</i>
                <p>Status <span style="text-transform: lowercase !important;">decisioncloud</span>
                </p>
            </a>

            <!-- <a data-toggle="collapse" href="#ayuda" (click)="updatePS()" class="nav-link">
                <i class="material-icons">help</i>
                <p>Ayuda
                    <b class="caret"></b>
                </p>
            </a>
            <div id="ayuda" class="collapse">
                <ul class="nav">
                    <li class="nav-item" routerLinkActive="active"
                        *ngIf="_usuarioService.isMatrizEmpresaContratante()">
                        <a [routerLink]="['/notificacion/noticias-dcgs']" class="nav-link">
                            <i class="material-icons">new_releases</i>
                            <p>Noticias</p>
                        </a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" style="cursor: pointer;"
                        *ngIf="_usuarioService.isMatrizEmpresaContratante()">
                        <a (click)="cargarModalSoporte()" class="nav-link">
                            <i class="material-icons">help_center</i>
                            <p>Material de Apoyo</p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.youtube.com/channel/UC6MznukFXF0UwgKya0DIaqA?view_as=subscriber"
                           target="_blank" class="nav-link">
                            <i class="material-icons">help_outline</i>
                            <p>Soporte</p>
                        </a>
                    </li>
                    <li class="nav-item">

                    </li>
                </ul>
            </div> -->
            <!--FIN - Soporte a Usuarios-->
        </li>
    </ul>
    <!-- ========================== -->
    <!-- FIN - MENU PANTALLA NORMAL -->
    <!-- ========================== -->
</div>