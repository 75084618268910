export class GraficoHorasServicioCliente {
    fechaInicio: Date;
    fechaFin: Date;
    idEmpresaContratante: string;
    idsCliente: string[];
    idsTipoServicio: string[];
    constructor() {
        this.fechaInicio = null;
        this.fechaFin = null;
        this.idsCliente = [];
        this.idsTipoServicio = [];
    }
}
