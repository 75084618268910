import { GrupoCampoServicio } from './grupo-campo-servicio.model';

export class CampoServicio {
  id: string;
  nombre?: string;
  observacion?: string;
  orden?: number;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  grupoCampoServicio?: GrupoCampoServicio;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.orden = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.grupoCampoServicio = new GrupoCampoServicio();
  }
}
