import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sinFoto'
})
export class SinFotoPipe implements PipeTransform {
  /**
   * Función para validar si existe la imagen de las entidades del sistema
   * @param value URL de la imagen
   * @param args Nombre de la entidad para validar la imagen, por defecto Usuario
   */
  transform(value: any, args: string = 'usuario'): any {
    switch (args) {
      case 'usuario':
        if (value) {
          return value;
        } else {
          return '../../assets/img/defaultUser.png';
        }
    }
    return null;
  }

}
