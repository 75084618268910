<div *ngIf="placeholderLoading" class="spinner-center">
  <mat-spinner diameter="50"></mat-spinner>
  <p>Cargando archivos...</p>
</div>

<div *ngIf="!placeholderLoading">
  <div class="row">
    <!-- ============== -->
    <!-- CAROUSEL IMG -->
    <!-- ============== -->
    <div class="col-12 text-center" *ngIf="archivosAdjuntos2.length > 0">
      <carousel [dots]="true"
                [loop]="true"
                [cellsToShow]="1"
                [autoplayInterval]="5000"
                [lightDOM]="false"
                [transitionDuration]="1000"
                arrowsTheme="dark"
                [height]="275"
                *ngIf="!placeholderLoading">
        <div class="carousel-cell text-center"
             *ngFor="let img of archivosAdjuntos2; let i = index">

          <a *ngIf="archivosAdjuntos2.length > 0"
             class="btn-eliminar-carousel"
             (click)="eliminarArchivoAdjunto(img)"
             matTooltip="Eliminar imagen"
             matTooltipPosition="above">
            <i class="material-icons md-24">close</i>
          </a>
          <a *ngIf="archivosAdjuntos2.length > 0"
             class="btn-zoom-carousel"
             (click)="abrirImagenNuevaPestana(img)"
             matTooltip="Zoom Imagen"
             matTooltipPosition="above">
            <i class="material-icons md-24">zoom_in</i>
          </a>

          <img *ngIf="!img?.url" style="max-width: 80% !important;"
               [src]=" img?.url|| img?.amazonS3Parameters?.base64 | sanitizedHtml: 'url'">
          <img *ngIf="img?.url" style="max-width: 80% !important;" [src]="img?.url">
        </div>
      </carousel>
    </div>
    <!-- ============== -->
    <!-- CAROUSEL IMG -->
    <!-- ============== -->
    <div class="col-12 text-center">
      <div class="col-12" *ngIf="archivosAdjuntos2.length == 0">
        <img src="../../../assets/img/default_image.png"
             style="height: 100%; width: 100%;  max-width: 400px;">
      </div>
      <div class="col-12">
        <button type="button"
                matTooltip="Añadir imagen"
                class="btn btn-sm btn-rounded btn-primary "
                (click)="cargarFilePicker()">
          <i class="material-icons">add</i> Añadir
        </button>
      </div>
    </div>
  </div>

  <!-- ===================== -->
  <!-- SELECCION DEL ARCHIVO -->
  <!-- ===================== -->
  <div class="row" *ngIf="!procesoRegistro.enDetalle && !procesoRegistro.enProceso">
    <div class="col-lg-10 col-md-11 col-12 ml-auto mr-auto">
      <input id="pickerFile{{identificador}}"
             name="pickerFile{{identificador}}"
             accept="image/png, image/gif, image/jpeg"
             type="file"
             ng2FileSelect
             (onFileSelected)="onFileSelected($event)"
             [uploader]="uploader"
             multiple
             [hidden]="true" />
      <br />
      <br />
    </div>
  </div>
  <!-- ===================== -->
  <!-- SELECCION DEL ARCHIVO -->
  <!-- ===================== -->
</div>