<form class="animated fadeIn fast"
      [formGroup]="genericForm"
      (ngSubmit)="save()">
    <div class="card">
        <div class="card-header card-header-icon card-header-warning">
            <div class="card-icon" data-background-color="orange">
                <i class="material-icons">gavel</i>
            </div>
            <h4 class="card-title">
                <small>PÓLITICA DE SEGURIDAD</small>
            </h4>
        </div>
        <div class="card-body">
            <mat-dialog-content>
                <div class="row justify-content-center align-items-center">
                    <div class="col text-center">
                        <img id="imgPrincipal" class="imgPrincipal"
                             src="../../../assets/img/politicas-seguridad-1.png"
                             style="max-height: 200px; width: auto; display: block; margin: 0 auto;">
                    </div>

                    <div class="col text-left">
                        <mat-checkbox formControlName="aceptacionPolitica" id="aceptacionPolitica"
                                      name="aceptacionPolitica">
                            <label for="aceptacionPolitica" class="form-check-label">
                                He leído y acepto las
                                <a [href]="urlPolitica"
                                   target="_blank">
                                    <strong>{{nombrePolitica}}</strong>
                                </a>
                            </label>
                        </mat-checkbox>
                    </div>

                </div>
            </mat-dialog-content>
        </div>
        <div class="card-footer">
            <div class="col-lg-6 col-md-6 text-left">
                <div class="category form-category">
                    <span style="color: red">*</span> Campos requeridos
                </div>
            </div>
            <div class="col-lg-6 col-md-6 text-right">
                <button type="submit" class="btn btn-sm btn-primary"
                        [disabled]="!genericForm.controls.aceptacionPolitica.value">
                    <i class="material-icons" *ngIf="!enProceso">save</i>
                    <i class="material-icons fa-spin" *ngIf="enProceso">sync</i>
                </button>
                <button type="button" (click)="logout()" [disabled]="procesoRegistro.enProceso"
                        class="btn btn-sm btn-danger">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>
</form>