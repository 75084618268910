// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


// rxjs
import { map, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

//Kendo
import { State } from '@progress/kendo-data-query';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

@Injectable({
  providedIn: 'root'
})
export class PlanificadorServicioService extends AbtractCrudBasicoService {

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'planificadorservicios', '');
  }

  getKendoServiciosPrevistos(state: State) {
    this.loadingGrid = true;
    // URL
    const url = `${this.pathName.toLowerCase()}/previstos/`;

    return this._kendoService.queryGridPlanificador(url, state).pipe(
      map(data => {
        if (state.take && isNaN(state.take)) {
          state.take = data.data.length;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => new Error(err));
      })
    );
  }

  getKendoServiciosProgramados(state: State) {
    this.loadingGrid = true;
    // URL
    const url = `${this.pathName.toLowerCase()}/programados/`;

    return this._kendoService.queryGridPlanificador(url, state).pipe(
      map(data => {
        if (state.take && isNaN(state.take)) {
          state.take = data.data.length;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => new Error(err));
      })
    );
  }
}
