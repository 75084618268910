export class Noticia {
    id: string;
    titulo?: string;
    descripcion?: string;
    fechaCreacion?: Date;
    fechaLanzamiento?: Date;
    lanzamiento?: boolean;
    estado?: string;
    version?: number;

    constructor() {
        this.id = null;
        this.titulo = null;
        this.descripcion = null;
        this.fechaCreacion = null;
        this.fechaLanzamiento = null;
        this.lanzamiento = null;
        this.estado = null;
        this.version = null;
    }
}
