export class Periodo {
    id: string;
    nombre?: string;
    observacion?: string;
    fechaCreacion?: Date;
    version?: number;
    estado?: string;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.version = null;
    }
}
