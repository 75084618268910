import { Servicio } from './servicio.model';
import { Elemento } from '../inventario/elemento.model';

export class EquipoSistemaRequerido {
  id?: string;
  atendido?: boolean;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  elemento?: Elemento;

  constructor() {
    this.id = null;
    this.atendido = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.elemento = null;
  }
}
