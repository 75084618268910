<form #form="ngForm" class="animated fadeIn fast">
    <div class="card">
        <div class="card-header card-header-icon card-header-warning">
            <div class="card-icon" data-background-color="orange">
                <i class="material-icons">visibility</i>
            </div>
            <h4 class="card-title">
                Vista Previa
            </h4>
        </div>
        <div class="card-body">
            <mat-dialog-content>                
                <quill-editor id="tooltip" name="tooltip" style="margin: 10px;" class="ancho-total"
                [readOnly]="true" [(ngModel)]="data.texto" #modelRef="ngModel"></quill-editor>
            </mat-dialog-content>
        </div>
        <div class="card-footer">
            <div class="col-12 text-right">
                <button type="button" (click)="closeModal()" class="btn btn-sm btn-danger">
                        <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>
</form>