// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';
// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { RepuestoUtilizado } from '../../models/models.index';

@Injectable({
  providedIn: 'root'
})
export class RepuestoUtilizadoService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'repuestoUtilizado', 'repuestos_utilizados');
  }

  getRepuestosUtilizados(idEquipoSistemaAtendido: string): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${
      this.pathName.toLowerCase()
    }/porequiposistemaatendido/${idEquipoSistemaAtendido}`;
    return this.http.get(url, { headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  saveRepuestosUTilizados(repuestosUtilizados: RepuestoUtilizado[]) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.pathName.toLowerCase()}`;

    // Editar
    if (repuestosUtilizados[0].fechaCreacion) {
      return this.http.put(url, repuestosUtilizados[0], { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            this._mensajeService.exito(response.mensaje);
          } else {
            this._mensajeService.error(response.error);
          }
          return response;
        }),
        catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    } else { // Crear
      return this.http.post(url, repuestosUtilizados, { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            this._mensajeService.exito(response.mensaje);
          } else {
            this._mensajeService.error(response.error);
          }
          return response;
        }),
        catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    }
  }

}
