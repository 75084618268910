import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './pages.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpPetitionInterceptor } from '../services/guards/http-petition-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TourNgxBootstrapModule } from 'ngx-tour-ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TourNgxBootstrapModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpPetitionInterceptor, multi: true }
  ],
  declarations: [PagesComponent]
})
export class PagesModule { }
