import { EmpresaContratante } from '../empresa-contratante.model';
import { TipoContrato } from './tipo-contrato.model';
import { DiaCobertura } from './dia-cobertura.model';

export class AcuerdoServicio {
    id?: string;
    nombre?: string;
    codigo?: string;
    fechaInicio?: Date;
    fechaVencimiento?: Date;
    facturacionMinimaHoraMes?: number;
    facturacionMinimaServicio?: number;
    incluyeRepuesto?: boolean;
    otrosIncluye?: string;
    noIncluye?: string;
    tiempoRespuesta?: Date;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    valorHoraTecnico?: number;
    horaTecnicoIndividual?: boolean;
    frecuenciaFacturacion?: number;
    empresaContratante?: EmpresaContratante;
    tipoContrato?: TipoContrato;
    diaCobertura?: DiaCobertura;
    constructor() {
        this.id = null;
        this.nombre = null;
        this.codigo = null;
        this.fechaInicio = null;
        this.fechaVencimiento = null;
        this.facturacionMinimaHoraMes = null;
        this.facturacionMinimaServicio = null;
        this.incluyeRepuesto = false;
        this.otrosIncluye = null;
        this.noIncluye = null;
        this.tiempoRespuesta = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.valorHoraTecnico = 0;
        this.horaTecnicoIndividual = false;
        this.frecuenciaFacturacion = null;
        this.empresaContratante = null;
        this.tipoContrato = null;
    }
}
