// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

// @ts-ignore
import imageCompression from 'browser-image-compression';

@Injectable({
  providedIn: 'root'
})
export class ArchivoAdjuntoGenericoService extends AbtractCrudBasicoService {

  // Variables para el manejo de archivos adjuntos
  archivosErrorMimeType: string;

  optionsFile = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    super(
      http,
      _usuarioService,
      _kendoService,
      _mensajeService,
      'archivoadjunto',
      'archivo_adjunto'
    );
  }

  /**
   * Función para crear o editar una entidad
   * @param {any} Entidad - Entidad a crear o editar
   * @param {any} notificar - Propiedad para notificar la transacción (por defecto = true)
   */
  saveArchivoAdjunto(entidad: any) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}`;
    // Petición post para crear la entidad
    return this.http.post(url, entidad, { headers }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
  * Servicio para obtener los equipos requerimientos
  */
  getArchivosAdjuntosPorElemento(idElemento: string, visualizarCarousel: boolean): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()
      }/elemento/${idElemento}/${visualizarCarousel}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => err);
      })
    );
  }


  /**
   * Servicio para obtener archivos adjuntos por entidad y ID
   */
  getArchivosAdjuntosPorEntidad(entidad: string, id: any): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });

    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/${entidad}/${id}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => response),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => err);
      })
    );
  }

  /**
   * Servicio para obtener archivos adjuntos por comentario
   */
  // getArchivosAdjuntosPorComentario(idComentario: string) {
  //   return this.getArchivosAdjuntosPorEntidad('comentario', idComentario);
  // }

  // /**
  //  * Servicio para obtener archivos adjuntos por acuerdo servicio
  //  */
  // getArchivosAdjuntosPorAcuerdoServicio(idAcuerdo: string) {
  //   return this.getArchivosAdjuntosPorEntidad('acuerdo', idAcuerdo);
  // }

  /**
   * Servicio para obtener archivos adjuntos por empresa contratante
   */
  // getArchivosAdjuntosPorEmpresaContratante(idEmpresacontratante: string) {
  //   return this.getArchivosAdjuntosPorEntidad('empresacontratante', idEmpresacontratante);
  // }

  /**
  * Servicio para obtener los equipos requerimientos
  */
  // getArchivosAdjuntosPorComentario(idComentario): any {
  //   // Obtiene el header de la petición
  //   const headers = new HttpHeaders({
  //     token_autorizado: this._usuarioService.token_autorizado,
  //     'Content-Type': 'application/json'
  //   });
  //   // Arma la url de la petición
  //   const url = `${url_servicio}/${this.pathName.toLowerCase()
  //     }/comentario/${idComentario}`;
  //   return this.http.get(url, { headers }).pipe(
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError(err => {
  //       this._mensajeService.errorCritico();
  //       return throwError(() => err);
  //     })
  //   );
  // }

  /**
  * Servicio para obtener los equipos requerimientos
  */
  // getArchivosAdjuntosPorAcuerdoServicio(idAcuerdo): any {
  //   // Obtiene el header de la petición
  //   const headers = new HttpHeaders({
  //     token_autorizado: this._usuarioService.token_autorizado,
  //     'Content-Type': 'application/json'
  //   });
  //   // Arma la url de la petición
  //   const url = `${url_servicio}/${this.pathName.toLowerCase()
  //     }/acuerdo/${idAcuerdo}`;
  //   return this.http.get(url, { headers }).pipe(
  //     map((response: any) => {
  //       return response;
  //     }),
  //     catchError(err => {
  //       this._mensajeService.errorCritico();
  //       return throwError(() => err);
  //     })
  //   );
  // }

  deleteByIdArchivoAdjuntoGenerico(idEntidad: string, idComponente: string, notificar: boolean = true) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/archivoadjunto/${idEntidad}/${idComponente}`;

    // Petición put para cambiar el estado de la entidad
    return this.http.delete(url, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          if (notificar) {
            this._mensajeService.exito(response.mensaje);
          }
        } else {
          this._mensajeService.error(response.error);
        }
        return response.success;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => err);
      })
    );
  }

  /**
   * Funicion para saber si un archivo es de tipo imagen
   * @param fileExtn
   */
  isImage(fileExtn) {
    switch (fileExtn.toLowerCase()) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
      case 'image/webp':
        return true;
    }
  }

  /**
   * Funicion para validar si el archivo adjunto es permitido
   * @param fileExtn
   */
  validateMimeType(fileType: string, mimeType: any) {
    if ((fileType != null && fileType.length > 0) && (mimeType.notAllowedMimeType.indexOf(fileType) !== -1)) {
      return false;
    }
    return true;
  }

  /**
  * Función que retorna el archivo con formato requerido por AmazonS3
  */
  getFile(file, archivoAdjunto, mimeType) {
    return new Promise(async (resolve, reject) => {
      if (this.validateMimeType(file.type, mimeType)) {
        // Comprime imagenes
        if (this.isImage(file.type)) {
          file = await imageCompression(file, this.optionsFile);
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          archivoAdjunto.amazonS3Parameters = {
            filetype: file.type,
            filename: file.name,
            filesize: file.size,
            base64: (<string>reader.result).split(',')[1],
          };
          resolve('');
        };
      } else {
        // Almacena los nombre de los archivos que superaron el tamaño máximo
        this.archivosErrorMimeType +=
          '<li>' + file.name + ' <b>(extensión no permitida)</b></li>';
      }
    });
  }


  saveArchivoAdjuntoChild(imgenes: any, idElementoChild) {
    return new Promise((resolve, reject) => {
      const promises = [];
      imgenes.forEach(archivoAdjuntoTmp => {
        //Se asigan el id del Elemento para agregar los archivos adjuntos
        archivoAdjuntoTmp.elemento = idElementoChild;
        promises.push(
          new Promise((resolve2, reject2) => {
            this.saveArchivoAdjunto(archivoAdjuntoTmp)
              .subscribe(
                resp => {
                  resolve2('');
                },
                err => {
                  reject2();
                }
              );
          })
        );
      });
      Promise.all(promises)
        .then(() => {
          resolve('');
        })
        .catch(err => {
          reject();
        });
    });
  }
}
