
import { GrupoCampoServicio } from './grupo-campo-servicio.model';
import { TipoRecargo } from '../personas/tipo-recargo.model';
import { HoraServicioTrabajo } from './hora-servicio-trabajo.model';

export class CalculoHorarioTrabajoServicio {
    id: String;
    totalHoras?: number;
    totalHorasReajuste?: number;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    horaServicioTrabajo?: HoraServicioTrabajo;
    tipoRecargo?: TipoRecargo;
    //Transient
    totalHorasCopia?:number ;
  constructor() {
    this.id = null;
    this.totalHoras = null;
    this.totalHorasReajuste = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.horaServicioTrabajo = null;
    this.tipoRecargo = null;
    this.totalHorasCopia = null;
  }
}
