//Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

//Kendo
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { url_servicio } from '../../config/config';

@Injectable({
  providedIn: 'root'
})
export class ConsultaReporteHorasTrabajasSinTransporteService extends AbtractCrudBasicoService {

  constructor(
    public http: HttpClient,
    public usuarioService: UsuarioService,
    public kendoService: KendoService,
    public mensajeService: MensajesService
  ) { 
    // http, usuarioService, kendoService, mensajeService, 'pathName', 'tableName'
    super(
      http,
      usuarioService,
      kendoService,
      mensajeService,
      'consultareportehorastrabajadastsintransporte',
      'consulta_reporte_horas_trabajadas_sin_transporte'
    );
  }

  /**
   * Función para obtener las consulta de horas de trabajo sin transporte
   * @param state state kendo
  */
  obtenerReporteHorasTrabajadasSinTransporte(state: State) {
    // State
    this.loadingGrid = true;
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this.usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });
    // URL
    let url = `${url_servicio}/${this.pathName.toLowerCase()}/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, {headers}).pipe(
      map((response: any) => {
        if (response.data) {
          this.loadingGrid = false;
          return response.data
            ? ({
                data: response.data,
                total: response.total,
                aggregates: response.aggregates
              } as GridDataResult)
            : response;
        } else {
          this.loadingGrid = false;
          return response;
        }        
      }),
      catchError(err => {
        this.loadingGrid = false;
        this.mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
