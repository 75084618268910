// Angular
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';

// RXJS
import { BehaviorSubject } from 'rxjs';

// Modelos
import { Menu, EmpresaContratante, Empresa } from '../../models/models.index';

// Servicios
import {
  AccesoService,
  NavbarService,
  EmpresaContratanteService,
  UsuarioService,
} from '../../services/service.index';
import { TourService } from 'ngx-tour-ngx-bootstrap';

// KENDO
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

import {
  isAmbienteDemo,
  idEmpresaContratanteDecision,
} from '../../config/config';

import {
  configuracionDemoBienvenida,
  configuracionDemoConsultas,
  configuracionDemoServicio,
} from '../../config/demo-tour';
import { VideoSoporteComponent } from '../video-soporte/video-soporte.component';
import { SizeModal } from 'src/app/config/size-modal.enum';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

// Jquery
declare const $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent implements OnInit {
  // Inicialización de variables para el proceso
  @ViewChild('app-navbar', { static: false }) button: any;
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public menuItems: Menu[] = [];
  location: Location;
  isAmbienteDemo = isAmbienteDemo;
  // Empresa Contratante
  filtroEmpresaContratante = '';
  empresaContratanteTmp: EmpresaContratante = new EmpresaContratante();
  empresasContratantes: GridDataResult = { data: [], total: 0 };
  stateSubjectEmpresaContratante = new BehaviorSubject<string>(null);
  public stateEmpresaContratante: State = {
    skip: 0,
    take: 90,
    sort: [
      // Orden por defecto
      { dir: 'asc', field: 'nombreCorto' },
    ],
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  empresaContratanteActual = '';

  // Ayuda
  materialSoporte = [];

  /**
   * Contructor del componente
   * @constructor
   * @param {AccesoService} _accesoService Servicio de acceso.
   * @param {NavbarService} _navbarService Servicio de navbar para el cambio de titulo e icono
   * @param {EmpresaContratanteService} _empresaContratanteService Servicio de empresa contratante.
   * @param {MatDialog} dialog Diálogo Material.
   */
  constructor(
    location: Location,
    private elementRef: ElementRef,
    private _accesoService: AccesoService,
    public _navbarService: NavbarService,
    public _usuarioService: UsuarioService,
    private _empresaContratanteService: EmpresaContratanteService,
    public tourService: TourService,
    private dialog: MatDialog,
    private router: Router,

  ) {
    this.location = location;
    this.nativeElement = elementRef.nativeElement;
    this.sidebarVisible = false;
    this.cargarEmpresaContratante().then(() => {
      for (const obj of this.empresasContratantes.data) {
        if (obj.id === this._usuarioService.idEmpresaContratanteGlobal) {
          this.empresaContratanteTmp = obj;
        }
      }
    });
    if (this.isAmbienteDemo) {
      this.iniciarTourAutomaticamente();
    }
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];

    if (misc.sidebar_mini_active === true) {
      this._navbarService.updateSidebar(false);
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      this._navbarService.updateSidebar(true);
      setTimeout(function () {
        body.classList.add('sidebar-mini');

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  hideSidebar() {
    const body = document.getElementsByTagName('body')[0];
    const sidebar = document.getElementsByClassName('sidebar')[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove('hide-sidebar');
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove('animation');
      }, 600);
      sidebar.classList.add('animation');
    } else {
      setTimeout(function () {
        body.classList.add('hide-sidebar');
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    const navbar: HTMLElement = this.elementRef.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }

    this._accesoService
      .obtenerMenuPrincipal()
      .subscribe((menuItems) => (this.menuItems = menuItems));
    // DEMO TOUR
    this.tourBienvenida();
  }

  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle() {
    let titlee: any = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.split('?')[0];
    for (let i = 0; i < this.menuItems.length; i++) {
      if (this.menuItems[i].raiz.ruta === titlee) {
        return this.menuItems[i].raiz.nombre;
      } else if (this.menuItems[i].hijos.length > 0) {
        for (let j = 0; j < this.menuItems[i].hijos.length; j++) {
          const subtitle = `${this.menuItems[i].raiz.ruta}/${this.menuItems[i].hijos[j].ruta}`;
          if (subtitle === titlee) {
            return this.menuItems[i].hijos[j].nombre;
          }
        }
      }
    }
    return 'Dashboard';
  }

  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }

  ///////////////////////////////
  ////  Empresas Contrantes ////
  /////////////////////////////

  /**
   * Función para cargar las Empresas Contrantes
   */
  cargarEmpresaContratante(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._empresaContratanteService
        .getModal(this.stateEmpresaContratante)
        .subscribe((empresasContratantes) => {         
          this.empresasContratantes = empresasContratantes;
          this.cargarEmpresaActual();
          resolve('');
        });
    });
  }

  cargarEmpresaActual(){
    let empresaContratanteTmp: any = this.empresasContratantes.data.filter(empresaContratante => empresaContratante.id == this._usuarioService.idEmpresaContratanteGlobal);
    this.empresaContratanteActual = empresaContratanteTmp[0].nombreCorto;          
  }
  /**
   * (PRUEBA) Cambio de empresa contratante solo para el super administrador
   */
  cambiarEmpresaContrante(empresaContratante) {
    this._usuarioService.idEmpresaContratanteGlobal = empresaContratante.id;
    this._usuarioService
    .generarNuevoToken()
    .subscribe((resp) => {
      if(resp){
        this.router.navigate(['/dashboard']).then(()=>{
          window.location.reload();
        });
      }
    });
  }
  /**
   * Función para cargar el filtro de Empresas Contratantes
   */
  filterChangeEmpresaContratante(e) {
    this.filtroEmpresaContratante = e.toUpperCase();
    this.stateSubjectEmpresaContratante.next(e);
  }
  //=============================
  // Sección para el uso del Demo
  //=============================
  /**
   * Inicializa automaticamente el tour si el id de la empresa contratante es diferente a DCA
   */
  iniciarTourAutomaticamente() {
    if (
      idEmpresaContratanteDecision !==
      this._usuarioService.getEmpresaContratanteDefault()
    ) {
      setTimeout(() => {
        this.tourService.start();
      }, 2000);
    }
  }
  /**
   * Configuracion
   */
  tourBienvenida() {
    this.tourService.initialize(configuracionDemoBienvenida);
    this.tourService.start();
  }
  tourServicio() {
    this.tourService.initialize(configuracionDemoServicio);
    this.tourService.start();
  }
  tourConsultas() {
    this.tourService.initialize(configuracionDemoConsultas);
    this.tourService.start();
  }

  /**
   * Función que permite cargar el modal de un registro
   * @param {string} idTipoEstadoServicio Identificador de tipoEstadoServicio.
   */
  cargarMaterialSoporte(materialSoporte): void {
    this.dialog.open(VideoSoporteComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.large,
      data: {
        title: materialSoporte.titulo,
        url: materialSoporte.url
      }
    });    
  }
}
