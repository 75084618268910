import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styles: []
})
export class TooltipComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TooltipComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  /**
   * Función para cerrar el modal
   */
  closeModal() {
    this.dialogRef.close();
  }

}
