import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../../services/service.index';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styles: []
})
export class LoadingComponent {

  public active: boolean;

  public constructor(loading: LoadingService) {
    loading.status.subscribe((status: boolean) => {
      this.active = status;
    });
  }

}
