import { DateTimeFormatPipe } from './../../../pipes/date-time-format.pipe';
import {
  Component,
  OnInit,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ProcesoRegistro, Accion  } from '../../../models/models.index';

import { saveAs } from '@progress/kendo-file-saver';
import { geometry, fit, exportPDF, Group } from '@progress/kendo-drawing';
import { orderBy } from '@progress/kendo-data-query';

function mm(val: number): number {
  return val * 2.8347;
}

import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-donut',
  templateUrl: './donut.component.html',
  styleUrls: ['../../../../assets/css/kendo-pdf-export.css']
})
export class DonutComponent implements OnInit, AfterViewInit {
  @ViewChild('graficoPdf', { static: false }) graficoPdf: any;
  @ViewChild('kendoChart', { static: false }) kendoChart: any;

  placeholderLoading: boolean;
  titulo = 'Gráficos';
  tipoGrafico = 'donut';
  headerHtml;
  height: number;
  procesoRegistro: ProcesoRegistro;
  rango: any;
  accion: Accion;

  values = [];

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DonutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private elRef: ElementRef,
    private dateTimeFormatpipe: DateTimeFormatPipe
  ) {
    this.placeholderLoading = true;
    this.procesoRegistro = new ProcesoRegistro();
    this.rango = this.data.rango;

    this.accion = this.data.accion;
    // Titulo
    this.data.titulo
      ? (this.titulo = this.data.titulo)
      : (this.titulo = 'Gráfico');

    this.data.tipoGrafico
      ? (this.tipoGrafico = this.data.tipoGrafico)
      : (this.tipoGrafico = 'donut');

    this.headerHtml = this.data.header;
    data.data = orderBy(data.data, [{ field: 'items.length', dir: 'desc' }]);
    this.data.data.forEach(dataTmp => {
      this.values.push({
        categoria: dataTmp.value,
        cantidad: dataTmp.items.length
      });
    });
    this.height = data.data.length * 15;
    this.placeholderLoading = false;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // document.getElementById('idTituloConsulta').innerHTML
    //   = '<strong _ngcontent-c2='' class='font-bold'>' + this.titulo + '</strong>';
  }

  labelContent(e: any): string {
    if (e.percentage) {
      return (e.percentage * 100).toFixed(2) + '%' + ' - ' + e.value;
    } else {
      return e.value;
    }
  }

  getHtml(html) {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  /**
   * Función para cerrar el modal
   */
  closeModal() {
    this.dialogRef.close(false);
  }

  /**
   * Función para exportar el gráfico a JPG
   */
  exportImg() {
    this.procesoRegistro.enEjecucion();
    const node = document.getElementById('kendoPdf');
    htmlToImage
      .toJpeg(node)
      .then(dataUrl => {
        saveAs(dataUrl, this.obtenerNombreArchivoPng());
        this.procesoRegistro.finalizado();
      })
      .catch(error => {
        console.error('oops, something went wrong!', error);
      });
  }

  /**
   * Obtener el nombre del archivo para exportar a JPG
   */
  obtenerNombreArchivoPng(): string {
    const titulo = this.data.titulo.replace(' ', '_');
    return `dcGS_Gráfico_${titulo}_${this.dateTimeFormatpipe.transform(
      new Date(),
      'getName'
    )}.png`;
  }
}
