// Angular
import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';

// KENDO
import { InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { IntlModule } from '@progress/kendo-angular-intl';
import { TooltipModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DatePickerModule, TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";
import { LabelModule } from "@progress/kendo-angular-label";
import { FloatingLabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { NotificationModule } from "@progress/kendo-angular-notification";



// Idioma
import '@progress/kendo-angular-intl/locales/es-EC/all';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';

@NgModule({
  imports: [
    CommonModule
  ],
  exports:  [
    GridModule,
    DropDownsModule,
    InputsModule,
    ExcelModule,
    DateInputsModule,
    ChartsModule,
    IntlModule,
    DatePickerModule,
    TimePickerModule,
    PDFExportModule,
    ExcelExportModule,
    PDFModule,
    TooltipModule,
    TextBoxModule,
    ScrollViewModule,
    TreeViewModule,
    LabelModule,
    FloatingLabelModule,
    LayoutModule,
    NavigationModule,
    ButtonsModule,
    IconsModule,
    TooltipsModule,
    NotificationModule
  ],
  providers: [{
    provide: LOCALE_ID, useValue: 'es-EC'
  }],
  declarations: []
})
export class KendoModule { }
