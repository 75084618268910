import { Pipe, PipeTransform } from "@angular/core";
// MomentJS utilizado para Picker
import * as _moment from "moment";
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Pipe({
  name: "extIcon"
})
export class ExtIconPipe implements PipeTransform {
  fechaProduccion = new Date(2020, 3, 12, 23, 59, 0);
  transform(archivoAdjunto: any): any {
    const fileExtn = archivoAdjunto.nombre.split(".").reverse()[0];
    if (
      this.isImage(fileExtn) &&
      this.validarFechaProduccionImagenes(archivoAdjunto.fechaCreacion)
    ) {
      return archivoAdjunto.url + "mini";
    } else {
      const icono = this.getMIMEtype(fileExtn);
      return icono ? icono : "../../assets/icons-file/default-icon.svg";
    }
  }

  isImage(fileExtn) {
    switch (fileExtn.toLowerCase()) {
      case "jpg":
      case "png":
      case "jpeg":
        return true;
    }
  }

  /**
   * Tipos de archivos con sus respectivos iconos
   * @param extn extensión
   */
  getMIMEtype(extn) {
    const ext = extn.toLowerCase();
    const MIMETypes = {
      "3ds": "../../assets/icons-file/3ds.svg",
      cad: "../../assets/icons-file/cad.svg",
      dmg: "../../assets/icons-file/dmg.svg",
      gif: "../../assets/icons-file/gif.svg",
      js: "../../assets/icons-file/js.svg",
      pdf: "../../assets/icons-file/pdf.svg",
      ps: "../../assets/icons-file/ps.svg",
      txt: "../../assets/icons-file/txt.svg",
      aac: "../../assets/icons-file/aac.svg",
      cdr: "../../assets/icons-file/cdr.svg",
      doc: "../../assets/icons-file/doc.svg",
      docx: "../../assets/icons-file/doc.svg",
      htm: "../../assets/icons-file/html.svg",
      html: "../../assets/icons-file/html.svg",
      midi: "../../assets/icons-file/midi.svg",
      php: "../../assets/icons-file/php.svg",
      raw: "../../assets/icons-file/raw.svg",
      wmv: "../../assets/icons-file/wmv.svg",
      ai: "../../assets/icons-file/ai.svg",
      css: "../../assets/icons-file/css.svg",
      eps: "../../assets/icons-file/eps.svg",
      indd: "../../assets/icons-file/indd.svg",
      mov: "../../assets/icons-file/mov.svg",
      png: "../../assets/icons-file/png.svg",
      sql: "../../assets/icons-file/sql.svg",
      xls: "../../assets/icons-file/xls.svg",
      xlsx: "../../assets/icons-file/xls.svg",
      avi: "../../assets/icons-file/avi.svg",
      dat: "../../assets/icons-file/dat.svg",
      fla: "../../assets/icons-file/fla.svg",
      iso: "../../assets/icons-file/iso.svg",
      mp3: "../../assets/icons-file/mp3.svg",
      ppt: "../../assets/icons-file/ppt.svg",
      pptx: "../../assets/icons-file/ppt.svg",
      svg: "../../assets/icons-file/svg.svg",
      xml: "../../assets/icons-file/xml.svg",
      bmp: "../../assets/icons-file/bmp.svg",
      dll: "../../assets/icons-file/dll.svg",
      flv: "../../assets/icons-file/flv.svg",
      jpg: "../../assets/icons-file/jpg.svg",
      mpg: "../../assets/icons-file/mpg.svg",
      psd: "../../assets/icons-file/psd.svg",
      tif: "../../assets/icons-file/tif.svg",
      zip: "../../assets/icons-file/zip.svg"
    };
    return MIMETypes[ext];
  }

  /**
   * Fecha de salida a produccion.
   */
  validarFechaProduccionImagenes(fechaCreacion) {
    if (moment(this.fechaProduccion).isBefore(fechaCreacion)) {
      return true;
    }
    return false;
  }
}
