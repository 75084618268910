// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { map, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { State } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})
export class DashboardAcuerdoServicioService extends AbtractCrudBasicoService {

  /**
  * Contructor del Servicio
  * @constructor
  * @param {HttpClient} http Servicio http de angular.
  * @param {UsuarioService} _usuarioService Servicio de usuario.
  * @param {KendoService} _kendoService Servicio kendo.
  * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
  */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'acuerdoservicio/dashboard', '');
  }

  getKendoPorcentajeCumplimientoMes(state: State) {
    this.loadingGrid = true;
    let url = `${this.pathName.toLowerCase()}/cumplimientomes`;

    return this._kendoService.queryGridRefactoring(url, state).pipe(
      map(data => {
        if (state.take && isNaN(state.take)) {
          state.take = data.data.length;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => new Error(err))
      })
    );
  }

  getKendoInformacionAnalitica(state: State) {
    this.loadingGrid = true;
    let url = `${this.pathName.toLowerCase()}/informacionanalitica`;

    return this._kendoService.queryGridRefactoring(url, state).pipe(
      map(data => {
        if (state.take && isNaN(state.take)) {
          state.take = data.data.length;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => new Error(err));
      })
    );
  }
}