<div class="ph-item">
    <div class="ph-col-2">
        <div class="ph-row">
            <div class="ph-col-12" style="height: 65px"></div>
        </div>
    </div>
    <div class="ph-col-10">
        <div class="ph-col-12">
            <div class="ph-row">
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-4 big"></div>
            </div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-12 empty"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="card-footer">
        <div class="row">
            <div class="col-6 text-left">
                <div class="category form-category">
                    <span style="color: red">*</span> Campos requeridos
                </div>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="btn btn-sm btn-primary">
                        <i class="material-icons">save</i>
                </button>

                <button type="button" class="btn btn-sm btn-danger">
                        <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>

</div>