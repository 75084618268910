import { Servicio } from './servicio.model';
import { TipoEtiquetaServicio } from './tipo-etiqueta-servicio.model';

export class EtiquetaServicio {
  id?: string;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  tipoEtiquetaServicio?: TipoEtiquetaServicio;

  constructor() {
    this.id = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.tipoEtiquetaServicio = null;
  }
}
