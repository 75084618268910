import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placeholder-loading-card',
  templateUrl: './placeholder-loading-card.component.html',
  styles: []
})
export class PlaceholderLoadingCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
