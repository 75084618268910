// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// rxjs
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Configuracion
import { url_servicio } from '../../../config/config';
// Servicios
import { UsuarioService } from '../../seguridad/usuario.service';
import { CrudBasicoService } from '../../utilidades/crud-basico.service';
// Modelo
import { DetalleMovimiento } from '../../../models/models.index';
// SweetAlert
import Swal from 'sweetalert2'
import { MensajesService } from '../../utilidades/mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class DetalleMovimientoService {

  private tableName = 'detallemovimiento';
  public numeroElementosRegistrados = 0;
  public precioTotal = 0;
  public detallesMovimientoRegistrados: DetalleMovimiento[] = [];
  /**
    * Contructor del Servicio Estado Elemento
    * @constructor
    * @param {HttpClient} http Servicio para llamar web services
    * @param {CrudBasicoService} _crudBasicoService Servicio para los crud básicos.
    * @param {UsuarioService} _usuarioService Servicio de usuario.
    */
  constructor(
    private http: HttpClient,
    private _crudBasicoService: CrudBasicoService,
    private _mensajeService: MensajesService,
    private _usuarioService: UsuarioService
  ) {

  }

  /**
  * Obtiene el registro por llaves primarias compuestas
  * @param {string[]} ids - IDs del registro
  */
  getByPK(ids: string[]) {
    return this._crudBasicoService.getByPK(this.tableName, ids);
  }

  /**
   * Función para crear o editar el registro
   * @param {any} detalleMovimiento - Entidad(es) a crear o editar
   */
  save(detalleDetalleMovimiento: any) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}`;
    return this.http.post(url, detalleDetalleMovimiento, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire('Error', response.mensaje, 'error');
          }
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
  * Elimina el registro directamente de la base de datos
  * @param {string[]} id - IDs del registro
  */
  eliminar(id: string[]) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    const ids = id.toString().trim().replace(',', '/');
    // URL
    const url = `${url_servicio}/${this.tableName}/eliminar/${ids}`;
    return this.http.get(url, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response.success;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
  * Obtiene los registros por id movimiento
  */
  getByMovimiento(idMovimiento: string): any {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/pormovimiento/${idMovimiento}`;
    return this.http.get(url, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }
}
