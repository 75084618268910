// Módulos Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Módulo de Loading
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

// Componentes
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingFormComponent } from './loading/loading-form.component';
import { PlaceholderLoadingGridComponent } from './placeholder-loading/placeholder-loading-grid.component';
import { PlaceholderLoadingFormComponent } from './placeholder-loading/placeholder-loading-form.component';
import { PlaceholderLoadingMenuComponent } from './placeholder-loading/placeholder-loading-menu.component';
import { PerfilPersonaComponent } from './perfil-persona/perfil-persona.component';
import { PlaceholderLoadingWizardComponent } from './placeholder-loading/placeholder-loading-wizard.component';
import { PlaceholderLoadingCalendarComponent } from './placeholder-loading/placeholder-loading-calendar.component';
import { PlaceholderLoadingResumenServicioComponent } from './placeholder-loading/placeholder-loading-resumen-servicio.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ImgFullscreenViewComponent } from './carousel/img-fullscreen-view/img-fullscreen-view.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { TemplateNotificacionNoticiaComponent } from './template-notificacion-noticia/template-notificacion-noticia.component';
import { DonutComponent } from './kendo/donut/donut.component';
import { PlaceholderLoadingCardComponent } from './placeholder-loading/placeholder-loading-card.component';

import {
    RegistroEmpresaSuscriptoraComponent
} from '../pages/empresa-suscriptoras/registro-empresa-suscriptora/registro-empresa-suscriptora.component';

import { ConfiguracionEmpresaSuscriptoraComponent } from './configuracion-empresa-suscriptora/configuracion-empresa-suscriptora.component';
import { TooltipComponent } from './tooltip/tooltip.component';

// Material Angular
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialModule } from '../material.module';

//Modulos componentes
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";
import { TourNgxBootstrapModule } from 'ngx-tour-ngx-bootstrap';

//Carousel
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { NgImageViewerModule } from '@haseeamarathunga/ng-image-viewer';

//Google Maps
import { AgmCoreModule } from '@agm/core';
//Key Maps
import { keyGoogleMaps } from '../config/config';

// ng2 File Upload
import { FileUploadModule } from 'ng2-file-upload';

// IMG-CROPPER
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImgCropperComponent } from './img-cropper/img-cropper.component';

// Kendo
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { FloatingLabelModule } from "@progress/kendo-angular-label";

//Pipes
import { HourToStringPipe } from '../pipes/hour-to-string.pipe';
import { SinFotoPipe } from '../pipes/sin-foto.pipe';
import { DateTimeFormatPipe } from '../pipes/date-time-format.pipe';
import { ExtIconPipe } from '../pipes/ext-icon.pipe';
import { SanitizedHtmlPipe } from '../pipes/sanitized-html.pipe';
import { NumberFormatPipe } from '../pipes/number-format.pipe';
//import { DatePipe } from '@progress/kendo-angular-intl';

//Directivas
import { UppercaseInputDirective } from '../directives/upperCaseDirective';
import { NumbercaseInputDirective } from '../directives/numberCaseDirective';
import { UppercaseDirective } from '../directives/uppercase.directive';
import { RemoveSpecialCharactersDirective } from '../directives/remove-special-characters.directive'


// QUILL
import { QuillModule } from 'ngx-quill';
import { formats, toolbarOptions } from '../config/quill-editor.enum';
import { KendoGridCrudComponent } from './kendo-grid-crud/kendo-grid-crud.component';
import { KendoModule } from '../kendo.module';
import { TemplateBuscarGridModule } from '../componentes/template-buscar-grid/template-buscar-grid.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
// import { NgxQuillComponent } from './ngx-quill/ngx-quill.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';

//Valicacion de contrasena segura
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { VideoSoporteComponent } from './video-soporte/video-soporte.component';





@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ScrollViewModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.threeBounce,
            backdropBackgroundColour: 'rgba(0,0,0,0.3)',
            backdropBorderRadius: '0px',
            primaryColour: '#ca4900',
            secondaryColour: '#cf5b19',
            tertiaryColour: '#d46d32'
        }),
        FormsModule,
        InputsModule,
        DropDownsModule,
        MaterialModule,
        MatDialogModule,
        QuillModule.forRoot({
            formats: formats,
            modules: {
                syntax: false,
                toolbar: toolbarOptions
            }
        }),
        ImageCropperModule,
        ChartsModule,
        PDFExportModule,
        TourNgxBootstrapModule,
        AgmCoreModule.forRoot({
            apiKey: keyGoogleMaps,
            libraries: ['places']
        }),
        IvyCarouselModule,
        PinchZoomModule,
        NgImageViewerModule,
        FileUploadModule,
        FloatingLabelModule,
        KendoModule,
        TemplateBuscarGridModule,
        NavigationModule,
        PasswordStrengthMeterModule.forRoot()
    ],
    declarations: [
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        NopagefoundComponent,
        LoadingComponent,
        LoadingFormComponent,
        PlaceholderLoadingMenuComponent,
        PlaceholderLoadingGridComponent,
        PlaceholderLoadingFormComponent,
        ImgCropperComponent,
        PerfilPersonaComponent,
        PlaceholderLoadingWizardComponent,
        PlaceholderLoadingCalendarComponent,
        PlaceholderLoadingResumenServicioComponent,
        UppercaseDirective,
        UppercaseInputDirective,
        NumbercaseInputDirective,
        RemoveSpecialCharactersDirective,
        HourToStringPipe,
        SinFotoPipe,
        DateTimeFormatPipe,
        NumberFormatPipe,
        ExtIconPipe,
        SanitizedHtmlPipe,
        // NgxQuillComponent,
        DonutComponent,
        ConfiguracionEmpresaSuscriptoraComponent,
        RegistroEmpresaSuscriptoraComponent,
        TooltipComponent,
        PlaceholderLoadingCardComponent,
        CarouselComponent,
        ImgFullscreenViewComponent,
        BreadcrumbsComponent,
        TemplateNotificacionNoticiaComponent,
        KendoGridCrudComponent,
        VideoSoporteComponent
    ],
    exports: [
        NavbarComponent,
        SidebarComponent,
        FooterComponent,
        NopagefoundComponent,
        LoadingComponent,
        LoadingFormComponent,
        PlaceholderLoadingMenuComponent,
        PlaceholderLoadingGridComponent,
        PlaceholderLoadingFormComponent,
        PlaceholderLoadingWizardComponent,
        PlaceholderLoadingCalendarComponent,
        PlaceholderLoadingResumenServicioComponent,
        PlaceholderLoadingCardComponent,
        ImgCropperComponent,
        UppercaseDirective,
        UppercaseInputDirective,
        NumbercaseInputDirective,
        RemoveSpecialCharactersDirective,
        HourToStringPipe,
        SinFotoPipe,
        DateTimeFormatPipe,
        NumberFormatPipe,
        ExtIconPipe,
        SanitizedHtmlPipe,
        TourNgxBootstrapModule,
        BreadcrumbsComponent,
        // NgxQuillComponent,
        CarouselComponent,
        ImgFullscreenViewComponent,
        // NgxQuillComponent,
        //TourNgxBootstrapModule,
        TemplateNotificacionNoticiaComponent,
        KendoGridCrudComponent
    ],
    providers: [
        DateTimeFormatPipe,
        NumberFormatPipe,
        ExtIconPipe,
        SanitizedHtmlPipe,
    ]
})
export class SharedModule { }
