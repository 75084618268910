import { FaseTrabajo } from './fase-trabajo.model';
import { Usuario } from '../seguridad/usuario.model';
import { Servicio } from './servicio.model';

export class HoraServicioTrabajo {
  id?: string;
  fechaInicio?: Date;
  fechaFin?: Date;
  horaTrabajada?: number;
  cargoClienteHora?: boolean;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  usuario?: Usuario;
  faseTrabajo?: FaseTrabajo;
  zonaHoraria?: string;

  constructor() {
    this.id = null;
    this.fechaInicio = null;
    this.fechaFin = null;
    this.horaTrabajada = null;
    this.cargoClienteHora = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.usuario = null;
    this.faseTrabajo = null;
    this.zonaHoraria = null;
  }
}
