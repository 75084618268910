// Angular
import { Injectable } from '@angular/core';
import { Acceso } from 'src/app/models/models.index';
import { AccesoService } from '../seguridad/acceso.service';

@Injectable()
export class NavbarService {
  title = 'Dashboard';
  icon = '';
  sidebarOpen = false;
  titleInfo = '';
  materialSoporte = [];

  /**
   * Contructor del Servicio
   */
  constructor(private accesoServicio: AccesoService) {}

  updateSidebar(status) {
    this.sidebarOpen = status;
  }

  updateTittleAcceso(idAcceso: string, materialSoporte = []){
    this.icon = '';
    this.titleInfo = '';
    this.accesoServicio.getByID(idAcceso).subscribe((acceso: Acceso)=> {
      this.title = acceso.nombre;
      this.icon = acceso.icono;
      this.titleInfo = acceso.descripcion;
    })
    this.materialSoporte = materialSoporte;
  }
  
  /**
   * Función para actualizar el titulo del sidebar
   * @param {string} title - Titulo
   */
  updateTitle(title: string, materialSoporte = []) {
    this.title = title;
    this.icon = '';
    this.titleInfo = '';
    this.materialSoporte = materialSoporte;

  }

    /**
   * Función para actualizar el Info del titulo del sidebar
   * @param {string} titleInfo - Informativo Titulo
   */
  updateTitleInfo(titleInfo: string) {
    this.titleInfo = titleInfo;
  }
  /**
   * Función para actualizar el icono del sidebar
   * @param {string} icon - Icono
   */
  updateIcon(icon: string) {
    this.icon = icon;
  }
}
