import { EmpresaContratante } from '../empresa-contratante.model';
import { Empresa } from '../empresas/empresa.model';
import { EstadoCotizacion } from './estado-cotizacion.model';
import { Usuario } from '../seguridad/usuario.model';
import { ArchivoAdjuntoCotizacion } from './archivo-adjunto-cotizacion.model';

export class Cotizacion {
    id?: string;
    numeroReferencia?: string;
    nombre?: string;
    fechaOferta?: Date;
    fechaVencimiento?: Date;
    nombreCliente?: string;
    nombrePersonaDirigida?: string;
    correoPersonaDirigida?: string;
    asunto?: string;
    antecedentes?: string;
    formaPago?: string;
    plazoEntrega?: string;
    incluye?: string;
    noIncluye?: string;
    vigencia?: string;
    subtotalSinDescuento?: number;
    valorDescuento?: number;
    descuento?: number;
    subtotalDescuento?: number;
    iva?: number;
    valorCantidadIva?: number;
    total?: number;
    correoCopia?: string;
    descuentoPorcentaje?: Boolean;
    aprobada?: Boolean;
    fechaAprobacion?: Date;
    cerrada?: Boolean;
    fechaCierre?: Date;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresaContratante?: EmpresaContratante;
    cliente?: Empresa;
    estadoCotizacion?: EstadoCotizacion;
    usuarioVendedor?: Usuario;
    usuarioAprueba?: Usuario;
    usuarioCierra?: Usuario;
    usuarioCrea?: Usuario;
    constructor() {
        this.id = null;
        this.numeroReferencia = null;
        this.nombre = null;
        this.fechaOferta = null;
        this.fechaVencimiento = null;
        this.nombreCliente = null;
        this.nombrePersonaDirigida = null;
        this.correoPersonaDirigida = null;
        this.asunto = null;
        this.antecedentes = null;
        this.formaPago = null;
        this.plazoEntrega = null;
        this.incluye = null;
        this.noIncluye = null;
        this.vigencia = null;
        this.subtotalSinDescuento = null;
        this.valorDescuento = 0;
        this.descuento = null;
        this.subtotalDescuento = null;
        this.iva = null;
        this.valorCantidadIva = 15;
        this.total = null;
        this.correoCopia = null;
        this.descuentoPorcentaje = false;
        this.aprobada = false;
        this.fechaAprobacion = null;
        this.cerrada = false;
        this.fechaCierre = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresaContratante = null;
        this.cliente = null;
        this.estadoCotizacion = null;
        this.usuarioVendedor = null;
        this.usuarioAprueba = null;
        this.usuarioCierra = null;
        this.usuarioCrea = null;
    }

}
export class CotizacionAdjuntos {
    cotizacion?: Cotizacion;
    archivosAdjuntosCotizacion: ArchivoAdjuntoCotizacion[] ;
    constructor() {
        this.cotizacion = null;
        this.archivosAdjuntosCotizacion = null;
    }
}
