import { AcuerdoServicio } from './acuerdo-servicio.model';
import { TipoCobertura } from './tipo-cobertura.model';

export class Cobertura {
    id?: string;
    horaInicio?: Date;
    horaFin?: Date;
    recargo?: number;
    fechaCreacion?: Date;
    version?: number;
    tipoCobertura?: TipoCobertura;
    contratoConvenio?: AcuerdoServicio;
    constructor() {
        this.id = null;
        this.horaInicio = null;
        this.horaFin = null;
        this.recargo = null;
        this.fechaCreacion = null;
        this.version = null;
        this.tipoCobertura = null;
        this.contratoConvenio = null;
    }
}
