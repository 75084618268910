import { TipoMovimiento } from './tipo-movimiento.model';
import { Empresa } from '../empresas/empresa.model';
import { Proyecto } from '../servicio/proyecto.model';
import { PuntoServicio } from '../empresas/punto-servicio.model';
import { Usuario } from '../seguridad/usuario.model';
export class Movimiento {
    id: string;
    numeroMovimiento?: string;
    codigoMovimiento?: string;
    precioTotal?: number;
    aceptado?: boolean;
    fechaAceptacion?: Date;
    desdeServicio?: boolean;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    tipoMovimiento?: TipoMovimiento;
    usuarioCrea?: Usuario;
    usuarioFirma?: Usuario;
    cliente?: Empresa;
    proyectoCargo?: Proyecto;
    puntoServicio?: PuntoServicio;
    usuarioOrigen?: Usuario;
    usuarioDestino?: Usuario;

    constructor() {
        this.id = null;
        this.numeroMovimiento = null;
        this.codigoMovimiento = null;
        this.precioTotal = null;
        this.aceptado = null;
        this.fechaAceptacion = null;
        this.desdeServicio = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.tipoMovimiento = new TipoMovimiento();
        this.usuarioCrea = new Usuario();
        this.usuarioFirma = new Usuario();
        this.cliente = new Empresa();
        this.proyectoCargo = new Proyecto();
        this.puntoServicio = new PuntoServicio();
        this.usuarioOrigen = new Usuario();
        this.usuarioDestino = new Usuario();
    }
}
