import { Servicio } from './servicio.model';

export class FechaRequerimiento {
  id?: string;
  fecha?: Date;
  fechaFin?: Date;
  zonaHoraria?: string;
  estado?: string;
  version?: number;
  servicio?: Servicio;

  constructor() {
    this.id = null;
    this.fecha = null;
    this.fechaFin = null;
    this.zonaHoraria = null;
    this.estado = null;
    this.version = null;
    this.servicio = new Servicio();
  }
}
