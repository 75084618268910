/**
 * Pipe utilizado para modificar la hora de tiempo de transporte en los puntos de servicio
 * Implementado en puntos-servicios
 */

import { Pipe, PipeTransform } from '@angular/core';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
@Pipe({
  name: 'hourToString'
})
export class HourToStringPipe implements PipeTransform {

  transform(hour: any): any {
    if (hour) {
      return new moment(hour, 'HH:mm').format('HH:mm');
    }
    return 'null';
  }

}
