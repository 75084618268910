import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-soporte',
  templateUrl: './video-soporte.component.html',
  styleUrls: []
})
export class VideoSoporteComponent implements OnInit {

  title: string;
  url: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<VideoSoporteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;    
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  closeModal() {
    this.dialogRef.close();
  }
}