export class EquipoSinMantenimiento {
    fechaInicio: Date;
    fechaFin: Date;
    idEmpresaContratanteParametro: string;
    idsTipoServicio: string;
    idsCliente: string;
    idsMarca: string;
    idsModelo: string;
    idsSerie: string;

    constructor() {
        this.fechaInicio = null;
        this.fechaFin = null;
        this.idEmpresaContratanteParametro = '';
        this.idsTipoServicio = '';
        this.idsCliente = '';
        this.idsMarca = '';
        this.idsModelo = '';
        this.idsSerie = '';
    }
}

export class ConsultaEsquiposDecisionParametros {
    anioInput: number;
    idEmpresaContratanteParametro: string;
    idsEmpresa: string;
    idsMarca: string;
    idsModelo: string;
    idsSerie: string;

    constructor() {
        this.anioInput = null;
        this.idEmpresaContratanteParametro = '';
        this.idsEmpresa = '';
        this.idsMarca = '';
        this.idsModelo = '';
        this.idsSerie = '';
    }
}

