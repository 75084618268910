// /// <reference path='../../../../../node_modules/@types/googlemaps/index.d.ts' />
/// <reference types="../../../../../node_modules/@types/google.maps" />

// Angular
import { Component, Inject, ViewChild, OnInit, ElementRef, NgZone } from '@angular/core';
import { NgForm, UntypedFormControl } from '@angular/forms';

// RXJS
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Dialogo Angular/Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Modelo
import {
  EmpresaContratante,
  Ciudad,
  ProcesoRegistro,
  Accion
} from '../../../models/models.index';

// Servicio
import {
  ValidarFormularioService,
  EmpresaContratanteService,
  CiudadService,
  UsuarioService,
} from '../../../services/service.index';

// Componentes
import { ImgCropperComponent } from '../../../shared/img-cropper/img-cropper.component';

// Kendo JQuery
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

// ENUM
import { SizeModal } from '../../../config/size-modal.enum';
import { MensajesService } from '../../../services/utilidades/mensajes.service';
import { MouseEvent, MapsAPILoader } from '@agm/core';
import {
  ArchivoAdjuntoEmpresaContratanteService
} from '../../../services/empresa-suscriptoras/archivo-adjunto-empresa-contratante.service';

@Component({
  selector: 'app-registro-empresa-suscriptora',
  templateUrl: './registro-empresa-suscriptora.component.html',
  styles: []
})
export class RegistroEmpresaSuscriptoraComponent implements OnInit {
  // Inicialización de variables para el proceso
  @ViewChild('form', { static: false })
  public form: NgForm;
  placeholderLoading = false;
  accion: Accion = new Accion();
  procesoRegistro: ProcesoRegistro = new ProcesoRegistro();
  logAuditoria: any = {};
  empresaContratante: EmpresaContratante = new EmpresaContratante();
  logo: { principal: boolean, secundario: boolean };

  // Google Maps
  zoom = 4;
  draggable = true;
  public searchControl: UntypedFormControl;
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef;
  coordenadas: any = { latitud: -1.9191583, longitud: -79.1236012 };

  selectedIndex = 0;

  // Ciudad
  filtroCiudad = '';
  ciudadTmp: Ciudad;
  ciudades: GridDataResult = { data: [], total: 0 };
  stateSubjectCiudad = new BehaviorSubject<string>(null);
  public stateCiudad: State;
  terminosCondiciones = '';
  // Archivos Adjuntos
  archivosAdjuntos: [];

  /**
   * Contructor
   * @param _empresaContratanteService Empresa Contratante Sevicio
   * @param _ciudadService Ciudad Servicio
   * @param _validarFormularioService Validar Formulario Servicio
   * @param _mensajeServices Mensaje Servicio
   * @param _usuarioService Usuario Servicio
   * @param dialog dialog
   * @param dialogRef dialogRef
   * @param data Data
   */
  constructor(
    public _empresaContratanteService: EmpresaContratanteService,
    private _archivoAdjuntosService: ArchivoAdjuntoEmpresaContratanteService,
    public _ciudadService: CiudadService,
    public _validarFormularioService: ValidarFormularioService,
    public _mensajeServices: MensajesService,
    public _usuarioService: UsuarioService,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<RegistroEmpresaSuscriptoraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.placeholderLoading = true;
    // this.accion = this.data.accion;
    this.logAuditoria = {};
    this.procesoRegistro = new ProcesoRegistro();
    this.logo = { secundario: false, principal: false };
    const cargarCiudadesPromise = this.cargarCiudades();
    Promise.all([
      cargarCiudadesPromise
    ]).then(() => {
      if (this.data && this.data.idEmpresaContratante) {
        this._empresaContratanteService
          .getByID(data.idEmpresaContratante)
          .subscribe((empresaContratante: EmpresaContratante) => {
            this.empresaContratante = empresaContratante;
            this.ciudadTmp = empresaContratante.ciudad;
            this.empresaContratante.longitud = empresaContratante.longitud
              ? Number(empresaContratante.longitud)
              : this.coordenadas.longitud;
            this.empresaContratante.latitud = empresaContratante.latitud
              ? Number(empresaContratante.latitud)
              : this.coordenadas.latitud;
            this.zoom =
              this.empresaContratante.longitud === this.coordenadas.longitud
                ? 4
                : 16;
            this.anadirCiudadVirtualizado();
            this.cargarArchivosAdjuntos();
            this.procesoRegistro.cargarEditar();
            this.placeholderLoading = false;
          });
        this._empresaContratanteService.getLog(data.idEmpresaContratante).subscribe(response => {
          this.logAuditoria = response;
        });
      }
    });
  }

  /**
   * Carga de la función filtros para los combobox
   */
  ngOnInit() {
    // create search FormControl
    this.searchControl = new UntypedFormControl();
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          componentRestrictions: { country: 'ec' }
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // // set latitude, longitude and zoom
          this.empresaContratante.latitud = (place.geometry.location.lat());
          this.empresaContratante.longitud = (place.geometry.location.lng());
          this.empresaContratante.datosReferencia = place.formatted_address;
          this.asignarDireccion(place.address_components);
          this.draggable = true;
          this.zoom = 16;
        });
      });
    });

    this.stateSubjectCiudad
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value !== null) {
          this.stateCiudad = this.stateCiudadDefault();
          if (value.length > 0) {
            this.stateCiudad.filter = {
              logic: 'and',
              filters: [
                { field: 'ciudad.nombre', operator: 'contains', value }
              ]
            };
          }
          this.cargarCiudades();
        }
      });
  }

  /**
   * Función para crear o editar un registro
   * @param {NgForm} forma - Información del formulario
   */
  save(forma: NgForm) {
    if (!this.empresaContratante.aceptacionTerminos) {
      this._mensajeServices.errorPersonalizada('Es necesario aceptar los términos y condiciones para terminar con el registro'
        , 'Aceptar Términos y Condiciones');
      return;
    }
    if (
      (forma.dirty || this.data.dirty) &&
      forma.valid &&
      !this.procesoRegistro.enProceso && this.empresaContratante.aceptacionTerminos
    ) {
      this._mensajeServices.confirmacionPersonalizado(
        'Estas seguro de aceptar los Términos y Condiciones. ¿Desea Continuar?')
        .then(value => {
          if (value) {
            this.procesoRegistro.enEjecucion();
            const empresaContratante: EmpresaContratante = {
              id: this.empresaContratante.id,
              ciudad: this.ciudadTmp.id ? { id: this.ciudadTmp.id } : null,
              ruc: this.empresaContratante.ruc,
              nombreCompleto: this.empresaContratante.nombreCompleto,
              nombreCorto: this.empresaContratante.nombreCorto,
              siglas: this.empresaContratante.siglas,
              telefonoFijo: this.empresaContratante.telefonoFijo,
              telefonoMovil: this.empresaContratante.telefonoMovil,
              sitioWeb: this.empresaContratante.sitioWeb,
              mailContacto: this.empresaContratante.mailContacto,
              mailRecibeNotificaciones: this.empresaContratante.mailRecibeNotificaciones,
              calle: this.empresaContratante.calle,
              numero: this.empresaContratante.numero,
              interseccion: this.empresaContratante.interseccion,
              datosReferencia: this.empresaContratante.datosReferencia,
              logoPrincipal: this.empresaContratante.logoPrincipal,
              logoSecundario: this.empresaContratante.logoSecundario,
              logoCsc: this.empresaContratante.logoCsc,
              informacionPdf: this.empresaContratante.informacionPdf,
              observacion: this.empresaContratante.observacion,
              fechaCreacion: this.empresaContratante.fechaCreacion,
              estado: this.empresaContratante.estado,
              version: this.empresaContratante.version,
              representanteLegalNombre: this.empresaContratante.representanteLegalNombre,
              representanteLegalMail: this.empresaContratante.representanteLegalMail,
              contactoVentaNombre: this.empresaContratante.contactoVentaNombre,
              contactoVentaMail: this.empresaContratante.contactoVentaMail,
              latitud: this.empresaContratante.latitud,
              longitud: this.empresaContratante.longitud,
              administradorNombre: this.empresaContratante.administradorNombre,
              administradorMail: this.empresaContratante.administradorMail,
              administradorTelefono: this.empresaContratante.administradorTelefono,
              facturacionNombre: this.empresaContratante.facturacionNombre,
              facturacionMail: this.empresaContratante.facturacionMail,
              facturacionTelefono: this.empresaContratante.facturacionTelefono,
              aceptacionTerminos: this.empresaContratante.aceptacionTerminos,
              fechaAceptacionTerminos: this.empresaContratante.fechaAceptacionTerminos,
            };

            this._empresaContratanteService.aceptoTerminosCondiciones(empresaContratante, false).subscribe(
              (response: any) => {
                if (response.success) {
                  this._usuarioService.generarNuevoToken().subscribe(() => {
                    this._mensajeServices.
                      exitoHTML(
                        'Suscripción realizada con éxito. Bienvenido/a hemos enviado las indicaciones de acceso a tu cuenta de correo' +
                        '<br> <strong>¿Eres el Administrador del Sistema?</strong> : ' +
                        'Recuerda ingresar los datos principales en la sección de Configuración.' +
                        '<br>  <strong>¿No eres el Administrador?</strong> : ' +
                        'no te preocupes, tienes acceso de consulta a todos los módulos del Sistema, además hemos ' +
                        ' enviado las indicaciones de acceso al email del  Administrador.'
                      );
                    setTimeout(() => {
                      this._usuarioService.setEmpresaContratanteAceptacionTerminos();
                      this.procesoRegistro.finalizado();
                      this.dialogRef.close({
                        id: response.idEntidad,
                        nombre: this.empresaContratante.nombreCorto
                      });
                    }, 200);
                  });
                }
                this.procesoRegistro.finalizado();
                document.body.style.overflow = '';
              },
              error => {
                console.error(error);
                // this.dialogRef.close(false);
              }
            );
          }
        });
    } else if (!forma.valid) {
      this._validarFormularioService.validateAllFormFields(forma.control);
    } else {
      this.dialogRef.close(false);
    }
  }

  /**
   * Función para cerrar el modal
   */
  closeModal() {
    this._validarFormularioService.confirmCloseModal(
      this.form.dirty || this.data.dirty,
      this.dialogRef
    );
  }


  nextStep() {
    if (this.selectedIndex !== 3) {
      this.selectedIndex = this.selectedIndex + 1;
    }
  }

  previousStep() {
    if (this.selectedIndex !== 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

  // #region Fotografía

  cargarInputFile(isLogoPrincipal: boolean) {
    let input;
    if (isLogoPrincipal) {
      this.logo.principal = true;
      this.logo.secundario = false;
      input = "inputFile1";
    } else {
      this.logo.secundario = true;
      this.logo.principal = false;
      input = "inputFile2";
    }
    const element: HTMLElement = document.getElementById(
      input
    ) as HTMLElement;
    element.click();
  }

  cancelarFotografia(logoprincipal: boolean) {
    this.form.control.markAsDirty();
    if (logoprincipal) {
      this.empresaContratante.logoPrincipal = '../../../assets/img/defaultUser.png';
    } else {
      this.empresaContratante.logoSecundario = '../../../assets/img/defaultUser.png';
    }
  }

  cargarPersonalizarImagen(event) {
    let data = {
      event: event,
      aspectRatio: 1,
      resizeToWidth: 75
    };
    if (this.logo.principal) {
      data = {
        event: event,
        aspectRatio: 4,
        resizeToWidth: 0
      };
    }
    const dialogRef = this.dialog.open(ImgCropperComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.medium,
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.form.control.markAsDirty();
        if (this.logo.principal) {
          this.empresaContratante.logoPrincipal = result.fotografia;
        }
        if (this.logo.secundario) {
          this.empresaContratante.logoSecundario = result.fotografia;
        }
      }
    });
  }

  // #endregion


  

  // #region CIUDAD

  /**
   * Función que retorna el state por defecto
   */
  stateCiudadDefault(): State {
    return {
      skip: 0,
      take: 25,
      sort: [
        // Orden por defecto
        { dir: 'asc', field: 'nombre' }
      ],
      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: []
      }
    };
  }

  /**
   * Función para cargar las ciudades
   */
  cargarCiudades(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.stateCiudad = this.stateCiudad
        ? this.stateCiudad
        : this.stateCiudadDefault();
      this._ciudadService.getModal(this.stateCiudad).subscribe(ciudades => {
        this.ciudades = ciudades;
        resolve('');
      });
    });
  }
  /**
   * Función para cargar Ciudad de Google
   */
  cargarCiudadMaps(nombre: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const stateCiudad: State = {
        skip: 0,
        take: 25,
        sort: [
          // Orden por defecto
          { dir: 'asc', field: 'nombre' }
        ],
        // Initial filter descriptor
        filter: {
          logic: 'and',
          filters: [
            {
              field: 'ciudad.nombre',
              operator: 'eq',
              value: nombre.toUpperCase()
            }
          ]
        }
      };
      this._ciudadService.getModal(stateCiudad).subscribe(ciudades => {
        resolve(ciudades);
      });
    });
  }

  /**
   * Función para cargar el filtro de Ciudad
   */
  filterChangeCiudad(e) {
    // Cargar ciudades
    this.filtroCiudad = e.toUpperCase();
    this.stateSubjectCiudad.next(e);
  }

  /**
   * Función para cargar más datos de ciudades
   */
  obtenerMasCiudades() {
    // Cargar ciudades
    this.stateCiudad.take += this.stateCiudad.take;
    this._ciudadService.getModal(this.stateCiudad).subscribe(ciudades => {
      this.ciudades = ciudades;
      if (this.empresaContratante.id) {
        this.anadirCiudadVirtualizado();
      }
    });
  }

  /**
   * Función para añadir la ciudad al datasource de empresas
   */
  anadirCiudadVirtualizado() {
    let virtual = false;
    for (const ciudad of this.ciudades.data) {
      if (ciudad.id === this.ciudadTmp.id) {
        virtual = true;
      }
    }
    if (!virtual) {
      this.ciudades.data.unshift(this.ciudadTmp);
    }
  }


  // #endregion

  // #region GOOGOLE MAPS

  asignarDireccion(address_components: any[]) {
    this._mensajeServices.advertenciaDireccion();
    let nombreCiudad = '';
    let calle = '';
    let numero = '';
    let interseccion = '';
    this.ciudadTmp = null;
    address_components.forEach(componente => {
      componente.types.forEach(tipo => {
        if (tipo === 'administrative_area_level_2' || tipo === 'locality') {
          // Ciudad
          if (componente.short_name === 'Distrito Metropolitano de Quito') {
            componente.short_name = 'Quito';
          }
          nombreCiudad = componente.short_name;
        } else if (tipo === 'route') {
          // Ciudad
          calle = componente.short_name;
        } else if (tipo === 'street_number') {
          // Ciudad
          numero = componente.short_name;
        } else if (tipo === 'sublocality') {
          // Ciudad
          interseccion = componente.short_name;
        }
      });
    });
    this.cargarCiudadMaps(nombreCiudad).then(value => {
      if (value && value.data.length === 1) {
        this.ciudadTmp = value.data[0];
      }
      this.empresaContratante.calle = calle;
      this.empresaContratante.numero = numero;
      this.empresaContratante.interseccion = interseccion;
      this.form.control.markAsDirty();
    });
  }
  /**
   * Cuando se da click en el mapa salta el evento mapClick el cual es capturado
   * para poder buscar que direccion señalo a traves de las coordenadas
   * @param $event: MouseEvent
   **/
  mapClicked($event: MouseEvent) {
    const latlng = { lat: $event.coords.lat, lng: $event.coords.lng };
    this.getAddres(latlng);
  }
  /**
   * Cuando se mueve el punto señado en el mapa salta el evento dragEnd el cual es capturado
   * para poder buscar que direccion señalo a traves de las coordenadas
   * @param $event: MouseEvent
   */
  dragEnd($event) {
    const latlng = { lat: $event.coords.lat, lng: $event.coords.lng };
    this.getAddres(latlng);
  }
  /**
   * Busca la direccion ingresada en la latlng {latitud, longitud}
   * @param latlng
   */
  getAddres(latlng: any) {
    this.empresaContratante.latitud = latlng.lat;
    this.empresaContratante.longitud = latlng.lng;
    this.draggable = true;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      this.ngZone.run(() => {
        this.empresaContratante.datosReferencia = results[0].formatted_address;
        this.asignarDireccion(results[0].address_components);
      });
    });
  }

  // #endregion

  // #region Archivos Adjuntos

  /**
   * Función para cargar los archivos adjuntos del empresaContratante
   */
  cargarArchivosAdjuntos() {
    this._archivoAdjuntosService
      .getArchivosAdjuntos(this.empresaContratante.id)
      .subscribe(archivosAdjuntos => {
        this.archivosAdjuntos = archivosAdjuntos;
      });
  }
  // #endregion
}
