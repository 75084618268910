export class ProcesoRegistro {

    titulo: string;
    enCrear: boolean;
    enEditar: boolean;
    enDetalle: boolean;
    enEliminar: boolean;
    enProceso: boolean;

    constructor() {
        this.titulo = '';
        this.enCrear = false;
        this.enEditar = false;
        this.enDetalle = false;
        this.enEliminar = false;
        this.enProceso = false;
    }

    public cargarCrear() {
        this.titulo = 'Crear';
        this.enCrear = true;
        this.enEditar = false;
        this.enDetalle = false;
        this.enEliminar = false;
        this.enProceso = false;
    }

    public cargarEditar() {
        this.titulo = 'Editar';
        this.enCrear = false;
        this.enEditar = true;
        this.enDetalle = false;
        this.enEliminar = false;
        this.enProceso = false;
    }

    public cargarDetalle() {
        this.titulo = 'Detalle';
        this.enCrear = false;
        this.enEditar = false;
        this.enDetalle = true;
        this.enEliminar = false;
        this.enProceso = false;
    }

    public enEjecucion() {
        this.enProceso = true;
    }

    public finalizado() {
        this.enProceso = false;
    }

}
