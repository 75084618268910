import { Servicio } from './servicio.model';
import { Usuario } from '../seguridad/usuario.model';

export class ArchivoAdjuntoServicio {
  id?: string;
  nombre?: string;
  url?: string;
  visibleCliente?: Boolean;
  notificacionServicio?: Boolean;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  usuario?: Usuario;
  amazonS3Parameters?: any;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.url = null;
    this.visibleCliente = null;
    this.notificacionServicio = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.usuario = null;
    this.amazonS3Parameters = null;
  }
}
