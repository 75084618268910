// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// rxjs
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Configuracion
import { url_servicio } from '../../config/config';
// SweetAlert
import Swal from 'sweetalert2'
// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService  extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService,
    private _router: Router,
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'cotizacion', 'cotizacion');
  }
  /**
   * Función para aceptar los EGRESOS. EGRESOS POR PÉRDIDA y TRASLADOS
   * @param idMovimiento Identificador del movimiento
   * @param idPuntoServicio Identificador del Punto de servicio
   * @param contrasenaPDF Contraseña concatenada con el PDF en bse64 que se envía al correo
   */
  cerrarCotizacion(idCotizacion: string, contrasenaPDF: string) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/cerrarcotizacion/${idCotizacion}`;
    return this.http.put(url, contrasenaPDF, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  cambiarEstadoCotizacion(idCotizacion: string, idEstadoCotizacion: string) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/cambiarestadocotizacion/${idCotizacion}`;
    return this.http.put(url, idEstadoCotizacion, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }
  duplicarCotizacion(idCotizacion: string): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/duplicar/${idCotizacion}`;
    return this.http.post(url, null , { headers }).pipe(
      map((response: any) => {
        if (response.success) {
            this._mensajeService.exito(response.mensaje);
            return response;
        } else {
          this._mensajeService.error(response.error);
        }
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
