import { EmpresaContratante } from '../empresa-contratante.model'
import { Empresa } from '../empresas/empresa.model';


export class AcuerdoServicioSla {
    id?: string;
    nombre?: string;
    numeroSla?: string;
    fechaInicio?: Date;
    fechaFin?: Date;
    descripcionServicios?: String;
    descripcionLimitacionServicios?: String;
    descripcionPenalidadesServicios?: String;
    descripcionDatosFacturacion?: String;
    descripcionInformacionAdicional?: String;
    descripcionPersonalTecnico?: String;
    tiempoRespuesta?: number;
    tiempoResolucion?: number;
    valorHoraTecnico?: number;
    facturacionMinimaHorasMes?: number;
    facturacionMinimaHorasServicio?: number;
    valorFijo?: boolean;
    valorMensual?: number;
    finalizado?: boolean;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    //Nuevos campos
    partidaPresupuestaria?: string;
    fechaFallo?: Date;
    //Nuevos campos
    empresaContratante?: EmpresaContratante;
    empresa?: Empresa;

    //Oportunidad
    idOportunidad?: string;

    //Transient
    oportunidadTransient?: any;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.numeroSla = null;
        this.fechaInicio = null;
        this.fechaFin = null;
        this.descripcionServicios = null;
        this.descripcionLimitacionServicios = null;
        this.descripcionPenalidadesServicios = null;
        this.descripcionDatosFacturacion = null;
        this.descripcionInformacionAdicional = null
        this.descripcionPersonalTecnico = null;
        this.tiempoRespuesta = null;
        this.tiempoResolucion = null;
        this.valorHoraTecnico = null;
        this.facturacionMinimaHorasMes = null;
        this.facturacionMinimaHorasServicio = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.valorHoraTecnico = 0;
        this.partidaPresupuestaria = null;
        this.fechaFallo = null;
        this.finalizado = null;
        this.idOportunidad = null;
        this.empresa = new Empresa();
        this.empresaContratante = new EmpresaContratante();
        this.oportunidadTransient = null;
    }
}
