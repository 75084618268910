// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// rxjs
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
// Configuracion
import { url_servicio } from '../../config/config';
import { State } from '@progress/kendo-data-query';
// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { Elemento } from 'src/app/models/inventario/elemento.model';

@Injectable({
  providedIn: 'root'
})
export class ElementoService extends AbtractCrudBasicoService {
  public loadingGrid: boolean;

  /**
   * Contructor del Servicio Elemento
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
    * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'elemento');
  }

  /**
  * Obtiene los elementos que tiene el usuario para el modal
  */
  getModalTraslado(state?: State, idUsuarioOrigen?: string): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoService._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/modal/paratraslado/`;
    url += `${this._kendoService.serialize({ data: JSON.stringify(stateGrid) })}/${idUsuarioOrigen}`;

    return this._kendoService.queryModal(this.pathName, state, url).pipe(
      map(data => {
        return data;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
  * Obtiene los equipos para el servicio
  */
  getEquiposServicio(state?: State, idPuntoServicio?: string): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoService._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/portipoproducto/equipos/${idPuntoServicio}/`;
    url += `${this._kendoService.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this._kendoService.queryModal(this.pathName, state, url).pipe(
      map(data => {
        return data;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
    * Obtiene los equipos para el servicio
    */
  getRepuestos(state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoService._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/portipoproducto/repuestos/`;
    url += `${this._kendoService.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this._kendoService.queryModal(this.pathName, state, url).pipe(
      map(data => {
        return data;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
    * Obtiene los equipos para el servicio
    */
  getMateriales(state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoService._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/portipoproducto/materiales/`;
    url += `${this._kendoService.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this._kendoService.queryModal(this.pathName, state, url).pipe(
      map(data => {
        return data;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para remover los duplicados de un arreglo de elementos
   * @param elementos Lista de los elementos que se comprobarán para remover los duplicados
   * @param propiedad Nombre de la propiedad de la clase 'Elemento'. Ejm: numeroSerie, para mayor información
   * por favor remitirse al archivo elemento.model.ts
   */
  removerDuplicadoPorPropiedad(elementos, propiedad) {
    return elementos.filter((elemento, pos, nuevosElementos) => {
      return nuevosElementos.map(mapObj => mapObj[propiedad]).indexOf(elemento[propiedad]) === pos;
    });
  }

  saveElemento(elemento: any) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/agregarelementofichatecnica/`;

    // Crear
    return this.http.post(url, elemento, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          this._mensajeService.exito(response.mensaje);
        } else {
          this._mensajeService.error(response.error);
        }
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }


  saveComponentes(entidad) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/crearcomponentesmasivos/`;

    // Crear
    return this.http.post(url, entidad, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          this._mensajeService.exito('Los componentes fueron creado con éxito');
        } else {
          this._mensajeService.error({
            detalle: response.mensaje
          });
        }
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  getComponentesByCodigo(state?: State): any {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoService._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // URL
    let url = `${url_servicio}/${this.tableName.toLowerCase()}/codigo/`;
    url += `${this._kendoService.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this._kendoService.queryModal(this.pathName, state, url).pipe(
      map(data => {
        return data;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  getTicketsServicioPorElemento(state?: State): any {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/consolidar/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
