import { Component, OnInit } from '@angular/core';
import { version } from '../../config/config';

@Component({
  selector: 'app-nopagefound',
  templateUrl: './nopagefound.component.html',
  styleUrls: ['./nopagefound.component.css']
})
export class NopagefoundComponent implements OnInit {

  fechaCopyright: Date = new Date();
  version = version;

  constructor() { }

  ngOnInit() {
  }

}
