import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavbarService } from 'src/app/services/service.index';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-material-apoyo',
  templateUrl: './material-apoyo.component.html',
  styleUrls: ['./material-apoyo.component.scss']
})
export class MaterialApoyoComponent implements OnInit {

  url: any;

  constructor(
    private _navbarService: NavbarService,
    private dialogRef: MatDialogRef<MaterialApoyoComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    ;
  }

  closeModal() {
    this.dialogRef.close();
  }

}
