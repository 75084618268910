// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// rxjs
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Configuracion
import { url_servicio } from '../../config/config';

// Modelos
import { Rol } from '../../models/models.index';

// Kendo
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { CrudBasicoService } from '../utilidades/crud-basico.service';

// SweetAlert

import { MensajesService } from '../utilidades/mensajes.service';


@Injectable({
  providedIn: 'root'
})
export class AccionService {

  private tableName = 'accion';

  /**
   * Contructor del Servicio Accion
   * @constructor
   * @param {CrudBasicoService} _crudBasico Servicio para los crud básicos.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   */
  constructor(
    private http: HttpClient,
    private _crudBasico: CrudBasicoService,
    private _mensajeService: MensajesService,
    private _usuarioService: UsuarioService
  ) {}

  /**
  * Obtiene el registro por ID
  * @param {number} id - ID del registro
  */
 getByIDRol(idRol: string): Observable<any> {
  // Headers
  const headers = new HttpHeaders({
    'token_autorizado': this._usuarioService.token_autorizado,
    'Content-Type': 'application/json'
  });
  // URL
  const url = `${url_servicio}/${this.tableName.toLowerCase()}/rol/${idRol}`;
  return this.http.get(url, { headers })
    .pipe(
      map((response: any) => {
        return response;
      }), catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      }));
}

 /**
  * Obtiene el registro por ID
  * @param {number} id - ID del registro
  */
 getByIDAcceso(idAcceso: string): Observable<any> {
  // Headers
  const headers = new HttpHeaders({
    'token_autorizado': this._usuarioService.token_autorizado,
    'Content-Type': 'application/json'
  });
  // URL
  const url = `${url_servicio}/${this.tableName.toLowerCase()}/${idAcceso}`;
  return this.http.get(url, { headers })
    .pipe(
      map((response: any) => {
        return response;
      }), catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      }));
}

}
