import { EmpresaContratante } from '../empresa-contratante.model';
import { FormularioEvaluacion } from './formulario-evaluacion-servicio.model';

export class TipoServicio {
  id: string;
  nombre?: string;
  faseTrabajo?: boolean;
  bonificacion?: boolean;
  etiquetaServicio?: boolean;
  incluirChecklistPdf?: boolean;
  autorizacionSolicitud?: boolean;
  aceptacionAutomatica?: boolean;
  horasAceptacionAutomatica?: number;
  plantillaServicio?: boolean;
  interno?: boolean;
  tiempoAnticipacion?: boolean;
  horasTiempoAnticipacion?: number;
  notificacionAcceso?: boolean;
  horasNotificacionAcceso?: number;
  observacion?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  padreTipoServicio?: TipoServicio;
  empresaContratante?: EmpresaContratante;
  formularioEvaluacion?: FormularioEvaluacion;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.faseTrabajo = null;
    this.bonificacion = null;
    this.etiquetaServicio = null;
    this.incluirChecklistPdf = null;
    this.autorizacionSolicitud = null;
    this.aceptacionAutomatica = null;
    this.horasAceptacionAutomatica = null;
    this.plantillaServicio = null;
    this.interno = null;
    this.tiempoAnticipacion = null;
    this.horasTiempoAnticipacion = null;
    this.notificacionAcceso = null;
    this.horasNotificacionAcceso = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.padreTipoServicio = null;
    this.empresaContratante = null;
    this.formularioEvaluacion = null;
  }
}
