import { AcuerdoServicio } from './acuerdo-servicio.model';
import { TipoServicio } from '../servicio/tipo-servicio.model';
import { Periodo } from './periodo.model';

export class MantenimientoPrevistoContrato {
    id?: string;
    intervaloTiempo?: Date;
    fechaCreacion?: Date;
    version?: number;
    tipoServicio?: TipoServicio;
    contratoConvenio?: AcuerdoServicio;
    periodo?: Periodo;
    constructor() {
        this.id = null;
        this.intervaloTiempo = null;
        this.fechaCreacion = null;
        this.version = null;
        this.tipoServicio = null;
        this.contratoConvenio = null;
        this.periodo = null;
    }
}
