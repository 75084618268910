// Angular
import { Injectable } from '@angular/core';
import {
  NgForm,
  UntypedFormGroup,
  AbstractControl,
  UntypedFormControl
} from '@angular/forms';

// Swal
import Swal from 'sweetalert2'

@Injectable()
export class ValidarFormularioService {
  /**
   * Contructor del Servicio
   */
  constructor() { }

  /**
   * Función para validar los formularios del sistema
   * @param {NgForm} form - Forma del componente
   * @param {string} field - campo a validar
   */
  displayFieldCss(form: NgForm, field: string) {
    return {
      'has-error': this.isFieldValid(form, field),
      'has-feedback': this.isFieldValid(form, field)
    };
  }

  /**
   * Función para validar los formularios del sistema
   * @param {NgForm} form - Forma del componente
   * @param {string} field - campo a validar
   */
  isFieldValid(form: NgForm, field: string, index?: string) {
    if (index) {
      field += index;
    }
    const control: UntypedFormGroup = form.control;
    const formControl: AbstractControl = control.get(field);
    if (formControl) {
      return !formControl.valid && formControl.touched;
    }
    return false;
  }

  /**
   * Función para validar los formularios del sistema
   * @param {FormGroup} formGroup - Grupo de Forma a validar
   */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Función para cerrar los modales con confirmación si lo requieren
   * @param {NgForm} form - Forma del componente
   * @param {string} field - campo a validar
   */
  confirmCloseModal(dirty: boolean, dialogRef) {
    if (dirty) {
      Swal
        .fire({
          text: 'Los cambios efectuados en el formulario no se guardarán. ¿Está seguro de que desea continuar?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ffa726',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'Cancelar'
        })
        .then(result => {
          if (result.value) {
            document.body.style.overflow = '';
            dialogRef.close(false);
          }
        });
    } else {
      document.body.style.overflow = '';
      dialogRef.close(false);
    }
  }
}
