import { DiaCobertura } from './dia-cobertura.model';
import { Cobertura } from './cobertura.model';
import { AcuerdoServicio } from './acuerdo-servicio.model';

export class DiaCoberturaContrato {

    diaCobertura: DiaCobertura;
    cobertura: Cobertura;
    contratoConvenio: AcuerdoServicio;
    constructor() {
        this.diaCobertura = null;
        this.cobertura = null;
        this.contratoConvenio = null;
    }
}

export class DiaCoberturaContratoGroup {

    diaCobertura: DiaCobertura;
    cobertura: Cobertura[];
    constructor() {
        this.diaCobertura = null;
        this.cobertura = [];
    }
}

