import { GrupoEmpresarial } from '../empresas/grupo-empresarial.model';
import { Persona } from './persona.model';

export class PersonaGrupoEmpresarial {
  fechaCreacion?: Date;
  version?: number;
  grupoEmpresarial?: GrupoEmpresarial;
  persona?: Persona;

  constructor() {
    this.fechaCreacion = null;
    this.version = null;
    this.grupoEmpresarial = new GrupoEmpresarial();
    this.persona = new Persona();
  }
}
