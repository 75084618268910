
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { CrudBasicoService } from '../utilidades/crud-basico.service';

// SweetAlert
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { map, catchError } from 'rxjs/operators';
import { MensajesService } from '../utilidades/mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  private tableName = 'perfil';

  constructor(
    private _usuarioService: UsuarioService,
    private _crudBasicoService: CrudBasicoService,
    private _mensajeService: MensajesService,
    public http: HttpClient
  ) { }

  /**
  * Obtiene los accesos principales de acuerdo al perfil del usuario
  */
 obtenerPerfiles(idUsuario: string) {
  // Headers
  const headers = new HttpHeaders({
    'token_autorizado': this._usuarioService.token_autorizado,
    'Content-Type': 'application/json'
  });
  // URL
  const url = `${url_servicio}/perfil/usuario/${idUsuario}`;
  return this.http.get(url, { headers })
    .pipe(map((response: any) => {
      const roles = [];
      response.forEach(element => {
        roles.push(element.rol);
      });
      return roles;
    }), catchError(err => {
      this._mensajeService.errorCritico();
      return observableThrowError(err);
    }));
}
}
