import { PreguntaChecklist } from './pregunta-checklist.model';

export class EtiquetaMatriz {
  id?: string;
  etiqueta?: string;
  fila?: number;
  columna?: number;
  pregunta?: PreguntaChecklist;

  constructor() {
    this.id = null;
    this.etiqueta = null;
    this.fila = null;
    this.columna = null;
    this.pregunta = null;
  }
}
