import { ServicioMarcaVerificacion } from '../servicio/servicio-marca-verificacion.model';
import { Checklist } from './checklist.model';
import { TipoRespuesta } from './tipo-respuesta.model';

export class PreguntaChecklist {
  id?: string;
  numero?: number;
  pregunta?: string;
  explicacion?: string;
  fila?: number;
  columna?: number;
  tipoTexto?: boolean;
  tipoNumerica?: boolean;
  tipoRango?: boolean;
  tipoSeleccion?: boolean;
  tipoVerificacion?: boolean;
  campoObservacion?: boolean;
  parametrosNormales?: boolean;
  fechaCreacion?: Date;
  version?: number;
  requerida?: boolean;
  checklist?: Checklist;
  tipoRespuesta?: TipoRespuesta;
  servicioMarcaVerificacion?: ServicioMarcaVerificacion;

  constructor() {
    this.id = null;
    this.numero = null;
    this.pregunta = null;
    this.explicacion = null;
    this.fila = null;
    this.columna = null;
    this.tipoNumerica = null;
    this.tipoRango = null;
    this.tipoSeleccion = null;
    this.tipoVerificacion = null;
    this.campoObservacion = null;
    this.parametrosNormales = null;
    this.tipoTexto = null;
    this.fechaCreacion = null;
    this.version = null;
    this.requerida = null;
    this.checklist = null;
    this.tipoRespuesta = null;
    this.servicioMarcaVerificacion = null;
  }
}
