import { Empresa } from '../empresas/empresa.model';
import { EmpresaContratante } from '../empresa-contratante.model';

export class Cargo {
    id: string;
    nombre?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresa?: Empresa;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresa = new Empresa();
    }
}
