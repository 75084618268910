// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// rxjs
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
// Configuracion
import { url_servicio } from '../../../config/config';
// Servicios
import { UsuarioService } from '../../seguridad/usuario.service';
import { KendoService } from '../../utilidades/kendo/kendo.service';
import { MensajesService } from '../../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../../utilidades/abstract-crud-basico.service';
// Modelo
import { TipoMovimiento } from '../../../models/models.index';
// SweetAlert
import Swal from 'sweetalert2'
// Configuraciones
import { TipoMovimientoEnum } from '../../../config/tipo-movimiento.enum';
import { State } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})
export class MovimientoService extends AbtractCrudBasicoService {

  /**
   * Contructor del Servicio Elemento
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
    * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'movimiento');
  }

  getMovimientoPersonal(state: State) {
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoService._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    let url = `${url_servicio}/${this.tableName.toLowerCase()}/kendo/tecnico/`;
    url += `${this._kendoService.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this._kendoService.queryGrid(this.tableName, state, url)
      .pipe(
        map(data => {
          return data;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
   * Función para aceptar el Ingreso
   * @param idMovimiento Identificador del movimiento
   * @param contrasenaUsuarioFirma Contraseña del usuario que acepta el movimiento
   */
  aceptarIngreso(idMovimiento: string, contrasenaUsuarioFirma: string) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/aceptar/ingreso/${idMovimiento}`;
    return this.http.put(url, contrasenaUsuarioFirma, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
   * Función para aceptar los EGRESOS. EGRESOS POR PÉRDIDA y TRASLADOS
   * @param idMovimiento Identificador del movimiento
   * @param idPuntoServicio Identificador del Punto de servicio
   * @param contrasenaPDF Contraseña concatenada con el PDF en bse64 que se envía al correo
   */
  aceptarEgreso(idMovimiento: string, contrasenaPDF: string) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/aceptar/egreso/${idMovimiento}`;
    return this.http.put(url, contrasenaPDF, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
   * Función para comprobar si el movimiento es INGRESO
   * @param tipoMovimiento Tipo de movimiento para comprobar
   */
  esIngreso(tipoMovimiento: TipoMovimiento): boolean {
    if (tipoMovimiento && tipoMovimiento.id) {
      return tipoMovimiento.id === TipoMovimientoEnum.Ingreso;
    }
    return false;
  }

  /**
   * Función para comprobar si el movimiento es EGRESO
   * @param tipoMovimiento Tipo de movimiento para comprobar
   */
  esEgreso(tipoMovimiento: TipoMovimiento): boolean {
    if (tipoMovimiento && tipoMovimiento.id) {
      return tipoMovimiento.id === TipoMovimientoEnum.Egreso;
    }
    return false;
  }

  /**
   * Función para comprobar si el movimiento es EGRESO POR PÉRDIDA
   * @param tipoMovimiento Tipo de movimiento para comprobar
   */
  esEgresoPorPerdida(tipoMovimiento: TipoMovimiento): boolean {
    if (tipoMovimiento && tipoMovimiento.id) {
      return (tipoMovimiento.id === TipoMovimientoEnum.RegistroDeEntrega
      || tipoMovimiento.id === TipoMovimientoEnum.EgresoPorPerdida);
    }
    return false;
  }

  /**
   * Función para comprobar si el movimiento es TRASLADO
   * @param tipoMovimiento Tipo de movimiento para comprobar
   */
  esTraslado(tipoMovimiento: TipoMovimiento): boolean {
    if (tipoMovimiento && tipoMovimiento.id) {
      return tipoMovimiento.id === TipoMovimientoEnum.Traslado;
    }
    return false;
  }

  /**
   * Función para comprobar si el precio total del movimiento es igual a la suma de los subtotales
   * de los detalles del movimiento
   *
   * @param precioTotalDetalleServicio Es el precio total guardado en DetalleMovimientoService
   * @param precioTotalMovimiento Es el precio total del movimiento
   * @description Se comprueba que el precio total del movimiento sea igual a la suma de los subtotales de los detalles del movimiento
   * si no son iguales se debe actualizar el precio total del movimiento, lo cual se hace en el servidor
   */
  esPrecioTotalIgualSubtotal(precioTotalDetalleServicio: number, precioTotalMovimiento: number): boolean {
    // Se realiza el redondeo a 2 posiciones decimales para hacer la comparación mas confiable
    return (Math.round(precioTotalDetalleServicio * 100) / 100) === (Math.round(precioTotalMovimiento * 100) / 100);
  }
}
