// Módulos de angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CrudBasicoService,
  LoadingService,
  EmpresaContratanteService,
  NavbarService,
  VerificaTokenGuard,
  // Seguridad
  KendoUtilsService,
  KendoService,
  ValidarFormularioService,
  // Ubicación
  PaisService,
  ProvinciaService,
  CiudadService,
  // Empresas
  GrupoEmpresarialService,
  EmpresaService,
  PuntoServicioService,
  // Personas
  TituloService,
  CargoService,
  // Inventario
  IdentificacionTipoProductoService
} from './service.index';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    CrudBasicoService,
    LoadingService,
    EmpresaContratanteService,
    NavbarService,
    VerificaTokenGuard,
    // Seguridad
    KendoUtilsService,
    KendoService,
    ValidarFormularioService,
    // Ubicación
    PaisService,
    ProvinciaService,
    CiudadService,
    // Empresas
    GrupoEmpresarialService,
    EmpresaService,
    PuntoServicioService
  ],
  declarations: []
})
export class ServiceModule { }
