import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BreadCrumb } from '../../models/navegacion/breadcrumbs.model';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { BreadCrumbItem } from "@progress/kendo-angular-navigation";


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: [`
    .k-breadcrumb {
      border-width: 0px;
      border-style: solid;
      box-sizing: border-box;
      outline: 0;
      font-family: 'Lato', "Helvetica Neue", sans-serif;
      font-size: 12px;
      line-height: 2;
      display: flex;
      flex-direction: row-reverse;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      background-color: rgba(0, 0, 0, 0);
  }


  .k-breadcrumb-link, .k-breadcrumb-root-link {
    border-radius: 2px;
    padding-block: 4px;
    padding-inline: 10px;
    color: red !important;
    text-decoration: none;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    align-self: stretch;
    overflow: hidden;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
  `]
})
export class BreadcrumbsComponent implements OnInit {

  @Input('form') form: UntypedFormGroup;
  @Input('breadcrumbs') breadcrumbs: BreadCrumb[] = [];

  public items: any[] = [];

  constructor(private router: Router) { }

  ngOnInit() {
    this.breadcrumbs.forEach(breadcrumb => {
      this.items.push({
        text: breadcrumb.titulo.toUpperCase(),
      title: breadcrumb.titulo.toUpperCase(),
        ruta: breadcrumb.ruta,
        css: breadcrumb.clase
      });
    })

  }

  navegar(url) {
    if (this.form && this.form.dirty) {
      Swal
        .fire({
          text: "Los cambios efectuados en el formulario no se guardarán. ¿Está seguro de que desea continuar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#ffa726",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.router.navigateByUrl(url)
          }
        });
    } else {
      this.router.navigateByUrl(url)
    }
  }

  onItemClick($event) {  }

}
