import { Empresa } from '../empresas/empresa.model';
import { EmpresaContratante } from '../empresa-contratante.model';
import { Titulo } from './titulo.model';
import { Cargo } from './cargo.model';
import { HorarioTrabajoEmpleado } from './horario-trabajo-persona.model';

export class Persona {
  id: string;
  cedula?: string;
  nombreCompleto?: string;
  direccionDomiciliaria?: string;
  fotografia?: string;
  correoElectronico?: string;
  telefono?: string;
  observacion?: string;
  codigoPersonal?: string;
  fechaIngreso?: Date;
  permisoTotal?: boolean;
  permisoGrupoEmpresarial?: boolean;
  permisoEmpresa?: boolean;
  permisoPuntoServicio?: boolean;
  urlObjectivosDCA?: string;
  sueldo?: number;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  empresaContratante?: EmpresaContratante;
  empresa?: Empresa;
  titulo?: Titulo;
  cargo?: Cargo;
  horarioTrabajoEmpleado?: HorarioTrabajoEmpleado;

  // @Trasient
  contrasenaAutomatica?: boolean;
  notificarContrasena?: boolean;
  contrasena?: string;
  contrasenaAnterior?: string;
  idEmpresaContratanteDefault?: String;
  sessionid?: String;

  constructor() {
    this.id = null;
    this.cedula = null;
    this.nombreCompleto = null;
    this.direccionDomiciliaria = null;
    this.fotografia = null;
    this.correoElectronico = null;
    this.telefono = null;
    this.empresaContratante = null;
    this.observacion = null;
    this.codigoPersonal = null;
    this.fechaIngreso = null;
    this.permisoTotal = null;
    this.permisoGrupoEmpresarial = null;
    this.permisoEmpresa = null;
    this.permisoPuntoServicio = null;
    this.urlObjectivosDCA = null;
    this.sueldo = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.empresa = new Empresa();
    this.titulo = new Titulo();
    this.horarioTrabajoEmpleado = null;
    this.cargo = new Cargo();
    this.contrasenaAutomatica = null;
    this.notificarContrasena = null;
    this.contrasena = null;
    this.contrasenaAnterior = null;
  }
}
