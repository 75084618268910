
import { TipoProducto } from './tipo-producto.model';
import { UnidadMedida } from './unidad-medida.model';
import { Elemento } from './elemento.model';
import { Servicio } from '../models.index';
import { EmpresaContratante } from '../empresa-contratante.model';

export class ElementoUpdate {
    id: string;
    cantidad?: number;
    cantidadValidada?: number;
    codigo?: string;
    numeroSerie?: string;
    estado?: string;
    version?: number;
    fechaCreacion?: Date;
    marca?: string;
    modelo?: string;
    nombre?: string;
    elementoRaiz?: Elemento;
    servicio?: Servicio;
    tipoProducto?: TipoProducto;
    empresaContratante?: EmpresaContratante;
    unidadMedida?: UnidadMedida;


    constructor() {
        this.id = null;
        this.nombre = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.codigo = null;
        this.numeroSerie = null;
        this.cantidad = null;
        this.cantidadValidada = null;
        this.tipoProducto = null;
        this.marca = null;
        this.modelo = null;
        this.elementoRaiz = null;
        this.servicio = null;
        this.empresaContratante = null;
        this.unidadMedida = null;
    }
}
