import { PuntoServicio } from '../empresas/punto-servicio.model';

export class Ubicacion {
    id: string;
    nombre?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    puntoServicio?: PuntoServicio;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.puntoServicio = new PuntoServicio();
    }
}