import { EmpresaContratante } from '../empresa-contratante.model';
import { Noticia } from './noticia.model';

export class ContenidoNoticia {
    id?: string;
    titulo?: string;
    descripcion?: string;
    orden?: number;
    contenido?: string;
    estado?: string;
    fechaCreacion?: Date;
    version?: number;
    empresaContratante?: EmpresaContratante;
    noticia?: Noticia;

    constructor() {
        this.id = null;
        this.titulo = null;
        this.descripcion = null;
        this.orden = null;
        this.contenido = null;
        this.estado = null;
        this.fechaCreacion = null;
        this.version = null;
        this.empresaContratante = null;
        this.noticia = null;
    }
}
