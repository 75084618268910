import { Cotizacion } from './cotizacion.model';

export class DetalleCotizacion {
    id: string;
    item: string;
    descripcion: string;
    cantidad?: number;
    precioUnitario?: number;
    precioTotal?: number;
    cotizacion?: Cotizacion;
    fechaCreacion?: Date;

    constructor() {
        this.id = null;
        this.item = null;
        this.descripcion = null;
        this.cantidad = 0;
        this.precioUnitario = 0;
        this.precioTotal = 0;
        this.cotizacion = null;
        this.fechaCreacion = null;
    }
}
