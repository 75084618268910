// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { HoraServicioTransporte } from '../../models/models.index';

@Injectable({
  providedIn: 'root'
})
export class HoraServicioTransporteService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   *
   * @param http Servicio http de angular.
   * @param usuarioService Servicio de usuario.
   * @param kendoService Servicio kendo.
   * @param mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public usuarioService: UsuarioService,
    public kendoService: KendoService,
    public mensajeService: MensajesService
  ) {
    // http, usuarioService, kendoService, mensajeService, 'pathName', 'tableName'
    super(
      http,
      usuarioService,
      kendoService,
      mensajeService,
      'horaServicioTransporte',
      'hora_servicio_transporte'
    );
  }

  getHorasServicioTransporte(idServicio: string): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this.usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/porservicio/${idServicio}`;
    return this.http.get(url, { headers }).pipe(
      map(response => {
        return response;
      }),
      catchError(err => {
        this.mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  saveHorasServicioTransporte(
    horasServicioTransporte: HoraServicioTransporte[]
  ) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this.usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.pathName.toLowerCase()}`;

    // Editar
    if (horasServicioTransporte[0].id) {
      return this.http.put(url, horasServicioTransporte[0], { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            this.mensajeService.exito(response.mensaje);
          } else {
            this.mensajeService.error(response.error);
          }
          return response;
        }),
        catchError(err => {
          this.mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    } else {
      // Crear
      return this.http.post(url, horasServicioTransporte, { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            this.mensajeService.exito(response.mensaje);
          } else {
            this.mensajeService.error(response.error);
          }
          return response;
        }),
        catchError(err => {
          this.mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    }
  }
}
