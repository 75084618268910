import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { formatCurrency, formatPercent } from '@angular/common';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: number, format: string): string {
    if (value == null) {
      return '';
    }

    switch (format) {
      case 'number':
        return value.toString();
      case 'percentage':
        return formatPercent(value, this.locale)+'%';
      case 'currency':
        return formatCurrency(value, this.locale,'$');
      default:
        return value.toString();
    }
  }

}
