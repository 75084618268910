import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appRemoveSpecialCharacters]'
})
export class RemoveSpecialCharactersDirective {

  constructor(private control: NgControl) { }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Removemos los caracteres especiales automáticamente al escribir en el input
    const newValue = value.replace(/[^\w\s.@]/gi, '');
    // Asignamos el nuevo valor al control del input
    this.control.control.setValue(newValue, { emitEvent: false });
  }

}
