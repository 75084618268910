export const configuracionDemoBienvenida = [
  {
    anchorId: 'start.guias',
    title: 'Bienvenido',
    content:
      '<p>Bienvenido al Sistema de Gestión de Servicios "DecisionCloud". <br> Presiona en el icono ' +
      '<i class="material-icons">info</i> y podrás encontrar distintos tour del sistema, Finaliza y empieza un nuevo tour.</p>',
    endBtnTitle: 'Finalizar',
    containerClass: 'custom-tour-class',
  },
];
export const configuracionDemoServicio: any = [
  {
    anchorId: 'start.tour',
    content:
      '<strong>Bienvenido</strong><br><strong>Tour General</strong><br><p>Bienvenido al Sistema de Gestión de Servicios.' +
      ' Tour que describe de manera general el proceso del sistema.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    route: 'servicio/servicio',
  },
  {
    // SERVICIO
    anchorId: '5b1a2e8ep847351e2342bea1285', // Servicio menu
    content:
      '<strong>Módulo de Servicio</strong><br>' +
      '<p>En este módulo se crea el ticket de servicio, se lo gestiona , aprueba y verifica.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    // endBtnTitle: true
  },
  {
    anchorId: 'servicio.crearSolicitud', // crear
    content: '<p>¿Cómo se crea un ticket de servicio?</p>',
    endBtnTitle: 'fin',
    placement: 'right',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'nav.titulo', // titulo servicio
    content: '<p>Datos de un ticket de servicio.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    route: 'servicio/servicio/5f5f78dc94b15331453d0c7272',
  },
  {
    anchorId: 'servicio.numero',
    content: '<p>Número único del ticket.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    // endBtnTitle: true
  },
  {
    anchorId: 'servicio.seccionSolicitud',
    content: '<strong>Contenido de la Solicitud</strong><br>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.tipoServicio',
    content: '<p>Identifica el tipo de Servicio técnico.</p>',
    endBtnTitle: 'fin',
    placement: 'left',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.proveedorServicio',
    content:
      '<strong>Información del Proveedor del Servicio</strong><br><p>Empresa proveedora y persona responsable del servicio.</p>',
    endBtnTitle: 'fin',
    placement: 'right',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.receptorServicio',
    content:
      '<strong>Información del Receptor del Servicio</strong><br>' +
      '<p>Empresa receptora, lugar donde se requiere el servicio y persona quién lo solicita.</p>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.informacionAdicional',
    content:
      '<strong>Información Adicional</strong><br><p>Proyecto, Centro de Costo, y si el servicio es en garantía.</p>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.requerimiento',
    content:
      '<strong>Requerimiento</strong><br><p>Cuándo debe ejecutarse y que requiere el cliente.</p>' +
      '<div class="text-right">Desliza  <i class="material-icons vert-move">keyboard_arrow_down</i></div>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.equipoRequiereServicio',
    content:
      '<strong>Equipos / Sistemas</strong><br><p>Opcionalmente la identificación del equipo que requiere servicio.</p>' +
      '<div class="text-right">Desliza  <i class="material-icons vert-move">keyboard_arrow_down</i></div>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.seccionServicio',
    content: '<strong>Descripción del Servicio Técnico Realizado</strong><br>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.equipo',
    content:
      '<strong>Equipos / Sistemas atendidos </strong><br><p>y opcionalmente repuestos utilizados.</p>',
    endBtnTitle: 'fin',
    placement: 'right',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.trabajoRealizado',
    content:
      '<strong>Trabajo Realizado</strong><br><p>El técnico describe el trabajo realizado.</p>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.horasServicio',
    content:
      '<strong>Horas de Servicio</strong><br><p>Registro de los técnicos y las horas trabajadas.</p>' +
      '<div class="text-right">Desliza  <i class="material-icons vert-move">keyboard_arrow_down</i></div>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.novedadServicio',
    content:
      '<strong>Novedades del Servicio</strong><br><p>Registro de novedades, si las hubiera, para luego ser gestionadas.</p>' +
      '<div class="text-right">Desliza  <i class="material-icons vert-move">keyboard_arrow_down</i></div>',
    endBtnTitle: 'fin',
    placement: 'right',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.archivosAdjunto',
    content:
      '<strong>Archivos Adjuntos</strong><br><p>Fotografías, planos, archivos, videos, checklist, etc..</p>' +
      '<div class="text-right">Desliza  <i class="material-icons vert-move">keyboard_arrow_down</i></div>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.seccionAceptacion',
    content: '<strong>Aceptación / Aprobación</strong><br>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.resumenCabecera',
    content: '<p>Resumen del ticket de servicio.</p>',
    endBtnTitle: 'fin',
    placement: 'top',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.resumenAceptacion',
    content:
      '<strong>Aceptación del Servicio</strong><br><p>Persona que acepta el ticket de servicio.</p>' +
      '<div class="text-right">Desliza  <i class="material-icons vert-move">keyboard_arrow_down</i></div>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'servicio.resumenAprobacion',
    content:
      '<strong>Verificación del Supervisor</strong><br><p>Validación final.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
];

export const configuracionDemoConsultas: any = [
  {
    // Consultas
    anchorId: '5b71d7fe360920111b442de192', // Consulta menu
    content:
      '<p><strong>Bienvenido al tour de consultas del Sistema de Gestión de Servicio.</strong></p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    route: 'consultas',
    // endBtnTitle: true
  },
  {
    anchorId: '5b8da1fa8f83471025a87d5b99', // Servicio ejecutados
    content:
      '<p>En esta sección podemos ejecutar varias consultas. Seleccionamos la consulta de <strong>Servicios</strong>.</p>',
    endBtnTitle: 'fin',
    placement: 'right',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'nav.titulo', // titulo servicio
    content:
      '<p>Ahora podemos seleccionar los parámetros de búsqueda. Para el ejemplo: ' +
      '<ul><li>Rango de fechas: 1/12/2018 - 28/02/2019</li></ul></p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    route: 'consultas/servicio/reporte-servicios-ejecutados',
  },
  {
    anchorId: 'serviciosEjecutados.buscar',
    content:
      '<p><strong> La consulta se realizará automáticamente, espera unos segundos </strong>' +
      '<i class="material-icons fa-spin">sync</i>' +
      '</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'nav.titulo',
    content:
      '<p>Éstos son los resultados de la consulta basados en los parámetros de búsqueda.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'serviciosEjecutados.regresar',
    content:
      '<p>Si requiere cambiar los parámetros de búsqueda puedes dar click en regresar.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'serviciosEjecutados.kendo',
    content:
      '<p>Puede exportar los datos a Excel <span class="k-icon k-i-file-excel"></span> o ' +
      ' PDF <span class="k-icon k-i-file-pdf"></span></p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
  {
    anchorId: 'serviciosEjecutados.kendo',
    content:
      '<div>Junto al título de las columnas <span class="k-icon k-i-more-vertical"></span>' +
      ' es factible realizar otras acciones como: ' +
      '<ul><li>Agrupar los datos</li>' +
      '<li>Añadir o quitar columnas </li>' +
      '<li>Definir nuevos filtros</li>' +
      '</ul>' +
      'Intentalo dando click en ' +
      '<span class="k-icon k-i-more-vertical"></span></div> ' +
      '<div class="text-right"><a href="https://youtu.be/bEksHkMhSMk?list=PLk6mUPEhsZdxht1an9cOSFSbnXvM6cPkn&t=141" target="_blank">' +
      ' Reproducir video <i class="material-icons">ondemand_video</i></a></div>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
  {
    anchorId: 'serviciosEjecutados.kendo',
    content:
      '<p> Es factible visualizar los datos gráficamente por diferentes conceptos.' +
      ' Intentalo dando click en <i class="material-icons">bar_chart</i> Generar Gráficos' +
      '</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
  // Consultas de novedades
  {
    anchorId: '5b71d7fe360920111b442de192', // Consulta menu
    content: '<p><strong>Ahora veamos otra consulta</strong></p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    route: 'consultas',
    // endBtnTitle: true
  },
  {
    anchorId: '5b8da1fa8f83471025a87d5b99', // Servicio Ejecutados Novedades
    content:
      '<p>En esta sección podemos ejecutar varias consultas.' +
      ' Seleccionamos la consulta de <strong>Novedades del Servicio</strong>.</p>',
    endBtnTitle: 'fin',
    placement: 'right',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'nav.titulo', // titulo servicio
    content:
      '<div>Ahora podemos seleccionar los parámetros de búsqueda. Para el ejemplo:' +
      '<ul><li>Rango de fechas: 1/12/2018 - 28/02/2019</li>' +
      '<li>Reclamos de clientes</li>' +
      '</ul></div>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    route: 'consultas/servicio/reporte-servicios-ejecutados-novedad',
  },
  {
    anchorId: 'serviciosEjecutadosNovedad.buscar',
    content:
      '<p><strong> La consulta se realizará automáticamente, espera unos segundos </strong>' +
      '<i class="material-icons fa-spin">sync</i>' +
      '</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'nav.titulo',
    content:
      '<p>Éstos son los resultados de la consulta basados en los parámetros de búsqueda.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'serviciosEjecutadosNovedad.regresar',
    content:
      '<p>Si requiere cambiar los parámetros de búsqueda puedes dar click en regresar.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
  },
  {
    anchorId: 'serviciosEjecutadosNovedad.kendo',
    content:
      '<p>Puede exportar los datos a Excel <span class="k-icon k-i-file-excel"></span> o ' +
      ' PDF <span class="k-icon k-i-file-pdf"></span></p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
  {
    anchorId: 'serviciosEjecutadosNovedad.kendo',
    content:
      '<p>Si desea visualizar el informe de servicio completo dale click sobre el ' +
      '<a class="help">N° del servicio en color tomate</a>.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
  {
    anchorId: 'serviciosEjecutadosNovedad.kendo',
    content:
      '<p>Para visualizar el texto completo del reclamo, coloca el cursor sobre texto columna Detalles.</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
  {
    anchorId: 'serviciosEjecutadosNovedad.kendo',
    content:
      '<p> Es factible visualizar los datos gráficamente por diferentes conceptos.' +
      ' Intentalo dando click en <i class="material-icons">bar_chart</i> Generar Gráficos' +
      '</p>',
    endBtnTitle: 'fin',
    containerClass: 'custom-tour-class',
    placement: 'top',
  },
];
