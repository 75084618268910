import { Directive, EventEmitter, HostListener, Output, ElementRef } from '@angular/core';

@Directive({
  selector: '[ngModel][appUppercase]',
})
export class UppercaseDirective {

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.textTransform = 'uppercase';
  }

  // @HostListener('focus', ['$event']) onInputFocus($event) {
  //   this.value = $event.target.value.toUpperCase();
  //   this.ngModelChange.emit(this.value);
  // }

  @HostListener('blur', ['$event']) onInputFocusout($event) {
    this.value = $event.target.value.toUpperCase();
    this.ngModelChange.emit(this.value);
  }

}
