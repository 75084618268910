<div class="row ancho-total">
    <div class="col-12">
        <div class="gap8-right">
            <div *ngIf="mostrarBusqueda" class="label-floating" style="width: 100%;">
                <kendo-textbox style="width: 100%;" id="buscar" #buscar placeholder="Buscar..."
                               (keyup)="buscarInformacion(buscar.value)"
                               name="buscar" type="search">
                    <ng-template kendoTextBoxSuffixTemplate>
                        <kendo-icon name="search"></kendo-icon>
                    </ng-template>
                </kendo-textbox>
            </div>

            <!-- BOTÓN VISTA -->
            <a class="btn-header-grid cursor" [matMenuTriggerFor]="menuVista"
               matTooltip="Vista (tabla)"
               matTooltipPosition="above">
                <mat-icon
                          [style.color]="activarVistaGuardada ? '#f57c00' : '#6c6c6c'">view_list</mat-icon>
            </a>
            <mat-menu #menuVista="matMenu">
                <a mat-menu-item (click)="saveVista()">
                    <mat-icon>settings</mat-icon>
                    <span>Guardar vista</span>
                </a>
                <a mat-menu-item (click)="restaurarVista()">
                    <mat-icon>restore</mat-icon>
                    <span>Restaurar vista</span>
                </a>
            </mat-menu>
            <!-- BOTÓN VISTA -->

            <!-- BOTÓN CONFIGURACION -->
            <a class="btn-header-grid cursor" [matMenuTriggerFor]="menu"
               matTooltip="Parametrización"
               *ngIf="menuConfiguracion.length > 0"
               matTooltipPosition="above">
                <mat-icon>settings</mat-icon>
            </a>
            <mat-menu #menu="matMenu">
                <a mat-menu-item href="{{menu.ruta}}" target="_blank"
                   *ngFor="let menu of menuConfiguracion">
                    <mat-icon>{{menu.icono}}</mat-icon>
                    <span>{{menu.nombre}}</span>
                </a>
            </mat-menu>
            <!-- BOTÓN CONFIGURACION -->





            <!-- BOTÓN FILTRO -->
            <a class="btn-header-grid cursor" [matMenuTriggerFor]="menuFiltros"
               matTooltip="Filtro"
               matTooltipPosition="above">
                <mat-icon
                          [style.color]="estado !== estadoRegistro.Activo ? '#f57c00' : '#6c6c6c'">filter_alt</mat-icon>
            </a>
            <mat-menu #menuFiltros="matMenu">
                <span mat-menu-item (click)="cambioEstado(estadoRegistro.Activo)">
                    <input
                           type="radio"
                           name="estado"
                           [value]="estadoRegistro.Activo"
                           [(ngModel)]="estado"
                           kendoRadioButton />
                    <kendo-label [for]="estado" text="Activos"></kendo-label>
                </span>
                <span mat-menu-item (click)="cambioEstado(estadoRegistro.Inactivo)">
                    <input
                           type="radio"
                           name="estado"
                           [value]="estadoRegistro.Inactivo"
                           [(ngModel)]="estado"
                           kendoRadioButton />
                    <kendo-label [for]="estado" text="Inactivo"></kendo-label>
                </span>
                <span mat-menu-item (click)="cambioEstado(estadoRegistro.Todos)">
                    <input
                           type="radio"
                           name="estado"
                           [value]="estadoRegistro.Todos"
                           [(ngModel)]="estado"
                           kendoRadioButton />
                    <kendo-label [for]="estado" text="Todos"></kendo-label>
                </span>
            </mat-menu>
            <!-- BOTÓN FILTRO -->

            <!-- BOTON EXPORTAR -->
            <a class="btn-header-grid cursor" *ngIf="accion.exportar && (mostrarExcel || mostrarPDF)"            
               [matMenuTriggerFor]="menu2"
               matTooltip="Descargar"
               matTooltipPosition="above">
                <mat-icon>download</mat-icon>
            </a>
            <mat-menu #menu2="matMenu">
                <a name="excel" mat-menu-item *ngIf="mostrarExcel">
                    <div style="box-shadow: none !important;" kendoGridExcelCommand>
                        <span class="k-icon k-i-file-excel" style="margin-right: 4px !important"></span>
                        <span>Excel</span>
                    </div>
                </a>

                <!-- <a name="pdf" mat-menu-item *ngIf="mostrarPDF">
                    <div style="box-shadow: none !important;" kendoGridPDFCommand>
                        <span class="k-icon k-i-file-pdf" style="margin-right: 4px !important"></span>
                        <span>Pdf</span>
                    </div>
                </a>                 -->
            </mat-menu>



            <!-- BOTON CREAR -->
            <span matTooltip="{{accion.crear ? 'Crear' : 'No tiene permiso para crear.'}}"
                  matTooltipPosition="above">
                <button *ngIf="mostrarCrear && (!gridSettings || !gridSettings.url)" [disabled]="!accion.crear"
                        type="button"
                        class="btn btn-primary btn-round btn-fab" (click)="cargarInformacion()">
                    <span class="mat-button-wrapper">
                        <i class="material-icons">add</i>
                    </span>
                </button>

                <a *ngIf="mostrarCrear && (gridSettings && gridSettings.url)" [routerLink]="[gridSettings.url, 'new']"
                   [class.disabled]="!accion.crear"
                   [attr.aria-disabled]="!accion.crear ? 'true' : null"
                   [ngClass]="{'btn': true, 'btn-primary': true, 'btn-round': true, 'btn-fab': true, 'disabled': !accion.crear}">
                    <span class="mat-button-wrapper">
                        <i class="material-icons">add</i>
                    </span>
                </a>
            </span>
            <!-- BOTON CREAR -->
        </div>
    </div>

    <div class="col-12 text-right logServicio">
        <b>Visualizando registros: </b>
        <span [ngSwitch]="estado" style="color: #6c6c6c;">
            <span *ngSwitchCase="'TDS'">
                TODOS
            </span>
            <span *ngSwitchCase="'INA'">
                INACTIVOS
            </span>
            <span *ngSwitchDefault>
                ACTIVOS
            </span>
        </span>
    </div>

</div>