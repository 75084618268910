import { Usuario } from './usuario.model';

export class DiaHoraAcceso {
  usuario: Usuario;
  lun: boolean;
  mar: boolean;
  mie: boolean;
  jue: boolean;
  vie: boolean;
  sab: boolean;
  dom: boolean;
  horaInicio?: Date;
  horaFin?: Date;

  constructor() {
    this.usuario = null;
    this.lun = null;
    this.mar = null;
    this.mie = null;
    this.jue = null;
    this.vie = null;
    this.sab = null;
    this.dom = null;
    this.horaInicio = null;
    this.horaFin = null;
  }
}
