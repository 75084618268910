<div class="sidebar-wrapper">
    <div class="ph-item">
        <div class="ph-row">
            <div class="ph-col-12 big"></div>
        </div>
        <div class="ph-col-12"></div>
        <hr>
        <div class="ph-col-12"></div>
        <div class="ph-col-2">
            <div class="ph-avatar"></div>
        </div>
        <div>
            <div class="ph-row">
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
            </div>
        </div>
        <div class="ph-col-12"></div>
        <hr>
        <div class="ph-col-12">
            <div class="ph-row">
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12 empty"></div>
                <div class="ph-col-12 big"></div>
            </div>
        </div>
    </div>
</div>