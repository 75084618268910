import { EmpresaContratante } from "../empresa-contratante.model";

export class GrupoCampoServicio {
  id: string;
  nombre?: string;
  observacion?: string;
  orden?: number;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  empresaContratante?: EmpresaContratante;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.orden = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.empresaContratante = null;
  }
}
