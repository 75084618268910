import { DetalleMovimientoPK } from './detalle-movimiento-pk.model';
import { Movimiento } from './movimiento.model';
import { Elemento } from './elemento.model';

export class DetalleMovimiento {
    detalleMovimientoPK: DetalleMovimientoPK;
    cantidad?: number;
    subtotal?: number;
    version?: number;
    movimiento?: Movimiento;
    elemento?: Elemento;

    constructor() {
        this.detalleMovimientoPK = null;
        this.cantidad = null;
        this.subtotal = null;
        this.version = null;
        this.movimiento = null;
        this.elemento = null;
    }
}
