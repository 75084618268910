<footer class="footer-app">
    <div class="container">
        <div class="row">
            <div class="col-6 text-left" style="font-size: 11px">
                Desarrollado por <a class="help" href="https://www.decision.com.ec/" target="_blank"><b>DECISIÓN c.a.</b></a>  <br>
                Copyright &copy; 2013 - {{fechaCopyright | date: 'yyyy'}} | Versión: <b>{{version}}</b>
            </div>
            <div class="col-6 text-right">
                <a class="help" href="https://www.decision.com.ec/decisioncloud-gestion-servicios/" target="_blank"><b><img style="margin-top: 10px" src="../../../assets/img/decisioncloud_pdf.png" height="30"></b></a>
            </div>
        </div>
    </div>
</footer>