<div class="w-100 text-right">
<!-- <div class="col">

    <kendo-breadcrumb
                      [items]="items"
                      (itemClick)="navegar($event.ruta)">
    </kendo-breadcrumb>
</div> -->

    <ol class="breadcrumb">
        <ol class="breadcrumb">
            <li *ngFor="let breadcrumb of breadcrumbs; let i=index" [class]="breadcrumb.clase">
                <a class="help" *ngIf="i!==(breadcrumbs.length-1)"
                   (click)="navegar(breadcrumb.ruta)">
                    <b>{{breadcrumb.titulo | uppercase}}</b>
                </a>
                <span *ngIf="i===(breadcrumbs.length-1)" aria-current="page">
                    {{breadcrumb.titulo | uppercase}}
                </span>
            </li>
        </ol>
    </ol>
</div>