// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, ignoreElements } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { StateConsulta } from '../../models/kendo/state-consulta.interface';
import { KendoUtilsService } from '../utilidades/kendo/kendo-utils.service';
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root',
})
export class FacturacionServicioService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService,
    public _kendoUtilsService: KendoUtilsService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName' , urlconsulta
    super(
      http,
      _usuarioService,
      _kendoService,
      _mensajeService,
      'consultaFacturacionServicio',
      'consultaFacturacionServicio',
      'consultas/facturacion/servicio'
    );
  }

  /**
   * Servicio para obtener las fechas de requerimiento del servicio
   */
  getConsultaFacturacion(state: StateConsulta): Observable<any> {
    this.loadingGrid = true;
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json',
    });

    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Arma la url de la petición
    let url = `${url_servicio}/consultas/facturacion/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        if (response.data) {
          this.loadingGrid = false;
          return response.data
            ? ({
                data: response.data,
                total: parseInt(response.total, 10),
                aggregates: response.aggregates,
              } as GridDataResult)
            : response;
        } else {
          return response;
        }
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Servicio para obtener las fechas de requerimiento del servicio
   */
  getConsultaFacturacionDocumento(state: StateConsulta): Observable<any> {
    this.loadingGrid = true;

    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json',
    });

    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Arma la url de la petición
    let url = `${url_servicio}/consultas/facturacion/documentos/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        if (response.data) {
          this.loadingGrid = false;
          return response.data
            ? ({
                data: response.data,
                total: parseInt(response.total, 10),
                aggregates: response.aggregates,
              } as GridDataResult)
            : response;
        } else {
          return response;
        }
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Servicio para obtener las fechas de requerimiento del servicio
   */
  getConsultaByContrato(
    idContrato: string,
    diasFeriado: any,
    state: StateConsulta
  ): Observable<any> {
    this.loadingGrid = true;
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json',
    });

    // Nueva instancia de la variable state
    const stateGrid: StateConsulta = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // Arma la url de la petición
    let url = `${url_servicio}/${this.urlConsulta.toLowerCase()}/${idContrato}/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.put(url, diasFeriado, { headers }).pipe(
      map((response: any) => {
        this.loadingGrid = false;
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Servicio para obtener las fechas de requerimiento del servicio
   */
  facturarServicio(numeroFactura: string, idsServicio: any) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json',
    });

    // Arma la url de la petición
    const url = `${url_servicio}/consultas/facturacion/facturar/servicio/${numeroFactura}/${idsServicio}`;
    return this.http.put(url, null, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          this._mensajeService.exito(response.mensaje);
        }
        return response;
      }),
      catchError((err) => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  /**
   * Obtener el nombre del archivo para exportar a PDF
   */
  obtenerNombreArchivo(): string {
    return `N° prueba.pdf`;
  }
}
