<form class="animated fadeIn fast"
      [formGroup]="genericForm"
      (ngSubmit)="save()">
    <div class="card" *ngIf="!placeholderLoading">
        <div class="card-header card-header-icon card-header-warning">
            <div class="card-icon" data-background-color="orange">
                <i class="material-icons">handshake</i>
            </div>
            <h4 class="card-title">
                <small>TÉRMINOS Y CONDICIONES</small>
            </h4>
        </div>
        <div class="card-body">
            <mat-dialog-content>
                <div class="row justify-content-center align-items-center"
                     *ngIf="!placeholderLoading">
                    <div class="text-center">
                        <img id="imgPrincipal" class="imgPrincipal"
                             src="../../../assets/img/terminos-condiciones.jpg"
                             style="max-height: 200px; width: auto; display: block; margin: 0 auto;">
                    </div>

                    <div class="mt-2">
                        <mat-checkbox formControlName="aceptacionTerminos" id="aceptacionTerminos"
                                      name="aceptacionTerminos">
                            <label for="aceptacionTerminos" class="form-check-label">
                                He leído y estoy de acuerdo con los <strong>Terminos y
                                    condiciones.</strong>
                                <br>

                            </label>
                        </mat-checkbox>
                        <div>
                            <ul>
                                <li *ngIf="terminoCondicion.urlOferta">
                                    <a [href]="terminoCondicion.urlOferta" target="_blank"
                                       matTooltip="Visualizar Oferta" matTooltipPosition="above">
                                        <strong>Oferta</strong>
                                    </a>
                                </li>
                                <li *ngIf="terminoCondicion.urlContrato">
                                    <a [href]="terminoCondicion.urlContrato" target="_blank"
                                       matTooltip="Visualizar Contrato" matTooltipPosition="above">
                                        <strong>Contrato</strong>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
        </div>
        <div class="card-footer">
            <div class="col-lg-6 col-md-6 text-left">
                <div class="category form-category">
                    <span style="color: red">*</span> Campos requeridos
                </div>
            </div>
            <div class="col-lg-6 col-md-6 text-right">
                <button type="submit" class="btn btn-sm btn-primary"
                        [disabled]="!genericForm.controls.aceptacionTerminos.value">
                    <i class="material-icons" *ngIf="!enProceso">save</i>
                    <i class="material-icons fa-spin" *ngIf="enProceso">sync</i>
                </button>
            </div>
        </div>
    </div>
</form>