// Angular
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { idServicio, idServicioV, longitud } from 'src/app/config/config';

@Injectable({
    providedIn: 'root'
})
export class EncriptacionAesService {

    base64Key = 'LU1jZXRtc0taUz9TWXczRw==';
    base64iv = 'TDhQVTdRRlVOUzJMUjRUNw==';

    constructor(
        public router: Router) {

    }
    encryptData(textoDesencriptado) {
        try {
            const data = JSON.stringify(textoDesencriptado);
            const encryptedData = CryptoJS.AES.encrypt(data, CryptoJS.enc.Base64.parse(this.base64Key), {
                mode: CryptoJS.mode.CBC,
                iv: CryptoJS.enc.Base64.parse(this.base64iv),
                padding: CryptoJS.pad.Pkcs7
            });
            if (textoDesencriptado !== Object(textoDesencriptado)) {
                return textoDesencriptado;
            } else {
                return JSON.parse('{"dcgs":"' + encryptedData.toString() + '"}');
            }
        } catch (error) {
            this.router.navigate(['/login']);
            localStorage.removeItem('dcgs');
        }
    }
    decryptData(textoDesencriptado) {
        try {
            const decryptedData = CryptoJS.AES.decrypt(textoDesencriptado, CryptoJS.enc.Base64.parse(this.base64Key), {
                mode: CryptoJS.mode.CBC,
                iv: CryptoJS.enc.Base64.parse(this.base64iv),
                padding: CryptoJS.pad.Pkcs7
            });
            return JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            this.router.navigate(['/login']);
            localStorage.removeItem('dcgs');
        }
    }



    fm(texto: string,) {
        try {
            const fm = CryptoJS.AES.decrypt(texto, CryptoJS.enc.Base64.parse(this.base64Key), {
                mode: CryptoJS.mode.CBC,
                iv: CryptoJS.enc.Base64.parse(this.base64iv),
                padding: CryptoJS.pad.Pkcs7
            });
            return JSON.parse(fm.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            console.error(error);
        }
    }

    am(texto: string) {
        try {
            const ef = CryptoJS.AES.encrypt(texto, CryptoJS.enc.Base64.parse(this.base64Key), {
                mode: CryptoJS.mode.CBC,
                iv: CryptoJS.enc.Base64.parse(this.base64iv),
                padding: CryptoJS.pad.Pkcs7
            });
            return ef.toString();
        } catch (error) {
            console.error(error);
        }
    }
    

}
