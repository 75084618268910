// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

// Kendo
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnMenuSettings, DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { NotificationService } from "@progress/kendo-angular-notification";


// RXJS
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

// Enums
import { EstadoRegistro } from '../../../app/config/estado-registro.enum';

// Servicios
import { AccionService, DocumentsMethodsService, MensajesService, NavbarService, StatePersistingKendoService, UsuarioService } from 'src/app/services/service.index';

// Modelos
import { Accion } from '../../../app/models/models.index';
import { ColumnSettings } from '../../../app/services/utilidades/kendo/column-settings.interface';
import { GridSettings } from '../../../app/services/utilidades/kendo/grid-settings.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-kendo-grid-crud',
  templateUrl: './kendo-grid-crud.component.html',
  styles: [
    `
    input[type="checkbox"][readonly] {
      pointer-events: none;
    }
    `
  ]
})
export class KendoGridCrudComponent {

  // Propiedades de entrada del componente
  @Input('gridService') gridService: any;
  @Input('gridSettings') gridSettings: GridSettings;
  @Input('menuConfiguracion') menuConfiguracion: any = [];
  @Input('materialSoporte') materialSoporte: any = [];
  @Input('kendoView') kendoView: boolean = false;
  filterDefault: any;

  // Evento de salida para cargar un registro específico
  @Output('cargarRegistro') cargarRegistro: EventEmitter<any> = new EventEmitter(); //Inicia búsqueda por un término
  @Output('abrirModal') abrirModal: EventEmitter<any> = new EventEmitter();

  // Subject y filtro utilizado para realizar búsquedas en el estado del componente
  stateSubject = new BehaviorSubject<string>(null);
  filterValue: string;

  // Variables para el manejo del filtro estado
  estadoRegistro = EstadoRegistro;
  estado: string = EstadoRegistro.Activo;

  // Variables para el proceso
  placeholderLoading: boolean;
  public dataGrid: Observable<GridDataResult>;
  accion: Accion;
  state: any;
  urlView: string;
  terminoPolitica: boolean = false;

  /**
   * 
   * @param _navbarService 
   * @param _accionService 
   * @param _mensajesService 
   * @param statePersistingKendoService 
   * @param _documentsMethodsService 
   */
  constructor(
    private _navbarService: NavbarService,
    private _accionService: AccionService,
    private _mensajesService: MensajesService,
    private statePersistingKendoService: StatePersistingKendoService,
    private _documentsMethodsService: DocumentsMethodsService,
    public _usuarioService: UsuarioService,
    private _router: Router
  ) {

  }

  /**
   * Método que se ejecuta al inicializar el componente.
   */
  ngOnInit(): void {

    // Obtiene el filtro por defecto enviado desde el padre
    this.filterDefault = { logic: 'and', filters: [] };
    if (this.gridSettings.state?.filter) {
      this.filterDefault = JSON.parse(JSON.stringify(this.gridSettings.state?.filter));
    }

    // Url view
    if (this.gridSettings.url) {
      this.urlView = JSON.parse(JSON.stringify(this.gridSettings.url));
    }

    // No muestra las columnas que no tienen acceso        
    this.gridSettings.columnsConfig = this.gridSettings.columnsConfig.filter(column => !column.notShowColumn);

    // Inicializa las variables
    this.placeholderLoading = true;
    this.filterValue = '';

    // Valida si tiene permiso para visualizar la información
    this._accionService.getByIDAcceso(this.gridSettings.accesoEnum).subscribe((accion) => {
      this.accion = accion;

      if (this.accion && this.accion.ver) {

        // Actualiza el navbar
        this._navbarService.updateTittleAcceso(this.gridSettings.accesoEnum, this.materialSoporte);

        // Inicializa el state
        this.state = this.stateDefault();

        // Inicializa la persistencia de kendo
        this.initKendoPersistence();

        // Inicializa el componente de búsqueda
        this.cargarStateSubjectSearch();

        // Carga de datos para kendo Grid
        this.cargarKendoGrid();
        this.placeholderLoading = false;

      } else {
        // Mensaje de acceso denegado
        this._mensajesService.accesoDenegado();
      }
    });
  }

  cargarKendoGrid() {
    if (this.kendoView) {
      //Icono de visualizar para las tablas politicas y terminos cuando han sido finalizadas
      if (this.gridSettings.terminoPolitica) {
        this.gridService.getKendoView(this.state).subscribe(resp => {
          const elementoValido = resp.data.find(element =>
            element.terminoCondicionFinalizada || element.politicaFinalizada
          );
          if (elementoValido) {
            this.terminoPolitica = elementoValido.terminoCondicionFinalizada || elementoValido.politicaFinalizada;
          }
        });
      }
      this.dataGrid = this.gridService.getKendoView(this.state);
    } else {
      this.dataGrid = this.gridService.getKendo(this.state);
    }
  }

  /**
   * Función que carga el buscador
   */
  cargarStateSubjectSearch() {
    this.stateSubject.pipe(debounceTime(200), distinctUntilChanged()).subscribe((value) => {
      if (value !== null) {
        this.state = this.stateDefault();
        this.cargarKendoGrid();
      }
    });
  }

  /**
    * Función que actualiza la data cuando existe un cambio de estado
    * @param e 
    */
  filtrarEstado(e) {
    this.estado = e;
    this.state = this.stateDefault();
    this.cargarKendoGrid();
  }

  /**
   * Función que retorna el state por defecto para realizar la consulta
   * @param value 
   * @returns 
   */
  stateDefault(value = null) {

    const state = JSON.parse(JSON.stringify(this.gridSettings.state));
    state.filter = JSON.parse(JSON.stringify(this.filterDefault));

    switch (this.estado) {
      case EstadoRegistro.Activo:
      case EstadoRegistro.Inactivo:
        state.filter.filters.push({
          field: "estado",
          operator: "eq",
          value: this.estado,
        });
        break;
    }

    if (this.filterValue !== null && this.filterValue.length > 0) {
      const columns = this.gridSettings.columnsConfig.filter(column => column.filterable && !column.filter);
      const filterSearch = {
        logic: "or",
        filters: []
      };

      columns.forEach(column => {
        filterSearch.filters.push({
          field: column.field,
          operator: "contains",
          value: this.filterValue,
        })
      });

      // Carga de datos para kendo Grid
      state.filter.filters.push(filterSearch);

    }
    return state;
  }


  /**
   * Evento usado para abrir modal
   * @param registro 
   */
  cargarModalEvent(registro) {
    this.abrirModal.emit(registro);
  }

  /**
   * Evento usado en el botón crear y editar
   * @param idRegistro 
   */
  cargarRegistroEvent(idRegistro) {

    // Valida si se crea un nuevo Documento, crea Factura por defecto
    if (idRegistro === 'new' && this.gridSettings.identificador === 'Documentos') {
      this.cargarModalEvent(1);
      return;
    }
    this.cargarRegistro.emit(idRegistro);
  }

  /**
   * Función para el cambio de estado de la entidad
   * @param entidad 
   */
  cargarEliminarEvent(entidad) {
    if (this.accion.eliminar) {
      this._mensajesService
        .confirmacionCambiarEstado(entidad)
        .then((confirmacion: boolean) => {
          if (confirmacion) {
            this.gridService.cambiarEstado(entidad)
              .subscribe(() => {
                this.cargarKendoGrid();
              });
          }
        });
    }
  }

  /**
   * Función que inicializa la persistencia de kendo
   */
  initKendoPersistence() {
    const gridSettings: GridSettings = this.statePersistingKendoService.get('gridSettings' + this.gridSettings.identificador);
    if (gridSettings) {
      this.gridSettings = this.mapGridSettings(gridSettings);
    }
  }

  /**
   * Función privada para la persistencia de kendo
   * @param gridSettings 
   * @returns 
   */
  private mapGridSettings(gridSettings: GridSettings): GridSettings {
    const state = gridSettings.state;
    const stateDefault = this.stateDefault();

    state.filter = stateDefault.filter;



    const identificador = gridSettings.identificador;
    const accesoEnum = gridSettings.accesoEnum;
    this.mapDateFilter(state.filter);

    return {
      state,
      identificador,
      accesoEnum,
      columnsConfig: gridSettings.columnsConfig.sort(
        (a, b) => a.orderIndex - b.orderIndex
      )
    };
  }

  /**
   * Función privada para la persistencia de kendo
   * @param descriptor 
   */
  private mapDateFilter = (descriptor: any) => {
    const filters = descriptor.filters || [];

    filters.forEach((filter) => {
      if (filter.filters) {
        this.mapDateFilter(filter);
      } else if (filter.field === "FirstOrderedOn" && filter.value) {
        filter.value = new Date(filter.value);
      }
    });
  };

  /**
   * Función para guardar la configuración del grid
   * @param grid 
   */
  public async saveGridSettings(grid: GridComponent, gridSettings: GridSettings): Promise<void> {
    const columns = grid.columns;
    this.gridSettings.state = this.state;

    //add only the required column properties to save local storage space
    const gridConfig = {
      identificador: this.gridSettings.identificador,
      accesoEnum: this.gridSettings.accesoEnum,
      state: this.gridSettings.state,
      columnsConfig: columns.toArray().map((item) => {

        const column = gridSettings.columnsConfig.filter(column => column.field === item["field"])[0];
        const cutText = column?.cutText || false;
        const notShowColumn = column?.notShowColumn || false;


        return <ColumnSettings>{
          field: item["field"],
          width: item["width"],
          _width: item["_width"],
          title: item["title"],
          filter: item["filter"],
          format: item["format"],
          filterable: item["filterable"],
          orderIndex: item["orderIndex"],
          hidden: item["hidden"],
          sticky: item["sticky"],
          sortable: item["sortable"],
          cutText,
          notShowColumn
        };
      }),
    };

    await this.statePersistingKendoService.set('gridSettings' + this.gridSettings.identificador, gridConfig);
    this._mensajesService.toastSuccess('Configuración guardada con éxito');
  }

  public async restoreGridSettings() {
    this.placeholderLoading = true;
    await this.statePersistingKendoService.remove('gridSettings' + this.gridSettings.identificador);
    this._mensajesService.toastSuccess('Configuración restaurada con éxito');
    setTimeout(() => {
      this.placeholderLoading = false;
    }, 300);
  }


  /**
     * Función que actualiza la tabla de kendo cada vez que detecta un cambio
     * @param {DataStateChangeEvent} state estado del evento de cambio de kendo grid.
     */
  public dataStateChange(state: DataStateChangeEvent): void {
    // Carga de datos para kendo Grid
    this.state = state;
    this.cargarKendoGrid();
  }

  /**
   * Función para buscar en la tabla de kendo
   * @param {string} valorBusqueda Valor a buscar.
   */
  searchKendo(filterValue: string) {
    this.filterValue = filterValue;
    this.stateSubject.next(filterValue);
  }
  /**
   * Obtiene la data para la exportación
   */
  public allData = (): Promise<ExcelExportData> => {
    return this._documentsMethodsService.allData(this.state, this.gridService, this.kendoView);
  };

  /**
   * Función para obtener el nombre del archivo excel y pdf
   */
  obtenerNombreArchivo(tipoDocumento): string {
    return this._documentsMethodsService.obtenerNombreArchivo(`${this.gridSettings.identificador}-`, tipoDocumento);
  }

}
