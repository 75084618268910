import { Empresa } from './empresa.model';
import { Ciudad } from '../ubicacion/ciudad.model';
import { Zona } from './zona.model';

export class PuntoServicio {
    id?: string;
    nombre?: string;
    telefono?: string;
    direccion?: string;
    latitud?: number;
    longitud?: number;
    mailContacto?: string;
    fueraPerimetroUrbano?: boolean;
    tiempoTransporte?: Date;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    ciudad?: Ciudad;
    empresa?: Empresa;
    zona?: Zona;
    codigoPostal?: string;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.telefono = null;
        this.direccion = null;
        this.latitud = null;
        this.longitud = null;
        this.mailContacto = null;
        this.fueraPerimetroUrbano = false;
        this.tiempoTransporte = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.ciudad = null;
        this.empresa = null;
        this.zona = null;
        this.codigoPostal = null;
    }
}
