import { MarcaVerificacion } from './marca-verificacion.model';
import { Servicio } from './servicio.model';
import { Usuario } from '../seguridad/usuario.model';
import { CondicionCierre } from './condicion-cierre.model';
import { PreguntaChecklist } from '../checklist/pregunta-checklist.model';
import { Checklist } from '../checklist/checklist.model';

export class ServicioMarcaVerificacion {
  id: string;
  detalle?: string;
  idTareaAsana?: string;
  gestionable?: boolean;
  solucionado?: boolean;
  cerrado?: boolean;
  fechaControl?: Date;
  fechaCreacion?: Date;
  fechaCierre?: Date;
  version?: number;
  marcaVerificacion?: MarcaVerificacion;
  servicio?: Servicio;
  usuario?: Usuario;
  condicionCierre?: CondicionCierre;

  titulo?: string;
  fechaEntrega?: Date;
  usuarioResponsable?: Usuario;
  pregunta?: PreguntaChecklist;
  checklistData?: Checklist;

  constructor() {
    this.id = null;
    this.detalle = null;
    this.idTareaAsana = null;
    this.gestionable = null;
    this.solucionado = null;
    this.cerrado = null;
    this.fechaControl = null;
    this.fechaCreacion = null;
    this.fechaCierre = null;
    this.version = null;
    this.marcaVerificacion = null;
    this.servicio = null;
    this.usuario = null;
    this.condicionCierre = null;
    this.titulo = null;
    this.fechaEntrega = null;
    this.usuarioResponsable = null;
    this.pregunta = null;
    this.checklistData = null
  }
}
