import { EquipoSistemaAtendido } from './equipo-sistema-atendido.model';
import { Elemento } from '../inventario/elemento.model';

export class RepuestoUtilizado {
  cantidad?: number;
  cantidadValidar?: number;
  observacion?: string;
  fechaCreacion?: Date;
  version?: number;
  equipoSistemaAtendido?: EquipoSistemaAtendido;
  elemento?: Elemento;

  constructor() {
    this.cantidad = null;
    this.cantidadValidar = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.version = null;
    this.equipoSistemaAtendido = null;
    this.elemento = null;
  }
}
