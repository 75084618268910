export class ServiciosFaseTrabajo {
    fechaInicio: Date;
    fechaFin: Date;
    idEmpresaContratanteParametro: string;
    idsEmpresaParametro: string;
    idsPuntoServicioParametro: string;
    idsProyectoParametro: string;
    idsFaseParametro: string;

    constructor() {
        this.fechaInicio = null;
        this.fechaFin = null;
        this.idEmpresaContratanteParametro = null;
        this.idsEmpresaParametro = '';
        this.idsPuntoServicioParametro = '';
        this.idsProyectoParametro = '';
        this.idsFaseParametro = '';
    }
}
