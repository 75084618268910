<app-placeholder-loading-form class="animated fadeIn fast" *ngIf="placeholderLoading"></app-placeholder-loading-form>
<form #form="ngForm" (submit)="save(form)" class="animated fadeIn fast" *ngIf="!placeholderLoading">
    <div class="card">
        <div class="card-header card-header-icon card-header-warning">
            <div class="card-icon" data-background-color="orange">
                <i class="material-icons">person</i>
            </div>
            <h4 class="card-title">Personalización de imagen
            </h4>
        </div>
        <div class="card-body">
            <mat-dialog-content>
                <div class="row">
                    <div class="col-md-6 col-sm-6">
                        <div class="row" class="titulo">
                            <span>IMAGEN ORIGINAL</span>
                        </div>
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="aspectRatio" [resizeToWidth]="resizeToWidth" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"
                            style="max-height: 33vh" [style.display]="cropperReady ? null : 'none'"></image-cropper>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="row" class="titulo">
                            <span>VISTA PREVIA</span>
                        </div>
                        <div class="text-center">
                            <img [src]="croppedImage.base64" />
                        </div>
                    </div>
                </div>

            </mat-dialog-content>
        </div>
        <div class="card-footer">
            <div class="col-lg-6 col-md-6 text-left">
                <div class="category form-category">
                    <span style="color: red">*</span> Campos requeridos
                </div>
            </div>
            <div class="col-lg-6 col-md-6 text-right">
                <button type="submit" class="btn btn-sm btn-primary">
                                <i class="material-icons">save</i>
                        </button>

                <button type="button" (click)="closeModal()" class="btn btn-sm btn-danger">
                                <i class="material-icons">close</i>
                        </button>
            </div>
        </div>
    </div>
</form>