// Angular
import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

// Dialogo Angular/Material
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Consentimiento, ProcesoRegistro } from 'src/app/models/models.index';

//Services
import { ConsentimientoService } from 'src/app/services/service.index';

@Component({
  selector: 'app-politica-aprobar',
  templateUrl: './politica-aprobar.component.html',
  styles: []
})
export class PoliticaAprobarComponent {
  // Inicialización de variables para el proceso
  enProceso = false;
  procesoRegistro: ProcesoRegistro;
  genericForm: UntypedFormGroup;
  idPolitica: string;
  urlPolitica: string;
  nombrePolitica: string;

  constructor(
    private _consentimientoService: ConsentimientoService,    
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PoliticaAprobarComponent>,
  ) {
    this.procesoRegistro = new ProcesoRegistro();
  }

  ngOnInit(): void {
    this.inicializarFomulario();
    this.idPolitica = this.data?.idPoliticaSeguridad;
    this.urlPolitica = this.data?.urlPoliticaSeguridad;
    this.nombrePolitica = this.data?.nombrePoliticaSeguridad;
  }

  inicializarFomulario() {
    this.genericForm = this._formBuilder.group({
      id: new UntypedFormControl(null),
      fechaAceptacion: new UntypedFormControl(null),
      usuario: new UntypedFormControl(null),
      politicaSeguridad: new UntypedFormControl(null),
      terminoCondicion: new UntypedFormControl(null),
      fechaCreacion: new UntypedFormControl(null),
      version: new UntypedFormControl(null),
      aceptacionPolitica: new UntypedFormControl(false)
    })
  };

  save() {
    if (
      this.genericForm.dirty &&
      this.genericForm.valid
    ) {
      this.procesoRegistro.enEjecucion();

      const consentimiento: Consentimiento = {
        id: this.genericForm.controls.id.value,
        fechaAceptacion: this.genericForm.controls.fechaAceptacion.value,
        usuario: this.genericForm.controls.usuario.value,
        politicaSeguridad: { id: this.idPolitica } || null,
        terminoCondicion: null,
        fechaCreacion: this.genericForm.controls.fechaCreacion.value,
        version: this.genericForm.controls.version.value
      };
      this._consentimientoService.save(consentimiento).subscribe({
        next: (response: any) => {
          if (response.success) {
            this.procesoRegistro.finalizado();
            this.dialogRef.close(true);
          }
          this.procesoRegistro.finalizado();
        }, error: (err) => {
          console.error(err);
          this.dialogRef.close(false);
        }
      });
    } else if (!this.genericForm.valid) {
      this.genericForm.markAllAsTouched();
    }
  }

  logout(){
    this.dialogRef.close(false);
  }
}
