<div class="card">
    <!-- <div class="col-md-12 animated fadeIn fast" *ngIf="placeholderLoading">
        <app-placeholder-loading-grid></app-placeholder-loading-grid>
    </div> -->
    <div class="card-header card-header-icon card-header-warning">
        <div class="card-icon" data-background-color="orange">
            <i class="material-icons">zoom_in</i>
        </div>
        <h4 class="card-title">Zoom</h4>
    </div>
    <div class="card-body">
        <mat-dialog-content>
            <div class="text-center">
                <pinch-zoom *ngIf="img?.url"
                            wheelZoomFactor="2"
                            autoHeight="true"
                            backgroundColor="rgba(155,155,155, 0.1)">
                    <img [src]="img?.url" />
                </pinch-zoom>

                <pinch-zoom *ngIf="!img?.url"
                            wheelZoomFactor="2"
                            autoHeight="true"
                            backgroundColor="rgba(155,155,155, 0.1)">
                    <img [src]="img?.amazonS3Parameters?.base64 | sanitizedHtml: 'url'" />
                </pinch-zoom>
                <span class="text-center" style="font-size: 12px; white-space: nowrap;">
                    <b>{{img?.nombre}}</b>
                </span>
            </div>
        </mat-dialog-content>
    </div>
    <div class="card-footer">
        <div></div>
        <div class="col-lg-6 col-md-6 text-right">
            <button type="button" (click)="closeModal()" class="btn btn-sm btn-danger">
                <i class="material-icons">close</i>
            </button>
        </div>
    </div>
</div>