import { PuntoServicio } from '../empresas/punto-servicio.model';
import { Persona } from './persona.model';

export class PersonaPuntoServicio {
  fechaCreacion?: Date;
  version?: number;
  puntoServicio?: PuntoServicio;
  persona?: Persona;

  constructor() {
    this.fechaCreacion = null;
    this.version = null;
    this.puntoServicio = new PuntoServicio();
    this.persona = new Persona();
  }
}
