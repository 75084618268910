import { EmpresaContratante } from '../empresa-contratante.model';
import { DetalleHorarioTrabajoEmpleado } from './detalle-horario-trabajo-empleado.model';

export class HorarioTrabajoEmpleado {
    id: string;
    nombre?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresaContratante?: EmpresaContratante;
    // Transient
    detalleHorarioTrabajoEmpleado? : DetalleHorarioTrabajoEmpleado[];
    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresaContratante = new EmpresaContratante();
        this.detalleHorarioTrabajoEmpleado = null;
    }
}