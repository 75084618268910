import { EmpresaContratante } from '../empresa-contratante.model';

export class TipoRecargo {
    id: string;
    nombre?: string;
    recargo?: number;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresaContratante?: EmpresaContratante;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.recargo = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresaContratante = new EmpresaContratante();
    }
}