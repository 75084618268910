// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';




// Configuracion
import { url_servicio } from '../../config/config';

// Modelos
import { Ciudad } from '../../models/models.index';

// Kendo
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { CrudBasicoService } from '../utilidades/crud-basico.service';


@Injectable()
export class CiudadService {

  private tableName = 'ciudad';

  /**
   * Contructor del Servicio Pais
   * @constructor
   * @param {CrudBasicoService} _crudBasico Servicio para los crud básicos.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   */
  constructor(
    private _crudBasico: CrudBasicoService,
    private _usuarioService: UsuarioService
  ) {
  }

   /**
  * Obtiene la ciudad por ID
  * @param {string} id - ID de la ciudad
  */
  getByID(id: number) {
    return this._crudBasico.getByID(this.tableName, id);
  }

  /**
  * Función para crear o editar una ciudad
  * @param {Ciudad} ciudad - Ciudad a crear o editar
  */
  save(ciudad: Ciudad) {
    return this._crudBasico.save(this.tableName, ciudad);
  }

  /**
  * Función para cambiar de estado a un registro
  * @param {Ciudad} ciudad - Entidad a cambiar de estado
  */
  cambiarEstado(ciudad: Ciudad) {
    return this._crudBasico.cambiarEstado(this.tableName, ciudad);
  }

  /**
  * Obtiene la data que se muestra en Kendo GRID
  * @param {State} state - Parametros generados por Kendo Grid
  */
  getKendo(state?: State): Observable<GridDataResult> {
    return this._crudBasico.kendo(this.tableName, state);
  }

  /**
  * Obtiene las provincias que se muentra en Kendo GRID Modal
  */
  getModal(state?: State): any {
    return this._crudBasico.modal(this.tableName, state);
  }
  /**
  * Obtiene la ultima accion realizada al registro
  */
  getLog(id): any {
    return this._crudBasico.log(this.tableName, id);
  }

}
