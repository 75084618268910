
//Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

//Models
import { ProcesoRegistro } from '../../../models/models.index';


@Component({
  selector: 'app-img-fullscreen-view',
  templateUrl: './img-fullscreen-view.component.html',
  styles: []
})
export class ImgFullscreenViewComponent implements OnInit {

  img: any;
  procesoRegistro: ProcesoRegistro;

  constructor(
    public dialogRef: MatDialogRef<ImgFullscreenViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.procesoRegistro = new ProcesoRegistro();
  }

  ngOnInit(): void {
    this.img = this.data.imagen;
  }

  closeModal() {
    this.dialogRef.close();
  }

}
