<app-placeholder-loading-wizard class="animated fadeIn fast" *ngIf="placeholderLoading">
</app-placeholder-loading-wizard>
<div class="animated fadeIn fast" [hidden]="placeholderLoading">
    <div class="card" data-color="orange">
        <form #form="ngForm" (submit)="save(form)">
            <div class="card-body">
                <mat-tab-group [selectedIndex]="selectedIndex" (selectedIndexChange)="selectedIndex = $event">
                    <mat-tab *ngIf="procesoRegistro.enCrear">
                        <ng-template mat-tab-label>
                            <mat-icon>location_city</mat-icon> <br> Empresa Suscriptora
                        </ng-template>
                        <mat-dialog-content>
                            <div [hidden]="placeholderLoading">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="titulo">
                                            <i class="material-icons">business</i>
                                            <span>IDENTIFICACIÓN</span>
                                        </div>
                                        <!-- === IDENTIFICADOR === -->
                                        <div class="col-12">
                                            <kendo-floatinglabel text="Identificador (RUC / RFC) *">
                                                <kendo-textbox type="text"
                                                               id="ruc"
                                                               name="ruc"
                                                               maxlength="13"
                                                               kendoTextBox
                                                               [(ngModel)]="empresaContratante.ruc"
                                                               [readonly]="procesoRegistro.enDetalle"
                                                               [disabled]="procesoRegistro.enEditar"
                                                               uppercase>
                                                </kendo-textbox>
                                            </kendo-floatinglabel>
                                            <kendo-formhint class="hintKendo">
                                                {{empresaContratante.ruc?.length || 0}} de 13
                                            </kendo-formhint>
                                        </div>

                                        <!-- === NOMBRE COMPLETO === -->
                                        <div class="col-12">
                                            <kendo-floatinglabel text="Nombre Completo *">
                                                <kendo-textbox id="nombreCompleto"
                                                               maxlength="256"
                                                               name="nombreCompleto"
                                                               kendoTextBox
                                                               required
                                                               [(ngModel)]="empresaContratante.nombreCompleto"
                                                               [readonly]="procesoRegistro.enDetalle"
                                                               [disabled]="procesoRegistro.enEditar"
                                                               uppercase>
                                                </kendo-textbox>
                                            </kendo-floatinglabel>
                                            <kendo-formhint class="hintKendo">
                                                {{empresaContratante.nombreCompleto?.length || 0}} de 256
                                            </kendo-formhint>
                                        </div>

                                        <!-- === SITIO WEB === -->
                                        <div class="col-12">
                                            <kendo-floatinglabel text="Sitio Web">
                                                <input type="text"
                                                       name="sitioWeb"
                                                       kendoTextBox
                                                       [(ngModel)]="empresaContratante.sitioWeb"
                                                       [readonly]="procesoRegistro.enDetalle"
                                                       [disabled]="procesoRegistro.enEditar"
                                                       maxlength="64" />
                                            </kendo-floatinglabel>
                                            <kendo-formhint class="hintKendo">
                                                {{ empresaContratante.sitioWeb?.length || 0 }} de 64
                                            </kendo-formhint>
                                        </div>
        
                                        <!-- === TELEFONO FIJO === -->
                                        <div class="col-12">
                                            <kendo-floatinglabel text="Télefono Fijo">
                                                <input type="text"
                                                       name="telefonoFijo"
                                                       kendoTextBox
                                                       [(ngModel)]="empresaContratante.telefonoFijo"
                                                       [readonly]="procesoRegistro.enDetalle"
                                                       [disabled]="procesoRegistro.enEditar"
                                                       maxlength="64" />
                                            </kendo-floatinglabel>
                                            <kendo-formhint class="hintKendo">
                                                {{ empresaContratante.telefonoFijo?.length || 0 }} de 64
                                            </kendo-formhint>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="titulo">
                                            <i class="material-icons">place</i>
                                            <span>UBICACIÓN DE LA EMPRESA</span>
                                        </div>
                                        <div class="col-12">
                                            <mat-form-field>
                                                <input [readonly]="procesoRegistro.enDetalle"
                                                    [disabled]="procesoRegistro.enEditar" matInput
                                                    placeholder="Buscar dirección" autocorrect="off"
                                                    autocapitalize="off" spellcheck="off" type="text" #search>
                                                    <mat-icon matSuffix>search</mat-icon>
                                            </mat-form-field>
                                        </div>

                                        <!-- === CIUDAD === -->
                                        <div class="col-md-12 margin-input">
                                            <kendo-floatinglabel text="Ciudad *">
                                                <kendo-combobox id="ciudad" name="ciudad" [(ngModel)]="ciudadTmp"
                                                    [readonly]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                                    [disabled]="procesoRegistro.enEditar"
                                                    [data]="ciudades.data" [textField]="'nombre'"
                                                    [valueField]="'id'" [suggest]="true" [filterable]="true"
                                                    (filterChange)="filterChangeCiudad($event)" [allowCustom]="true">
                                                    <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                        <div class="container">
                                                            <p style="float: right; font-size:9px">{{
                                                                dataItem.provincia.nombre }} - {{
                                                                dataItem.provincia.pais.nombre }}</p>
                                                            <span style="float: left;">{{ dataItem.nombre }}</span>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template kendoComboBoxFooterTemplate *ngIf="ciudades.total > 0">
                                                        <div class="footerCmb">
                                                            <a *ngIf="ciudades.data.length <  ciudades.total"
                                                                style="cursor: pointer" (click)="obtenerMasCiudades()">
                                                                Mostrar más... </a>
                                                            <br> {{ciudades.data.length}} de {{ciudades.total}}
                                                        </div>
                                                    </ng-template>
                                                    <ng-template kendoDropDownListNoDataTemplate>
                                                        <h4>Información no encontrada</h4>
                                                    </ng-template>
                                                </kendo-combobox>
                                            </kendo-floatinglabel>
                                            <kendo-formhint class="hintKendo" *ngIf="ciudadTmp?.provincia?.nombre">
                                                {{ ciudadTmp?.provincia?.nombre || ' ' }} - {{
                                                ciudadTmp?.provincia?.pais?.nombre || ' ' }}
                                            </kendo-formhint>
                                            <mat-hint class="mat-hint hintKendo" *ngIf="!ciudadTmp?.provincia?.nombre">
                                                Seleccione una Ciudad.</mat-hint>
                                        </div>

                                        <!-- === DATOS REFERENCIA -->
                                        <div class="col-12">
                                            <kendo-formfield>
                                                <kendo-floatinglabel text="Dirección">
                                                    <input id="datosReferencia"
                                                           maxlength="512"
                                                           kendoTextBox
                                                           name="datosReferencia"
                                                           [(ngModel)]="empresaContratante.datosReferencia"
                                                           [readonly]="procesoRegistro.enDetalle"
                                                           [disabled]="procesoRegistro.enEditar"
                                                           uppercase />
                                                </kendo-floatinglabel>
                                                <kendo-formhint class="mat-hint hintKendo">
                                                    {{empresaContratante.datosReferencia?.length || 0}} de 512
                                                </kendo-formhint>
                                            </kendo-formfield>
                                        </div>

                                        <!-- GOOGLE MAP -->
                                        <div class="col-12">
                                            <agm-map [latitude]="empresaContratante.latitud"
                                                [disableDefaultUI]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                                [longitude]="empresaContratante.longitud" [zoom]="zoom"
                                                [disableDefaultUI]="false" [zoomControl]="false"
                                                (mapClick)="mapClicked($event)">
                                                <agm-marker [latitude]="empresaContratante.latitud"
                                                    [longitude]="empresaContratante.longitud"
                                                    [markerDraggable]="draggable" (dragEnd)=" dragEnd($event)">
                                                </agm-marker>
                                            </agm-map>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-dialog-content>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <mat-icon>style</mat-icon> <br> Personalización
                        </ng-template>
                        <mat-dialog-content>
                            <div *ngIf="!placeholderLoading">
                                <div class="row">
                                    <div class="col-6 text-center">
                                        <div class="row" class="titulo">
                                            <i class="material-icons">photo</i>
                                            <span>LOGO PRINCIPAL</span>
                                        </div>
                                        <!-- =============== -->
                                        <!-- Logo Principal  -->
                                        <!-- =============== -->
                                        <div class="thumbnail img-circle">
                                            <img style="height: 50px !important; max-width: 250px !important ;" [src]="empresaContratante.logoPrincipal">
                                        </div>
                                        <input id="inputFile1" type="file" (change)="cargarPersonalizarImagen($event)"
                                            hidden="true" required />
                                        <div class="text-right">
                                            <small>Tamaño máximo (250 X 50)px</small>
                                        </div>
                                        <button type="button"
                                            [disabled]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                            (click)="cargarInputFile(true)" class="btn btn-round mat-raised-button">
                                            <span class="mat-button-wrapper">
                                                <i class="material-icons">add_photo_alternate</i>
                                            </span>
                                        </button>
                                        <button type="button"
                                            [disabled]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                            (click)="cancelarFotografia(true)" class="btn btn-round mat-raised-button"
                                            mat-raised-button="">
                                            <span class="mat-button-wrapper">
                                                <i class="material-icons">close</i>
                                            </span>
                                        </button>
                                        <!-- ==================== -->
                                        <!-- FIN - Logo Principal -->
                                        <!-- ==================== -->
                                    </div>
                                    <div class="col-6 text-center">
                                        <div class="row" class="titulo">
                                            <i class="material-icons">photo</i>
                                            <span>LOGO SECUNDARIO</span>
                                        </div>
                                        <!-- =============== -->
                                        <!-- Logo Secundario  -->
                                        <!-- =============== -->
                                        <div class="thumbnail img-circle">
                                            <img style="height: 50px !important; max-width: 250px !important ;" [src]="empresaContratante.logoSecundario">
                                        </div>
                                        <input id="inputFile2" type="file" (change)="cargarPersonalizarImagen($event)"
                                            hidden="true" required />
                                        <div class="text-right">
                                            <small>Tamaño máximo (50 X 50)px</small>
                                        </div>
                                        <button type="button"
                                            [disabled]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                            (click)="cargarInputFile(false)" class="btn btn-round mat-raised-button">
                                            <span class="mat-button-wrapper">
                                                <i class="material-icons">add_photo_alternate</i>
                                            </span>
                                        </button>
                                        <button type="button"
                                            [disabled]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                            (click)="cancelarFotografia(false)" class="btn btn-round mat-raised-button"
                                            mat-raised-button="">
                                            <span class="mat-button-wrapper">
                                                <i class="material-icons">close</i>
                                            </span>
                                        </button>
                                        <!-- ====================== -->
                                        <!-- FIN - Logo Secundario  -->
                                        <!-- ====================== -->
                                    </div>
                                </div>
                                <div class="row" class="titulo">
                                    <i class="material-icons">short_text</i>
                                    <span>INFORMACIÓN PDF</span>
                                </div>
                                <div class="row">
                                    <!-- ============================ -->
                                    <!--  Mail Recibe notificaciones   -->
                                    <!-- ============================ -->
                                    <div class="col-12" *ngIf="procesoRegistro.enCrear">
                                        <mat-form-field>
                                            <input [readonly]="procesoRegistro.enDetalle"
                                                [disabled]="procesoRegistro.enProceso" type="email"
                                                name="mailRecibeNotificaciones"
                                                placeholder="Mail de Recepción de Notificaciones" required matInput
                                                [(ngModel)]="empresaContratante.mailRecibeNotificaciones">
                                            <mat-hint align="end">{{empresaContratante.mailRecibeNotificaciones?.length
                                                || 0}} de 128</mat-hint>
                                            <mat-error
                                                *ngIf="_validarFormularioService.isFieldValid(form, 'mailRecibeNotificaciones')">
                                                El campo
                                                <strong>'Mail de Recepción de Notificaciones'</strong> es requerido
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- ================================== -->
                                    <!--  FIN - Mail Recibe notificaciones  -->
                                    <!-- ================================== -->
                                    <div class="col-12">
                                        <div  class="row" >
                                            <div class="col-md-6">
                                                <b style="font-size: 12px; font-weight: bold;">Encabezado PDF:</b><br>
                                                <mat-form-field>
                                                    <textarea cdkTextareaAutosize cdkAutosizeMinRows="2"
                                                        [readonly]="procesoRegistro.enDetalle"
                                                        [disabled]="procesoRegistro.enProceso" name="headerPdf"
                                                        placeholder="Información Cabecera PDF" maxlength="256"
                                                        matInput matTextareaAutosize
                                                        [(ngModel)]="empresaContratante.headerPdf">
                                                    </textarea>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <b style="font-size: 12px; font-weight: bold;">Pie de Página PDF:</b><br>
                                                <mat-form-field>
                                                    <textarea cdkTextareaAutosize cdkAutosizeMinRows="2"
                                                        [readonly]="procesoRegistro.enDetalle"
                                                        [disabled]="procesoRegistro.enProceso" name="informacionPdf"
                                                        placeholder="Información Pie de Página PDF" maxlength="100"
                                                        matInput matTextareaAutosize
                                                        [(ngModel)]="empresaContratante.informacionPdf">
                                                    </textarea>
                                                </mat-form-field>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="text-right nota-pdf">
                                                            <strong class="help">Nota:</strong> Tome en cuenta que el <b>Pie de Página</b> tiene un limite máximo de 100 caracteres. 
                                                            <br>Caracteres usados: {{empresaContratante.informacionPdf.length || 0}} de 100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 d-flex justify-content-center">
                                        <button type="button"
                                            (click)="previsualizarPDF()"
                                            class="btn btn-sm btn-primary"
                                            [readonly]="procesoRegistro.enDetalle"
                                            [disabled]="procesoRegistro.enProceso">
                                            Previsualizar PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </mat-dialog-content>
                    </mat-tab>
                    <mat-tab *ngIf="procesoRegistro.enCrear">
                        <ng-template mat-tab-label>
                            <mat-icon>attach_file</mat-icon> <br> Términos y Condiciones
                        </ng-template>
                        <mat-dialog-content>
                            <div *ngIf="!placeholderLoading">
                                <div class="row" class="titulo" *ngIf="empresaContratante.id">
                                    <i class="material-icons">person</i>
                                    <span>ARCHIVOS ADJUNTOS</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-10 col-md-11 col-12 ml-auto mr-auto" *ngIf="archivosAdjuntos">
                                        <div class="table-responsive" *ngIf="archivosAdjuntos.length > 0;">
                                            <table class="table table-striped" style="min-width: 350;">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Archivo</th>
                                                        <th width="50px"
                                                            *ngIf="!procesoRegistro.enDetalle && !procesoRegistro.enProceso">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let archivoAdjunto of archivosAdjuntos; let i = index;">
                                                        <td [style.color]="archivoAdjunto.enError ? 'red' : '#333333'"
                                                            *ngIf="archivoAdjunto.url">
                                                            <a target="_blank" [href]="archivoAdjunto.url"
                                                                [ngClass]="">{{archivoAdjunto.nombre}}</a>
                                                        </td>
                                                        <td [style.color]="archivoAdjunto.enError ? 'red' : '#333333'"
                                                            *ngIf="!archivoAdjunto.url">
                                                            {{archivoAdjunto.nombre}}
                                                        </td>
                                                        <td align="center"
                                                            *ngIf="!procesoRegistro.enDetalle && !procesoRegistro.enProceso">
                                                            <button type="button" [disabled]="archivoAdjunto.enProceso"
                                                                mat-icon-button [matMenuTriggerFor]="menu">
                                                                <span class="mat-button-wrapper"
                                                                    matTooltip="Más opciones"
                                                                    matTooltipPosition="above">
                                                                    <mat-icon>more_vert</mat-icon>
                                                                </span>
                                                            </button>
                                                            <mat-menu style="font-size: 10px" #menu="matMenu">
                                                                <a *ngIf="archivoAdjunto.url" mat-menu-item
                                                                    target="_blank" [href]="archivoAdjunto.url">
                                                                    <span><i class="material-icons">
                                                                            cloud_download
                                                                        </i> Descargar</span>
                                                                </a>
                                                            </mat-menu>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="archivosAdjuntos.length === 0">
                                            No existe ningún archivo
                                        </div>
                                    </div>
                                </div>
                                <div class="row" class="titulo" *ngIf="empresaContratante.id">
                                    <i class="material-icons">person</i>
                                    <span>TÉRMINOS Y CONDICIONES</span>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div style="height: 200px; overflow: auto;">
                                            <div class="ql-align-center"><strong>decisioncloud Gestión de Servicios -
                                                    Términos y políticas de privacidad</strong></div>
                                            &nbsp;
                                            <div><strong>Fecha de vigencia:</strong> Enero 2021</div>
                                            &nbsp;
                                            <div><strong>decisioncloud - Gestión de Servicios</strong> (dcGS) es una
                                                solución de software que permite evaluar los niveles de calidad en la
                                                administración, gestión y control eficiente de los servicios técnicos,
                                                tanto al que provee como al que recibe los servicios. A través de la
                                                variedad de consultas y reportes le permite a los supervisores y
                                                gerentes, tomar decisiones, planificar, gestionar, llevar control y
                                                realizar análisis basándose en información analítica que se va
                                                almacenando en una base de datos central</div>
                                            &nbsp;
                                            <div>DECISIÓN c.a, es titular exclusivo de los derechos de propiedad
                                                intelectual del software denominado decisioncloud - Gestión de Servicios
                                                (dcGS) en todas sus versiones, de igual forma es titular del dominio en
                                                internet denominado“<a href="https://www.mydecisioncloud.com/"
                                                    target="_blank"
                                                    rel="noopener noreferrer">https://www.mydecisioncloud.com</a>”</div>
                                            &nbsp;
                                            <div>decisionCloud tiene dos diferentes tipos de usuarios:</div>
                                            &nbsp;
                                            <div><strong>Usuarios Suscriptores (Suscriptor):</strong> son los usuarios
                                                que usan el Servicio como parte de un plan de Suscripción de Pago, los
                                                detalles de los servicios a los que acceden están determinados en las
                                                ofertas aprobadas con cada Suscriptor</div>
                                            &nbsp;
                                            <div>El Suscriptor accede al servicio<strong> dcGS,</strong> siempre que
                                                cuente con la disponibilidad del servicio de internet. Lo hará, a través
                                                de cuentas privadas, donde podrá registrarse. Al hacer uso del
                                                <strong>dcGS</strong>, contará con almacenamiento y podrá administrar la
                                                información existente en el <strong>dcGS. </strong>Solamente se le
                                                permitirá el acceso, durante la vigencia del acuerdo de servicio o
                                                contrato
                                            </div>
                                            &nbsp;
                                            <div><strong>Usuarios Generadores de la Información y o
                                                    Consultores</strong>: son aquellos usuarios a los cuales los
                                                Suscriptores permiten el acceso, pudiendo ser éstos funcionarios de su
                                                institución autorizados u otros. Cuando los usuarios generadores de la
                                                información o consultores acceden, el Suscriptor asume los derechos y
                                                obligaciones mencionados en el acuerdo de servicio del Suscriptor</div>
                                            &nbsp;
                                            <div><strong>RESPONSABILIDADES DE DECISIÓN c.a.</strong></div>
                                            <ol>
                                                <li>DECISIÓN c.a. es responsable de mantener el dominio “<a
                                                        href="https://www.mydecisioncloud.com/" target="_blank"
                                                        rel="noopener noreferrer">https://www.mydecisioncloud.com</a>” a
                                                    través del cual se accede vía internet al dcGS</li>
                                                <li>DECISIÓN c.a es responsable de alojar en plataformas seguras el dcGS
                                                </li>
                                                <li>DECISIÓN c.a. es responsable de permitir el acceso al uso del
                                                    servicio de dcGS bajo los lineamientos estipulados en la oferta
                                                    aprobada con cada Suscriptor</li>
                                                <li>Una vez finalizada la relación comercial, El Suscriptor podrá
                                                    solicitar a DECISIÓN c.a de manera expresa la eliminación o
                                                    almacenamiento de su información en el dcGS. Caso contrario DECISIÓN
                                                    mantendrá la información almacenada por seis meses y procederá con
                                                    la eliminación de la misma</li>
                                            </ol>
                                            &nbsp;
                                            <div><strong>RESPONSABILIDADES DEL SUSCRIPTOR</strong></div>
                                            <ol>
                                                <li>Colaborar con DECISIÓN para que se den todas la condiciones
                                                    necesarias para el uso del dcGS</li>
                                                <li>Pagar los valores estipulados en la oferta aprobada del Suscriptor
                                                </li>
                                                <li>El Suscriptor es el único responsable de designar y controlar
                                                    usuarios autorizados para el uso del dcGS que tendrán acceso al
                                                    servicio; haciéndose responsable de cualquier uso no adecuado de
                                                    usuarios, claves, accesos y/o consultas en el dcGS, El Suscriptor
                                                    será el único responsable del manejo de claves individuales, así
                                                    como de las seguridades dispuestas para el uso y resguardo de dichas
                                                    claves, para este efecto debe comunicar de manera formal los datos
                                                    de solicitud de los usuarios autorizados del dcGS que designe</li>
                                                <li>El suscriptor debe estructurar e ingresar la información de
                                                    clientes, puntos de servicios, usuarios, personal que brinda el
                                                    servicio, insumos y otras requeridas para la operación del dcGS</li>
                                                <li>Supervisar la calidad y la veracidad de la información que los
                                                    usuarios ingresen en el dcGS</li>
                                                <li>Asignar los equipos con las características mínimas necesarias, que
                                                    se utilizarán en el proyecto, con acceso a internet</li>
                                                <li>Disponer de acceso a internet con un mínimo de velocidad de 4Mbps,
                                                    de tal manera que permita sacar el mayor beneficio en el uso del
                                                    dcGS. Las interrupciones en la provisión del servicio de acceso al
                                                    internet serán exclusivamente imputables al proveedor de ese
                                                    servicio que haya contratado El Suscriptor</li>
                                                <li>Dar información inmediata a DECISIÓN sobre posibles anomalías
                                                    detectadas en el uso del dcGS</li>
                                                <li>El Suscriptor declara que la información registrada en el sistema
                                                    decisioncloud-Gestión de Servicios dcGS, proviene de negocios de
                                                    origen lícito, por lo que DECISION no tiene ninguna responsabilidad
                                                    de la información que se registra en el dcGS, ni del giro del
                                                    negocio o servicios que comercializa</li>
                                            </ol>
                                            &nbsp;
                                            <div><strong>RECOPILACIÓN Y USO DE LA INFORMACIÓN DEL SUSCRIPTOR</strong>
                                            </div>
                                            &nbsp;
                                            <div>Para acceder al dcGS es necesario que el Suscriptor proporcione los
                                                datos de inicio de sesión, los cuales son necesarios para el acceso al
                                                servicio, el Suscriptor puede en lo posterior ingresar de forma opcional
                                                la fotografía</div>
                                            &nbsp;
                                            <div><strong>Información recopilada en base a integración de
                                                    terceros</strong></div>
                                            &nbsp;
                                            <div>Al usar las integraciones de terceros como: Google Drive, si el
                                                Suscriptor habilita el uso de estas conexiones, nos autoriza a conectar
                                                y acceder a la información provista a través de esas conexiones y en
                                                consecuencia a aceptar las políticas de privacidad de esos terceros
                                            </div>
                                            &nbsp;
                                            <div><strong>¿ CÓMO PROTEGEMOS TUS DATOS ?</strong></div>
                                            &nbsp;
                                            <div>Los datos ingresados por el Suscriptor y usuarios generadores de la
                                                información al dcGS, serán de exclusiva propiedad de el Suscriptor,
                                                comprometiéndose DECISIÓN c.a. a no utilizar dicha base de datos para el
                                                uso o venta de ningún producto a cualquier otra entidad</div>
                                            &nbsp;
                                            <div>Por lo expuesto, DECISIÓN c.a. se halla prohibida de hacer uso de la
                                                INFORMACIÓN CONFIDENCIAL en asuntos que no sean los relativos a la
                                                oferta aprobada y / o acuerdo de Servicio con el SUSCRIPTOR ; Así como
                                                de divulgar dicha información por ninguna situación a terceras personas
                                                naturales o jurídicas, sino cuenta previamente con autorización por
                                                escrito de la otra parte, dicha obligación no se extinguirá aun cuando
                                                el servicio fuere descontinuado, suspendido o cancelado y el acuerdo de
                                                servicio ya no se encuentre en vigencia.</div>
                                            &nbsp;
                                            <div>En particular, las partes DECISIÓN c.a. y el Suscriptor se obligan a no
                                                revelar a terceras personas la Información Confidencial que reciba de, o
                                                conozca en relación con el dcGS o toda la información antes mencionada.
                                                En consecuencia, se obligan a mantenerla de manera confidencial y
                                                privada y a protegerla para evitar su uso o divulgación, ejerciendo el
                                                mismo grado de control que utilizará para proteger la información
                                                confidencial de su propiedad y a usarla exclusivamente para los
                                                propósitos a que se refiere la oferta aprobada de suscripción a la
                                                solución decisioncloud</div>
                                            &nbsp;
                                            <div>Se entenderá por información confidencial (la “Información
                                                Confidencial”), sea que se trate de DECISIÓN c.a., o de El Suscriptor,
                                                toda aquella información que la Parte conozca o reciba relacionada con
                                                su organización interna, con su actividad, con sus prestaciones
                                                mercantiles, o con sus relaciones y negocios con terceros y con el dcGS.
                                            </div>
                                            &nbsp;
                                            <div><strong>Vigencia de la suscripción</strong></div>
                                            &nbsp;
                                            <div>La vigencia de la suscripción, se especifica en la oferta aprobada, el
                                                mismo podrá ser renovado automáticamente con un ajuste en los precios
                                                acordado entre las partes</div>
                                            &nbsp;
                                            <div>Si tiene alguna inquietud, sobre éstos términos y políticas de
                                                privacidad, comuníquese con DECISIÓN c.a. a:
                                                contactenos@decision.com.ec, más información en www.decision.com.ec
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-dialog-content>
                    </mat-tab>
                </mat-tab-group>
            </div>
            <div class="card-footer">
                <div class="col-lg-6 col-md-6 text-left">
                    <div class="category form-category">
                        <span style="color: red">*</span> Campos requeridos
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 text-right">
                    <!-- <button *ngIf="selectedIndex !== 0" type="button" (click)="previousStep()"
                        class="btn btn-sm btn-primary">
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <button *ngIf="selectedIndex !== 1" type="button" (click)="nextStep()"
                        class="btn btn-sm btn-primary">
                        <mat-icon>arrow_forward</mat-icon>
                </button>-->
                    <button *ngIf="selectedIndex !== 2" [disabled]="procesoRegistro.enProceso" type="submit"
                        class="btn btn-sm btn-primary">
                        <i class="material-icons" *ngIf="!procesoRegistro.enProceso">save</i>
                        <i class="material-icons fa-spin" *ngIf="procesoRegistro.enProceso">sync</i>
                    </button>
                    <button type="button" (click)="closeModal()" [disabled]="procesoRegistro.enProceso"
                        class="btn btn-sm btn-danger">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>