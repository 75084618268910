import { Provincia } from './provincia.model';

export class Ciudad {
    id: string;
    nombre?: string;
    siglas?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    provincia?: Provincia;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.siglas = null;
        this.fechaCreacion = null;
        this.observacion = null;
        this.estado = null;
        this.version = null;
        this.provincia = new Provincia();
    }
}
