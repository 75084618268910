// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Servicios
import { UsuarioService } from './seguridad/usuario.service';
import { MensajesService } from './utilidades/mensajes.service';
import { KendoService } from './utilidades/kendo/kendo.service';

// Configuracion
import { url_servicio } from './../config/config';

// rxjs
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// Servicio Abstracto
import { AbtractCrudBasicoService } from './utilidades/abstract-crud-basico.service';

// Kendo
import { State } from '@progress/kendo-data-query';
import { KendoUtilsService } from './utilidades/kendo/kendo-utils.service';

@Injectable()
export class EmpresaContratanteService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService,
    public _kendoUtilsService: KendoUtilsService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'empresaContratante', 'empresa_contratante');
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  /**
   * Función para crear o editar una entidad
   * @param {any} Entidad - Entidad a crear o editar
   * @param {any} notificar - Propiedad para notificar la transacción (por defecto = true)
   */
  aceptoTerminosCondiciones(entidad: any, notificar: boolean = true, idTerminosCondiciones?: string) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/empresacontratante/terminoscondiciones/${idTerminosCondiciones}`;
    if (entidad.id) {
      // Petición put para editar la entidad
      return this.http.put(url, entidad, { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            if (notificar) {
              this._mensajeService.exito(response.mensaje);
            }
          } else {
            this._mensajeService.error(response.error);
          }
          return response;
        }),
        catchError(err => {
          this._mensajeService.errorCritico();
          return throwError(() => new Error(err));
        })
      );
    }
  }

  getDistribuidoresAtyde(state: State) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });
    // Nueva instancia de la variable state
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);
    // URL
    let url = `${url_servicio}/empresacontratante/atyde/distribuidores/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return throwError(() => new Error(err));
      })
    );
  }
}
