// Angular
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewRef } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

// Modelos
import {
  ProcesoRegistro,
  Elemento,
} from '../../models/models.index';

// Servicios
import {
  MensajesService,
  ArchivoAdjuntoGenericoService,
} from '../../services/service.index';

// ng2 Upload
import { FileUploader } from 'ng2-file-upload';

//enums
import { MimeTypeImg } from '../../config/mime-type.enum';
import { SizeModal } from 'src/app/config/size-modal.enum';

//Componentes
import { ImgFullscreenViewComponent } from './img-fullscreen-view/img-fullscreen-view.component';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styles: [`
  
  .btn-eliminar-carousel{
      text-align: right;
      position: absolute;
      z-index: 30;
      transition: opacity .2s;
      top: 0px;
      right: 8px;
      border-radius: 13px;      
      font-size: 11px;
      color: #f44336;
      padding: 5px 7px;
      line-height: normal;
  }

  .btn-zoom-carousel{
      text-align: left;
      position: absolute;
      z-index: 30;
      transition: opacity .2s;
      top: 0px;
      right: 36px;
      border-radius: 13px;      
      font-size: 11px;
      color: #f44336;
      padding: 5px 7px;
      line-height: normal;
  }

  :host ::ng-deep .carousel-dots {
    position: absolute !important;
    left: calc(50% - 150px) !important;
    bottom: 0 !important;
    z-index: 10 !important;
    text-align: center !important;
    background: rgb(210 210 210 / 80%) !important;
    border-radius: 25px !important;
    width: 300px !important;
    height: 18px !important;
  }

  :host ::ng-deep .carousel-dot-active {
      background-color: #000 !important;
  } 

  .spinner-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } 

  .spinner-center mat-spinner {
    margin-left: 40px; /* Ajusta este valor para mover el spinner más a la derecha */
  }

  `]
})
export class CarouselComponent implements OnInit {

  // Declaracion de variables para la inicialización del proceso
  @Input()
  form: NgForm;
  @Input()
  formReactive: UntypedFormGroup;
  @Input()
  elemento: Elemento;
  @Input()
  procesoRegistro: ProcesoRegistro;
  @Input()
  visualizarImg: boolean;
  @Input()
  archivosAdjuntosInput = [];
  @Output()
  archivosAdjuntosOutput = new EventEmitter();
  placeholderLoading: boolean;
  identificador = new Date().getMilliseconds().toString();

  // Variables para el manejo de archivos adjuntos
  archivosAdjuntos2: any[];
  archivosGuardados: any[];
  archivosErrorTamano: string;
  archivosErrorUpload: string;
  archivosErrorDuplicado: string;
  archivosErrorMimeType: string;
  uploader: FileUploader;
  maxFileSize = 20 * 1024 * 1024; // 20 MB

  logArchivoAdjunto: any[];
  historialArchivoAdjunto: boolean;

  arrows: boolean;

  /**
   * Contructor del componente
   * @constructor
   * @param {ArchivoAdjuntoServicioService} _archivoAdjuntosService: Servicio de archivos adjuntos
   * @param {MensajesService} _mensajesService: Servicio de mensajeria
   * @param {GDriveService} _gdriveService: Servicio de Gdrive
   */

  constructor(
    private _archivoAdjuntosService: ArchivoAdjuntoGenericoService,
    private _mensajesService: MensajesService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private overlay: Overlay,
  ) {
    // Inicializa la variable para el componente FileUploader
    this.uploader = new FileUploader({ url: 'urlUpload' });
    this.archivosAdjuntos2 = [];
  }

  ngOnInit() {
    this.placeholderLoading = true;
    // Carga los archivos adjuntos
    if (this.elemento.id) {
      const promises = [];
      promises.push(this.cargarArchivosAdjuntos(true));
      Promise.all(promises).then(() => {
        this.placeholderLoading = false;
      });
    } else {
      if (this.archivosAdjuntosInput) {
        this.archivosAdjuntos2 = this.archivosAdjuntosInput;
      }
      this.placeholderLoading = false;
    }
  }


  // #region ARCHIVOS ADJUNTOS

  /**
   * Función para cargar los archivos adjuntos del servicio
   */
  cargarArchivosAdjuntos(visualizarImg): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.visualizarImg) {
        this._archivoAdjuntosService
          .getArchivosAdjuntosPorElemento(this.elemento.id, visualizarImg)
          .subscribe((archivosAdjuntos) => {
            this.archivosAdjuntos2 = archivosAdjuntos;
            this.archivosAdjuntosOutput.emit(this.archivosAdjuntos2);
            resolve('');
          });
      }

    });
  }

  /**
   * Función para cargar el log de los archivos adjuntos
   */
  cargarLogArchivosAdjuntos(): Promise<string> {
    this.historialArchivoAdjunto = true;
    this.logArchivoAdjunto = [];
    return new Promise((resolve, reject) => {
      this._archivoAdjuntosService
        .getAllLog(this.elemento.id)
        .subscribe((logs: any[]) => {
          logs.forEach((log) => {
            this.logArchivoAdjunto.push({
              accion: log.accion,
              fecha: new Date(log.fecha),
              nombreUsuario: log.nombreUsuario,
              registroAntes: log.registroAntes
                ? JSON.parse(log.registroAntes)
                : null,
              registroDespues: log.registroDespues
                ? JSON.parse(log.registroDespues)
                : null,
            });
          });
          resolve('');
        });
    });
  }

  /**
   * Evento DRAG & DROP y Archivos desde el dispositivo
   */
  // onFileSelected(files: any[]) {
  //   // Setea a la forma a dirty
  //   if (this.form) {
  //     this.form.control.markAsDirty();
  //   }

  //   // Inicializa las variables para el proceso
  //   this.archivosGuardados = [];
  //   this.archivosErrorDuplicado = '';
  //   this.archivosErrorTamano = '';
  //   this.archivosErrorUpload = '';
  //   this.archivosErrorMimeType = '';
  //   const promises = [];
  //   let index = 0;
  //   // Recorre los archivos a guardar
  //   for (const file of files) {
  //     // Valida las exteniones permitidas
  //     if (this._archivoAdjuntosService.validateMimeType(file.type, MimeTypeImg)) {
  //       // Valida los archivos que no superen el tamaño máximo
  //       if (file.size <= this.maxFileSize) {
  //         // Valida los archivos duplicados
  //         if (
  //           this.archivosAdjuntos2.filter(
  //             (archivoTmp) => archivoTmp.nombre === file.name
  //           ).length === 0
  //         ) {
  //           const archivoAdjuntoTmp = {
  //             nombre: file.name,
  //             visualizarCarousel: true,
  //             elemento:
  //               this.elemento && this.elemento.id
  //                 ? { id: this.elemento.id }
  //                 : null,
  //             enProceso: true,
  //           };
  //           index = index + 1;
  //           promises.push(this.saveArchivoAdjunto(file, archivoAdjuntoTmp, index));
  //         } else {
  //           // Almacena los nombre de los archivos duplicados
  //           this.archivosErrorDuplicado +=
  //             '<li>' + file.name + ' <b>(ya existe)</b></li>';
  //         }
  //       } else {
  //         // Almacena los nombre de los archivos que superaron el tamaño máximo
  //         this.archivosErrorTamano +=
  //           '<li>' +
  //           file.name +
  //           ' <b>(sobrepasa el tamaño máximo permitido)</b></li>';
  //       }
  //     } else {
  //       // Almacena los nombre de los archivos que superaron el tamaño máximo
  //       this.archivosErrorMimeType +=
  //         '<li>' + file.name + ' <b>(extensión no permitida)</b></li>';
  //     }
  //   }

  //   // Después de guardar los archivos muestra los mensajes de inconvenientes si existieran
  //   Promise.all(promises).then(() => {
  //     if (
  //       this.archivosErrorDuplicado !== '' ||
  //       this.archivosErrorTamano !== '' ||
  //       this.archivosErrorUpload !== '' ||
  //       this.archivosErrorMimeType !== ''
  //     ) {
  //       let mensaje =
  //         'No se subieron los siguientes archivos por las siguientes causas: <br><ul>';
  //       if (this.archivosErrorTamano !== '') {
  //         mensaje += this.archivosErrorTamano;
  //       }
  //       if (this.archivosErrorDuplicado !== '') {
  //         mensaje += this.archivosErrorDuplicado;
  //       }
  //       if (this.archivosErrorUpload !== '') {
  //         mensaje += this.archivosErrorUpload;
  //       }
  //       if (this.archivosErrorMimeType !== '') {
  //         mensaje += this.archivosErrorMimeType;
  //       }
  //       mensaje += '</ul>';
  //       this._mensajesService.advertenciaPersonalizada(
  //         '<div class="text-left">' + mensaje + '</div>',
  //         'Verifica los archivos'
  //       );
  //     }

  //     this.placeholderLoading = true;
  //     setTimeout(() => {
  //       this.placeholderLoading = false;
  //     }, 100);

  //   });
  // }

  onFileSelected(files: any[]) {
    // Setea a la forma a dirty
    if (this.form) {
      this.form.control.markAsDirty();
    }

    this.placeholderLoading = true;

    // Inicializa las variables para el proceso
    this.archivosGuardados = [];
    this.archivosErrorDuplicado = '';
    this.archivosErrorTamano = '';
    this.archivosErrorUpload = '';
    this.archivosErrorMimeType = '';
    const promises = [];
    let index = 0;
    let archivosSubidos = 0;
    let archivosConError = 0;

    // Recorre los archivos a guardar
    for (const file of files) {
      // Valida las extensiones permitidas
      if (this._archivoAdjuntosService.validateMimeType(file.type, MimeTypeImg)) {
        // Valida los archivos que no superen el tamaño máximo
        if (file.size <= this.maxFileSize) {
          // Valida los archivos duplicados
          if (
            this.archivosAdjuntos2.filter(
              (archivoTmp) => archivoTmp.nombre === file.name
            ).length === 0
          ) {
            const archivoAdjuntoTmp = {
              nombre: file.name,
              visualizarCarousel: true,
              elemento:
                this.elemento && this.elemento.id ? { id: this.elemento.id } : null,
              enProceso: true,
            };
            index = index + 1;
            promises.push(
              this.saveArchivoAdjunto(file, archivoAdjuntoTmp, index)
                .then(() => {
                  archivosSubidos++;
                })
                .catch(() => {
                  this.archivosErrorUpload +=
                    '<li>' + file.name + ' <b>(No se pudo subir)</b></li>';
                  archivosConError++;
                })
            );
          } else {
            // Almacena los nombres de los archivos duplicados
            this.archivosErrorDuplicado +=
              '<li>' + file.name + ' <b>(ya existe)</b></li>';
          }
        } else {
          // Almacena los nombres de los archivos que superaron el tamaño máximo
          this.archivosErrorTamano +=
            '<li>' +
            file.name +
            ' <b>(sobrepasa el tamaño máximo permitido)</b></li>';
        }
      } else {
        // Almacena los nombres de los archivos con extensión no permitida
        this.archivosErrorMimeType +=
          '<li>' + file.name + ' <b>(extensión no permitida)</b></li>';
      }
    }

    Promise.all(promises).then(() => {
      let mensaje = '';

      if (
        this.archivosErrorDuplicado !== '' ||
        this.archivosErrorTamano !== '' ||
        this.archivosErrorMimeType !== '' ||
        this.archivosErrorUpload !== ''
      ) {
        mensaje +=
          '<span style="font-weight: bold;">No se subieron los siguientes archivos por las siguientes causas:</span> <br><br><ul>';
        if (this.archivosErrorTamano !== '') {
          mensaje += this.archivosErrorTamano;
        }
        if (this.archivosErrorDuplicado !== '') {
          mensaje += this.archivosErrorDuplicado;
        }
        if (this.archivosErrorUpload !== '') {
          mensaje += this.archivosErrorUpload;
        }
        if (this.archivosErrorMimeType !== '') {
          mensaje += this.archivosErrorMimeType;
        }
        mensaje += '</ul>';
        this._mensajesService.advertenciaPersonalizada(
          '<div class="text-left">' + mensaje + '</div>',
          'Archivos adjuntos'
        );
      } else {
        this._mensajesService.toastSuccess(`Se subieron ${archivosSubidos} archivo(s) con éxito.`);
      }

      //this.hideLoadingPlaceholder();

      this.placeholderLoading = true;
      setTimeout(() => {
        this.placeholderLoading = false;
      }, 500);
    });
  }

  /**
   * Función para guardar los archivos adjuntos
   */
  saveArchivoAdjunto(file, archivoAdjunto, index) {
    let dateActual = new Date();
    let numMlSeconds = dateActual.getTime();
    let addMlSeconds = 10 * index;
    let newDate = new Date(numMlSeconds + addMlSeconds);

    return new Promise((resolve, reject) => {
      // Obtiene los parametros para amazon s3
      this._archivoAdjuntosService.getFile(file, archivoAdjunto, MimeTypeImg)
        .then(() => {
          // Crea la estructura del archivo adjunto para enviar al servidor
          const archivoAdjuntoTmp = {
            nombre: archivoAdjunto.nombre,
            amazonS3Parameters: archivoAdjunto.amazonS3Parameters,
            visualizarCarousel: true,
            fechaCreacion: newDate,
            elemento:
              this.elemento && this.elemento.id ? { id: this.elemento.id } : null,
          };
          this.archivosAdjuntos2.push(archivoAdjuntoTmp);
          // Si el servicio ya existe guarda directamente los archivos adjuntos
          if (this.elemento && this.elemento.id) {
            this._archivoAdjuntosService
              .saveArchivoAdjunto(archivoAdjuntoTmp)
              .subscribe(
                (resp) => {
                  if (resp.success) {
                    // Añade el nuevo archivo guardado a la tabla principal
                    const entidadTmp = JSON.parse(resp.entidad);
                    entidadTmp.fechaCreacion = new Date();
                    this.archivosGuardados.push(entidadTmp);
                    this.actualizarTablaArchivosAdjuntos();
                    this.cargarArchivosAdjuntos(true);
                  }
                  resolve('');
                },
                (err) => {
                  // Setea las variables para marcar error en el archivo adjunto
                  archivoAdjunto.enProceso = false;
                  archivoAdjunto.enError = true;
                  this.archivosErrorUpload +=
                    '<li>' +
                    archivoAdjunto.nombre +
                    ' <b>(Revisa la conexión e intenta subir nuevamente)</b></li>';
                  resolve('');
                }
              );
          } else {
            // Si la solicitud es nueva
            // Actualiza la tabla de archivos adjuntos
            archivoAdjunto.enProceso = false;
            this.actualizarTablaArchivosAdjuntos();
            resolve('');
          }
        });
    });
  }

  /**
   * Función para actualizar la tabla de archivos adjuntos
   */
  actualizarTablaArchivosAdjuntos() {
    const archivos = [];
    this.archivosAdjuntos2.forEach((archivoAdjunto) => {
      const archivosFilter = this.archivosGuardados.filter(
        (archivoTmp) => archivoTmp.nombre === archivoAdjunto.nombre
      );
      if (archivosFilter.length > 0) {
        archivos.push(archivosFilter[0]);
      } else {
        archivos.push(archivoAdjunto);
      }
    });
    this.archivosAdjuntos2 = archivos;
    this.archivosAdjuntos2.sort((a, b) => b.fechaCreacion - a.fechaCreacion); // b - a for reverse sort
    this.archivosAdjuntosOutput.emit(this.archivosAdjuntos2);
  }

  /**
   * Función para llamar a la ventana de selección de archivos
   */
  cargarFilePicker() {
    $('#pickerFile' + this.identificador).click();
  }

  /**
   * Función para eliminar un archivo adjunto
   */
  eliminarArchivoAdjunto(archivo) {
    // Dirty del form principal
    if (this.form) {
      this.form.control.markAsDirty();
    }
    // Eliminar
    if (archivo.id) {
      this._mensajesService
        .confirmacionEliminar()
        .then((confirmacion: boolean) => {
          if (confirmacion) {
            let idComponente = this.elemento.id;
            this._archivoAdjuntosService.deleteByIdArchivoAdjuntoGenerico(archivo.id, idComponente)
              .subscribe(() => {
                if (this.archivosAdjuntos2) {
                  this.archivosAdjuntos2 = this.archivosAdjuntos2.filter(
                    (archivoTmp) => archivoTmp !== archivo
                  );
                  this.cargarArchivosAdjuntos(true).then(() => {
                    this.actualizarTablaArchivosAdjuntos();
                  });
                  this.placeholderLoading = true;
                  setTimeout(() => {
                    this.placeholderLoading = false;
                  }, 100);
                }
              });
          }
        });
    } else {
      this.archivosAdjuntos2 = this.archivosAdjuntos2.filter(
        (archivoTmp) => archivoTmp !== archivo
      );
      this.placeholderLoading = true;
      setTimeout(() => {
        this.placeholderLoading = false;
      }, 100);
    }
    this.archivosAdjuntosOutput.emit(this.archivosAdjuntos2);
  }

  /**
   * Función para actualizar el control de visible al cliente
   */
  emitirArchivosAdjuntos() {
    // Dirty del form principal
    if (this.form) {
      this.form.control.markAsDirty();
    }
    this.archivosAdjuntosOutput.emit(this.archivosAdjuntos2);
  }

  /**
   * Función para reintentar subir un archivo adjunto en el caso de que presente un error.
   */
  reintentarUpload(archivoAdjunto: any) {
    this.archivosErrorUpload = '';
    archivoAdjunto.enProceso = true;
    archivoAdjunto.enError = false;
    this.actualizarTablaArchivosAdjuntos();

    const archivoAdjuntoTmp = {
      nombre: archivoAdjunto.nombre,
      amazonS3Parameters: archivoAdjunto.amazonS3Parameters,
      visualizarCarousel: true,
      elemento:
        this.elemento && this.elemento.id ? { id: this.elemento.id } : null,
    };

    this._archivoAdjuntosService
      .saveArchivoAdjunto(archivoAdjuntoTmp)
      .subscribe(
        (resp) => {
          if (resp.success) {
            const entidadTmp = JSON.parse(resp.entidad);
            entidadTmp.fechaCreacion = new Date();
            this.archivosGuardados.push(entidadTmp);
            this.actualizarTablaArchivosAdjuntos();
          }
        },
        (err) => {
          archivoAdjunto.enProceso = false;
          archivoAdjunto.enError = true;
          this.archivosErrorUpload +=
            '<li>' +
            archivoAdjunto.nombre +
            ' <b>(Revisa la conexión e intenta subir nuevamente)</b></li>';

          if (this.archivosErrorUpload !== '') {
            let mensaje =
              'No se subieron los siguientes archivos por las siguientes causas: <br><ul>';
            if (this.archivosErrorUpload !== '') {
              mensaje += this.archivosErrorUpload;
            }
            mensaje += '</ul>';
            this._mensajesService.advertenciaPersonalizada(
              '<div class="text-left">' + mensaje + '</div>',
              'Verifica los archivos'
            );
          }
        }
      );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    setTimeout(() => {
      if (this.cdr !== null &&
        this.cdr !== undefined &&
        !(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
        this.placeholderLoading = false;
      }

    }, 250);

  }

  zoomImgCarousel(img?: any) {
    document.body.style.overflow = 'hidden';
    const dialogRef = this.dialog.open(ImgFullscreenViewComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.large,
      data: {
        imagen: img
      },
      scrollStrategy: this.overlay.scrollStrategies.noop(),
    });
    dialogRef.afterClosed().subscribe((result) => {
      document.body.style.overflow = '';
    });
  }

  abrirImagenNuevaPestana(img) {
    if (img.url) {
      window.open(img.url, '_blank');
    } else if (img.amazonS3Parameters && img.amazonS3Parameters.base64) {

      // Convertir base64 a Blob
      const byteCharacters = atob(img.amazonS3Parameters.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: img.amazonS3Parameters.filetype });

      // Crear una URL del Blob
      const blobURL = URL.createObjectURL(blob);

      // Abrir la URL en una nueva pestaña
      window.open(blobURL, '_blank');
    }
  }


}
