import { Servicio } from './servicio.model';
import { Usuario } from '../seguridad/usuario.model';


export class Involucrado {
  id?: string;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  usuario?: Usuario;

  constructor() {
    this.id = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.usuario = null;
  }
}
