<app-loading></app-loading>
<router-outlet></router-outlet>
<tour-step-template>
    <ng-template let-step="step">
        <div class="tour-step-content" innerHtml="{{step?.content}}">{{step?.content}}</div>
        <div class="tour-step-navigation">
            <button *ngIf="tourService.hasPrev(step)" class="btn btn-sm btn-default" (click)="tourService.prev()">« {{step?.prevBtnTitle}}</button>
            <button *ngIf="tourService.hasNext(step)" class="btn btn-sm btn-default" (click)="tourService.next()">{{step?.nextBtnTitle}} »</button>
            <button class="btn btn-sm btn-default" (click)="tourService.end()">{{step?.endBtnTitle}}</button>
        </div>
    </ng-template>
</tour-step-template>