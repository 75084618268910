// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

//RXJS
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContenidoNoticiaService extends AbtractCrudBasicoService {

  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(
      http,
      _usuarioService,
      _kendoService,
      _mensajeService,
      'contenidosnoticia',
      'contenido_noticia'
    );
  }

  /**
   * Actualiza el orden de una list de contenidoNoticia.
  */
   actualizarOrdenContenidoNoticia(contenidosNoticia: any[]) {
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });
    // URL
    const url = `${
      url_servicio
    }/${this.pathName.toLowerCase()}/orden`;
    return this.http.put(url, contenidosNoticia ,{ headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return observableThrowError(err);
      })
    );
  }

  /**
   * Obtiene los contenidosNoticia por usuario
  */
  obtenerContenidosNoticiaUsuario() {
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });
    // URL
    const url = `${
      url_servicio
    }/${this.pathName.toLowerCase()}/contenidosnoticianotificacion`;
    return this.http.get(url,{ headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return observableThrowError(err);
      })
    );
  }

  /**
   * Obtiene los contenidosNoticia por usuario y por noticia 
  */
  obtenerContenidosNoticiaUsuarioPorIdNoticia(idNoticia: string) {
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });
    // URL
    const url = `${
      url_servicio
    }/${this.pathName.toLowerCase()}/contenidosnoticiausuario/${idNoticia}`;
    return this.http.get(url,{ headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        return observableThrowError(err);
      })
    );
  }
}
