import { Elemento } from '../inventario/elemento.model';
import { Servicio } from './servicio.model';

export class MaterialUtilizado {
  id?: string;
  cantidad?: number;
  cantidadValidar?: number;
  observacion?: string;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  elemento?: Elemento;

  constructor() {
    this.id = null;
    this.cantidad = null;
    this.cantidadValidar = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.elemento = null;
  }
}
