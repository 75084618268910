import { ServicioMarcaVerificacion } from './servicio-marca-verificacion.model';
import { Usuario } from '../seguridad/usuario.model';

export class Comentario {
  id: string;
  comentario?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  servicioMarcaVerificacion?: ServicioMarcaVerificacion;
  usuario?: Usuario

  // TRANSIENT
  archivosAdjuntos?: any;

  constructor() {
    this.id = null;
    this.comentario = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.servicioMarcaVerificacion = null;
    this.usuario = null;
    this.archivosAdjuntos = null
  }
}
