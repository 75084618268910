import { Usuario } from '../seguridad/usuario.model';
import { ServicioMarcaVerificacion } from './servicio-marca-verificacion.model';

export class DetalleServicioMarcaVerificacion {
    id: string;
    detalle?: string;
    fechaCreacion?: Date;
    version?: number;
    servicioMarcaVerificacion?: ServicioMarcaVerificacion;
    usuario?: Usuario;

  constructor() {
    this.id = null;
    this.detalle = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicioMarcaVerificacion = null;
    this.usuario = null;
  }
}
