import { IdentificacionTipoProducto } from './identificacion-tipo-producto.model';
import { EmpresaContratante } from '../empresa-contratante.model';
export class TipoProducto {
    id: string;
    nombre?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    identificacionTipoProducto?: IdentificacionTipoProducto;
    empresaContratante?: EmpresaContratante;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.identificacionTipoProducto = new IdentificacionTipoProducto();
        this.empresaContratante = new EmpresaContratante();
    }
}
