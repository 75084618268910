// Models
import { EmpresaContratante } from '../empresa-contratante.model';

export class Capacidad {
    id: string;
    descripcion?: string;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresaContratante?: EmpresaContratante;

    constructor() {
        this.id = null;
        this.descripcion = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresaContratante = new EmpresaContratante();
    }
}
