import { AcuerdoServicio } from './acuerdo-servicio.model';
import { Empresa } from '../empresas/empresa.model';

export class EmpresasConvenios {
    id?: string;
    nombrePersonaResponsable?: string;
    receptor?: boolean;
    proveedor?: boolean;
    fechaCreacion?: Date;
    version?: number;
    empresa?: Empresa;
    contratoConvenio?: AcuerdoServicio;
    constructor() {
        this.id = null;
        this.nombrePersonaResponsable = null;
        this.receptor = null;
        this.proveedor = null;
        this.fechaCreacion = null;
        this.version = null;
        this.empresa = null;
        this.contratoConvenio = null;
    }
}
