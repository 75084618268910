import { Component, OnInit, Inject } from '@angular/core';

// Dialogo Angular/Material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-img-cropper',
  templateUrl: './img-cropper.component.html',
  styles: []
})
export class ImgCropperComponent implements OnInit {

  placeholderLoading = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  cropperReady = false;
  aspectRatio = 3 / 4;
  resizeToWidth = 150;
  /**
   * Contructor del componente Cargo
   * @constructor
   * @param {MatDialog} dialog Diálogo Material.
   * @param {MatDialogRef} dialogRef Referencia del diálogo.
   * @param {MAT_DIALOG_DATA} data Data manejada en el diálogo.
   */
  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ImgCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imageChangedEvent = data.event;
    this.aspectRatio = data.aspectRatio ? data.aspectRatio : this.aspectRatio;
    this.resizeToWidth = data.resizeToWidth ? data.resizeToWidth : this.resizeToWidth;
    this.placeholderLoading = true;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(image: string) {
    this.croppedImage = image;
  }
  imageLoaded() {
    this.cropperReady = true;
  }
  imageLoadFailed() {
    console.error('Load failed');
  }
  loadImageFailed() {
    console.error('Load failed');
  }

  save() {
    this.dialogRef.close({
      fotografia: this.croppedImage.base64
    });
  }

  ngOnInit() {
    this. placeholderLoading = false;
  }

  /**
   * Función para cerrar el modal
   */
  closeModal() {
    this.dialogRef.close(false);

  }
}
