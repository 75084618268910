import { Acceso } from '../seguridad/acceso.model';

export class Menu {
    raiz: Acceso;
    hijos: Acceso[];

    constructor() {
        this.raiz = new Acceso();
        this.hijos = [];
    }
}
