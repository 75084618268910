<app-placeholder-loading-form class="animated fadeIn fast" *ngIf="placeholderLoading"></app-placeholder-loading-form>
<div class="animated fadeIn fast" *ngIf="!placeholderLoading">
    <div class="card">
        <div class="card-header card-header-icon card-header-warning">
            <div class="card-icon" data-background-color="orange">
                <i class="material-icons">bar_chart</i>
            </div>
            <h4 class="card-title">Consulta Gráfica
            </h4>
        </div>
        <mat-dialog-content>

            <div class="col-12 text-right">
                <span matTooltip="{{accion.exportar ? 'Exportar Gráfico' : 'No tiene permiso para exportar'}}" matTooltipPosition="above">
                    <button type="button" class="btn btn-sm btn-primary" [disabled]="procesoRegistro.enProceso || !accion.exportar" (click)="exportImg()">
                        <i class="material-icons" *ngIf="!procesoRegistro.enProceso">photo</i>
                        <i class="material-icons fa-spin" *ngIf="procesoRegistro.enProceso">sync</i>
                    </button>
                </span>
            </div>

            <div id="kendoPdf" style="background-color: white;">                
                <div [innerHTML]="getHtml(headerHtml)"></div>
                <div #contentPlaceholder></div>
                <div class="col-12">
                    <kendo-chart #kendoChart [style.minHeight.px]="'400'" [style.height.px]="height">
                        <kendo-chart-series>
                            <kendo-chart-series-item [type]="tipoGrafico" [data]="values" categoryField="categoria" field="cantidad">
                                <kendo-chart-series-item-labels [content]="labelContent" color="#000" background="none">
                                </kendo-chart-series-item-labels>
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-legend [visible]="true"></kendo-chart-legend>
                    </kendo-chart>
                </div>
            </div>

        </mat-dialog-content>
        <div class="card-footer">
            <div class="col-12 text-right">
                <button type="button" (click)="closeModal()" class="btn btn-sm btn-danger">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>
</div>