
import { throwError as observableThrowError, Observable } from 'rxjs';
// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';




// Configuracion
import { url_servicio } from '../../config/config';

// SweetAlert
import Swal from 'sweetalert2'

// Kendo
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from './kendo/kendo.service';
import { map, catchError } from 'rxjs/operators';
//import { CATCH_ERROR_VAR } from '@angular/compiler/src/output/abstract_emitter';
import { MensajesService } from './mensajes.service';

@Injectable()
export class CrudBasicoService {

  constructor(
    private http: HttpClient,
    private _mensajeService: MensajesService,
    private _usuarioService: UsuarioService,
    private _kendoService: KendoService) {
  }

  /**
  * Obtiene el registro por ID
  * @param {number} id - ID del registro
  */
  getByID(tableName: string, id: number): Observable<any> {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${tableName.toLowerCase()}/${id}`;
    return this.http.get(url, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
  * Obtiene el registro por llaves primarias compuestas
  * @param {string[]} id - IDs del registro
  */
  getByPK(tableName: string, id: string[]): Observable<any> {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    const ids = id.toString().trim().replace(',', '/');
    // URL
    const url = `${url_servicio}/${tableName.toLowerCase()}/porpk/${ids}`;
    return this.http.get(url, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  /**
  * Función para crear o editar el registro
  * @param {any} Entidad - Entidad a crear o editar
  */
  save(tableName: string, entidad: any, notificar: boolean = true) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${tableName}`;
    if (entidad.id) { // Editar
      return this.http.put(url, entidad, { headers })
        .pipe(
          map((response: any) => {
            if (response.success) {
              if (notificar) {
                Swal.fire('Información', response.mensaje, 'success');
              }
            } else {
              Swal.fire(response.error.mensaje, response.error.detalle, 'error');
            }
            return response;
          }), catchError(err => {
            this._mensajeService.errorCritico();
            return observableThrowError(err);
          }));
    } else { // Crear
      return this.http.post(url, entidad, { headers })
        .pipe(
          map((response: any) => {
            if (response.success) {
              Swal.fire('Información', response.mensaje, 'success');
            } else {
              Swal.fire(response.error.mensaje, response.error.detalle, 'error');
            }
            return response;
          }), catchError(err => {
            this._mensajeService.errorCritico();
            return observableThrowError(err);
          }));
    }
  }

  /**
  * Función para cambiar de estado el registro
  * @param {any} Entidad - Entidad cambiar de estado
  */
  cambiarEstado(tableName: string, entidad: any) {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${tableName}/cambiarestado/${entidad.id}`;
    return this.http.put(url, {}, { headers })
      .pipe(
        map((response: any) => {
          if (response.success) {
            Swal.fire('Información', response.mensaje, 'success');
          } else {
            Swal.fire(response.error.mensaje, response.error.detalle, 'error');
          }
          return response.success;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  kendo(tableName: string, state: State) {
    return this._kendoService.queryGrid(tableName, state)
      .pipe(
        map(data => {
          return data;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

  modal(tableName: string, state: State): any {
    return this._kendoService.queryModal(tableName, state)
      .pipe(
        map(data => {
          return data;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }
  /**
  * Servicio para obtener la ultima accion de un registro
  */
  log(tableName: string, id): any {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/logauditoria/${tableName}/${id}`;
    return this.http.get(url, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        }));
  }

}
