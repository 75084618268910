export class PorcentajeCalculo {
    id: string;
    nombre?: string;
    valor?: number;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.valor = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
    }
}
