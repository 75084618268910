import { TipoServicio } from './tipo-servicio.model';

export class FaseTrabajo {
  id: string;
  nombre?: string;
  observacion?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  tipoServicio?: TipoServicio;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.tipoServicio = new TipoServicio();
  }
}
