// Angular
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// Configuracion
import { url_servicio } from '../../config/config';

// Kendo
import { State } from '@progress/kendo-data-query';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from './kendo/kendo.service';
import { MensajesService } from '../service.index';
import { StateConsulta } from '../../models/models.index';

export abstract class AbtractCrudBasicoService {
  public loadingGrid: boolean;
  public dataGrid: any[] = [];
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   * @param {string} pathName Nombre del Path (url) del servicio.
   * @param {string} tableName Nombre de la tabla del Servicio.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService,
    protected pathName: string,
    protected tableName: string = pathName,
    protected urlConsulta?: string
  ) { }

  /**
   * Función para obtener una entidad por el ID del registro
   * @param {number} id - ID del registro
   */
  getByID(id: string): Observable<any> {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/${id}`;
    // Petición get para traer el objeto por id
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(HttpErrorResponse => {
        console.error(HttpErrorResponse);
        if (HttpErrorResponse.error) {
          this._mensajeService.error(HttpErrorResponse.error.error);
        } else {
          this._mensajeService.errorCritico();
        }
        return observableThrowError(HttpErrorResponse);
      })
    );
  }

  /**
  * Obtiene el registro por llaves primarias compuestas
  * @param {string[]} id - IDs del registro
  */
  getByPK(id: string[]): Observable<any> {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    const ids = id.toString().trim().replace(',', '/');
    // URL
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/porpk/${ids}`;
    return this.http.get(url, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError(err => {
          if (err.error) {
            this._mensajeService.error(err.error.error);
          } else {
            this._mensajeService.errorCritico();
          }
          return observableThrowError(err);
        }));
  }

  /**
   * Función para crear o editar una entidad
   * @param {any} Entidad - Entidad a crear o editar
   * @param {any} notificar - Propiedad para notificar la transacción (por defecto = true)
   */
  save(entidad: any, notificar: boolean = true) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}`;
    if (entidad.id) {
      // Petición put para editar la entidad
      return this.http.put(url, entidad, { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            if (notificar) {
              this._mensajeService.exito(response.mensaje);
            }
          } else {
            if (notificar) {
              this._mensajeService.error(response.error);
            }
          }
          return response;
        }),
        catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    } else {
      // Petición post para crear la entidad
      return this.http.post(url, entidad, { headers }).pipe(
        map((response: any) => {
          if (response.success) {
            if (notificar) {
              this._mensajeService.exito(response.mensaje);
            }
          } else {
            if (notificar) {
              this._mensajeService.error(response.error);
            }
          }
          return response;
        }),
        catchError(err => {
          this._mensajeService.errorCritico();
          return observableThrowError(err);
        })
      );
    }
  }

  /**
   * Función para eliminar de estado la Entidad
   * @param {string} idEntidad - idEntidad a eliminar
   * @param {any} notificar - Propiedad para notificar la transacción (por defecto = true)
   */
  deleteById(idEntidad: string, notificar: boolean = true) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/${idEntidad}`;
    // Petición put para cambiar el estado de la entidad
    return this.http.delete(url, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          if (notificar) {
            this._mensajeService.exito(response.mensaje);
          }
        } else {
          this._mensajeService.error(response.error);
        }
        return response.success;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
  * Obtiene el registro por llaves primarias compuestas
  * @param {string[]} id - IDs del registro
  */
  deleteByPK(id: string[]): Observable<any> {
    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    const ids = id.toString().trim().replace(',', '/');
    // URL
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/porpk/${ids}`;
    return this.http.delete(url, { headers })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError(err => {
          if (err.error) {
            this._mensajeService.error(err.error.error);
          } else {
            this._mensajeService.errorCritico();
          }
          return observableThrowError(err);
        }));
  }


  /**
   * Función para cambiar de estado la Entidad
   * @param {any} Entidad - Entidad cambiar de estado
   */
  cambiarEstado(entidad: any) {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/cambiarestado/${entidad.id}`;
    // Petición put para cambiar el estado de la entidad
    return this.http.put(url, {}, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          this._mensajeService.exito(response.mensaje);
        } else {
          this._mensajeService.error(response.error);
        }
        return response.success;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener la información para kendo grid
   * @param {State} state - Parámetros de la petición
   */
  getKendo(state: State) {
    this.loadingGrid = true;
    return this._kendoService.queryGrid(this.pathName, state).pipe(
      map(data => {
        if (state.take && isNaN(state.take)) {
          state.take = data.data.length;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener la información para kendo grid
   * @param {State} state - Parámetros de la petición
   */
  getKendoView(state: State) {
    this.loadingGrid = true;
    return this._kendoService.queryGridRefactoring(this.pathName, state).pipe(
      map(data => {
        if (state.take && isNaN(state.take)) {
          state.take = data.data.length;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
  * Obtiene la data para exportar a Excel desde Kendo Grid
  * @param {State} state - Parametros generados por Kendo Grid
  * @description Obtiene toda la información de la base de datos
  */
  getAll(state: State) {
    const stateTmp = Object.assign({}, state);
    delete stateTmp.skip;
    delete stateTmp.take;
    this.loadingGrid = true;
    return this._kendoService.queryGrid(this.pathName, stateTmp).pipe(
      map(data => {
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener la información para Kendo ComboBox
   * @param {State} state - Parámetros de la petición
   */
  getModal(state: State): any {
    return this._kendoService.queryModal(this.pathName, state).pipe(
      map(data => {
        return data;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Servicio para obtener la ultima accion de un registro
   */
  getLog(id): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/logauditoria/${this.tableName}/${id}`;
    // Petición get para obtener el ultimo log de la entidad

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Servicio para obtener la ultima accion de un registro
   */
  getAllLog(id): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/logauditoria/todo/${this.tableName}/${id}`;
    // Petición get para obtener el ultimo log de la entidad

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
  /**
   * Función para obtener la información para kendo grid
   * @param {State} state - Parámetros de la petición
   */
  getConsulta(state: StateConsulta): Observable<any> {
    this.loadingGrid = true;
    return this._kendoService.queryGridConsulta(this.pathName, state, this.urlConsulta).pipe(
      map(data => {
        if (isNaN(state.take)) {
          state.take = data.data.length;
          // this.dataGrid = data.data;
        }
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  putConsulta(parametros: any): Observable<any> {
    this.loadingGrid = true;
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.urlConsulta}`;
    return this.http.put(url, parametros, { headers }).pipe(
      map(data => {
        return data;
      }),
      tap(() => {
        this.loadingGrid = false;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
