import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateBuscarGridComponent } from './template-buscar-grid.component';
import { MaterialModule } from '../../material.module';
import { KendoModule } from '../../kendo.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [TemplateBuscarGridComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule ,
    RouterModule,
    MaterialModule,
    KendoModule
  ],
  exports: [
    TemplateBuscarGridComponent
  ]
})
export class TemplateBuscarGridModule { }
