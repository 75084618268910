import { Usuario } from '../seguridad/usuario.model';
import { PoliticaSeguridad } from "./politica-seguridad.model";
import { TerminoCondicion } from './termino-condicion.model';

export class Consentimiento {

    id: string;
    fechaAceptacion?: Date;
    usuario?: Usuario;
    politicaSeguridad?: PoliticaSeguridad;
    terminoCondicion?: TerminoCondicion;
    fechaCreacion?: Date;
    version?: number;

    constructor() {
        this.id = null;
        this.fechaAceptacion = null;
        this.usuario = null;
        this.politicaSeguridad = null;
        this.terminoCondicion = null;
        this.fechaCreacion = null;
        this.version = null;
    }
}
