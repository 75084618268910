// Angular
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { MensajesService } from '../utilidades/mensajes.service';
import { KendoService } from '../utilidades/kendo/kendo.service';

// Configuracion
import { url_servicio } from '../../config/config';

// rxjs
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

// Kendo
import { KendoUtilsService } from '../utilidades/kendo/kendo-utils.service';
import { State, process } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends AbtractCrudBasicoService {

  @Output() fechaEmiter: EventEmitter<any> = new EventEmitter();

  state: State = {
    skip: 0,
    take: 5,
    sort: [ // Orden por defecto
      { dir: 'desc', field: 'servicio.numeroServicio' }
    ],
    group: []
  };

  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService,
    public _kendoUtilsService: KendoUtilsService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'dashboard');
  }

  /**
   * Función para obtener el número de horas de servicios ejecutados
   */
  getHorasServicios(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/horasservicios/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }


  /**
   * Función para obtener las evaluaciones de los servicios
   */
  getEvaluacionServicios(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/evaluacionservicios/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener las evaluaciones de los servicios del tecnico
   */
   getEvaluacionServiciosTecnico(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/evaluacionserviciostecnico/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener las evaluaciones de los servicios del cliente
   */
   getEvaluacionServiciosCliente(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/evaluacionservicioscliente/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de horas de servicio por tipo 
   */
   getHorasServiciosPorTipo(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/horasserviciosportipo/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de horas de servicio por empresa
   */
   getHorasServiciosPorEmpresa(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/horasserviciosporempresa/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de horas de servicio por unidad de negocio
   */
   getHorasServiciosPorUnidadNegocio(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/horasserviciosporunidadnegocio/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de horas de ocupación de los técnicos
   */
   getHorasOcupacionTecnicos(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/horasocupaciontecnicos/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de tickets por tipo de servicio
   */
   getTicketsPorTipoServicio(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/ticketsportiposervicio/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de solicitudes pendientes
   */
  getServiciosDashboard(urlServicio: string, obtenerData: boolean, state = this.state ) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid('servicio', stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/${urlServicio}/${obtenerData}/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        if (response.data) {
          return (<GridDataResult>{
            data: process(response.data, {
              group: state.group
            }).data,
            total: parseInt(response.total, 10)
          });
        } else {
          return response;
        }
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para obtener el número de solicitudes pendientes
   */
  getCalendario(state = this.state) {

    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));
    this._kendoUtilsService.customizeStateGrid(this.tableName, stateGrid);

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/calendario/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }


  /**
   * Función para obtener los servicios por el calendario
   */
  getConsultaCalendario(state) {
    // State
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    // Headers
    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/${this.pathName}/consultacalendario/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

}
