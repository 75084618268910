// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Configuracion
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { MensajesService } from '../utilidades/mensajes.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresadcgvService{

  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _mensajeService: MensajesService,
  ) {
    
  }


  /**
   * Obtiene la empresa por ruc desde el dcGV
   */
  obtenerPorRuc(ruc: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/empresadcgv/porruc/${ruc.trim()}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        if(response){
          return JSON.parse(response);
        }else{
          return response;
        }
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
  
  /**
   * Obtiene los contactos de la empresa por ruc desde dcGV
   */
  obtenerContactosPorRuc(ruc: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/empresadcgv/contactosruc/${ruc.trim()}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return JSON.parse(response);
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
  
  /**
   * Obtiene las sucursales de la empresa por ruc desde dcGV
   */
  obtenerSucursalesPorRuc(ruc: string) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      empresa_contratante: this._usuarioService.idEmpresaContratanteGlobal
    });

    // URL
    let url = `${url_servicio}/empresadcgv/sucursalesruc/${ruc.trim()}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return JSON.parse(response);
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
