import { Cotizacion } from './cotizacion.model';
import { Usuario } from '../seguridad/usuario.model';

export class ArchivoAdjuntoCotizacion {
  id?: string;
  nombre?: string;
  url?: string;
  cotizacion?: Cotizacion;
  usuario?: Usuario;
  amazonS3Parameters?: any;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.url = null;
    this.cotizacion = null;
    this.usuario = null;
    this.amazonS3Parameters = null;
  }
}
