<div class="ph-item">

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4 empty"></div>
            <div class="ph-col-4" style="height: 65px"></div>
            <div class="ph-col-4 empty"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-2"></div>
            <div class="ph-col-4 "></div>
            <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-2"></div>
            <div class="ph-col-4 "></div>
            <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-2"></div>
            <div class="ph-col-4 "></div>
            <div class="ph-col-2"></div>
        </div>
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-2"></div>
            <div class="ph-col-4 "></div>
            <div class="ph-col-2"></div>
        </div>
    </div>


    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-12 empty"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-12 empty"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-12 empty"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-12 empty"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>

    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4"></div>
            <div class="ph-col-8 empty"></div>
            <div class="ph-col-12"></div>
        </div>
    </div>


</div>