import { Injectable } from '@angular/core';
import { SortDescriptor, GroupDescriptor } from '@progress/kendo-data-query';

@Injectable()
export class KendoUtilsService {

  constructor() { }

  customizeStateGrid(tableName: String, state: any) {
    if (state.filter) {
      this.customizeFilters(tableName, state.filter);
    }
    if (state.sort) {
      state.sort = this.customizeSorts(tableName, state.sort);
    }
    if (state.group) {
      state.group = this.customizeGroups(tableName, state.group);
    }
  }

  customizeFilters(tableName: String, filters: any) {
      if (filters.filters) {
        for ( const filter of filters.filters) {
          this.customizeFilters(tableName, filter);
        }
      } else {
        const filter = filters;
        if (filter.field.indexOf('.') === -1) {
          filter.field = tableName + '.' + filter.field;
        }
        // Si el filtro contiene estado
        if (filter.field.indexOf('estado') >= 0) {
          if ('activo'.indexOf(filter.value) === 0) {
            filter.value = 'ACT';
          } else if ('inactivo'.indexOf(filter.value) === 0) {
              filter.value = 'INA';
          } else if ('temporal'.indexOf(filter.value) === 0) {
              filter.value = 'TMP';
          }
        }
      }
      return true;
  }

  customizeSorts (tableName: String, sorts: SortDescriptor[]) {
    const sortsTmp: SortDescriptor[] = [];
    for ( const sort of sorts) {
      if (sort.dir) {
        if (sort.field.indexOf('.') === -1) {
          sort.field = tableName + '.' + sort.field;
          sortsTmp.push(sort);
        } else {
          sortsTmp.push(sort);
        }
      }
    }
    return sortsTmp;
  }

  customizeGroups (tableName: String, groups: GroupDescriptor[]) {
    const groupsTmp: GroupDescriptor[] = [];
    for ( const group of groups) {
      if (!group.dir) {
        group.dir = 'asc';
      }
      if (group.field.indexOf('.') === -1) {
        group.field = tableName + '.' + group.field;
        groupsTmp.push(group);
      }
    }
    return groupsTmp;
  }

  

}
