export class GraficoHorasServicioTecnico {
    fechaInicio: Date;
    fechaFin: Date;
    idEmpresaContratante: string;
    idsTecnico: string[];
    idsCliente: string[];
    idsTipoServicio: string[];
    constructor() {
        this.fechaInicio = null;
        this.fechaFin = null;
        this.idsCliente = [];
        this.idsTecnico = [];
        this.idsTipoServicio = [];
    }
}
