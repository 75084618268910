import { Empresa } from '../empresas/empresa.model';
import { Persona } from './persona.model';

export class PersonaEmpresa {
  fechaCreacion?: Date;
  version?: number;
  empresa?: Empresa;
  persona?: Persona;

  constructor() {
    this.fechaCreacion = null;
    this.version = null;
    this.empresa = new Empresa();
    this.persona = new Persona();
  }
}
