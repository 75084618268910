// Angular
import { Injectable, EventEmitter } from '@angular/core';

// Enum
import { GApiEnum } from '../../../config/gapi.enum';

// Google API
declare var gapi: any;
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GDriveService {

  selectedEmitter: EventEmitter<string[]> = new EventEmitter();
  openPickerEmitter = new EventEmitter();

  scope: string;
  pickerApiLoaded: boolean;
  oauthToken: any;

  constructor() {}

  onApiLoad() {
    // Setea las variables a su estado por defecto
    this.selectedEmitter = new EventEmitter();
    this.openPickerEmitter = new EventEmitter();
    this.scope = 'https://www.googleapis.com/auth/drive.readonly';
    this.pickerApiLoaded = false;
    this.oauthToken = '';

    gapi.load('auth', {'callback': this.onAuthApiLoad.bind(this)});
    gapi.load('picker', {'callback': this.onPickerApiLoad.bind(this)});
  }

  onAuthApiLoad() {
    gapi.auth.authorize(
      {
        'client_id': GApiEnum.clientId,
        'scope': this.scope,
        immediate: false
      },
      this.handleAuthResult.bind(this));
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
    this.createPicker();
  }

  handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      this.oauthToken = authResult.access_token;
      this.createPicker();
    }else{
      this.openPickerEmitter.emit(false);
    }
  }

  createPicker() {
    if (this.pickerApiLoaded && this.oauthToken) {
      this.openPickerEmitter.emit(true);
      const view = new google.picker.DocsView();
          view.setParent('root');
          view.setIncludeFolders(true);

      const picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .addView(view)
          .setOAuthToken(this.oauthToken)
          .setDeveloperKey(GApiEnum.developerKey)
          .setCallback(this.pickerCallback.bind(this))
          .build();
      picker.setVisible(true);
    }
  }

  // A simple callback implementation.
  pickerCallback(data) {
    const docs = [];
    if (data.action === google.picker.Action.PICKED) {
      data.docs.forEach(doc => {
        docs.push(doc);
      });
      this.selectedEmitter.emit(docs);
    }
  }

  getUrlSelected() {
    return this.selectedEmitter;
  }


}
