<div class="ph-item">
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
    <div class="ph-col-2">
        <div class="ph-picture"></div>
    </div>
</div>