import { Component, OnInit, OnDestroy } from '@angular/core';

// Servicio
import { LoadingService } from './services/service.index';
import { UsuarioService } from './services/seguridad/usuario.service';

// Router
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { TourService } from 'ngx-tour-ngx-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(public _loading: LoadingService,
    private router: Router,
    private _usuarioService: UsuarioService,
    public tourService: TourService) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this._loading.start();
    }
    if (event instanceof NavigationEnd) {
      this._loading.stop();
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this._loading.stop();
    }
    if (event instanceof NavigationError) {
      this._loading.stop();
    }
  }

  ngOnInit() {
    // const notActivityUser = new Idle().whenNotInteractive().within(6, 1000).do(() =>
    //   this.verificarJWTExp()
    // ).start();
    // const activityUser = new NotIdle().whenInteractive().within(1, 1000).do(() =>
    //   this.verificarJWTAlive()
    // ).start();
  }

  
  ngOnDestroy() {

  }
  expirado(fechaExp: number) {
    const ahora = new Date().getTime() / 1000;
    return fechaExp < ahora;
  }


}
