import { TipoServicio } from '../servicio/tipo-servicio.model';
import { PuntoServicio } from '../empresas/punto-servicio.model';
import { Servicio } from '../servicio/servicio.model';
import { AcuerdoServicioSla } from './acuerdo-servicio-sla.model';
import { Elemento } from '../inventario/elemento.model';


export class ServicioPrevisto {
    id?: string;
    fechaProgramadaServicio?: Date;
    horasPrevistaServicio?: number;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    tipoServicio?: TipoServicio
    subtipoServicio?: TipoServicio;
    puntoServicio?: PuntoServicio;
    elemento?: Elemento;
    servicio?: Servicio
    acuerdoServicio?: AcuerdoServicioSla
    //Transient
    periocidad?: number;
    numeroServicioProgramado?: number;
    fechaPrevistaServicio?: Date;
    constructor() {
        this.id = null;
        this.fechaProgramadaServicio = null;
        this.horasPrevistaServicio = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.tipoServicio = null;
        this.subtipoServicio = null;
        this.puntoServicio = null;
        this.elemento = null;
        this.servicio = null;
        this.acuerdoServicio = null;
        //Transient
        this.periocidad = null;
        this.numeroServicioProgramado = null;
        this.fechaPrevistaServicio = null;
    }

}