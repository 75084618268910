import { ChecklistData } from './checklist-data.model';
import { PreguntaChecklist } from './pregunta-checklist.model';

export class ChecklistDetalleObservacion {
  observacion?: string;
  fechaCreacion?: Date;
  version?: number;
  checklistData?: ChecklistData;
  pregunta?: PreguntaChecklist;

  constructor() {
    this.observacion = null;
    this.fechaCreacion = null;
    this.version = null;
    this.checklistData = null;
    this.pregunta = null;
  }
}
