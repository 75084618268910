import { Empresa } from '../empresas/empresa.model';
import { PuntoServicio } from '../empresas/punto-servicio.model';
import { Ubicacion } from '../inventario/ubicacion.model';
import { TipoProducto } from '../inventario/tipo-producto.model';
import { Marca } from '../inventario/marca.model';
import { Modelo } from '../inventario/modelo.model';
import { UnidadMedida } from '../inventario/unidad-medida.model';
import { EmpresaContratante } from '../empresa-contratante.model'
import { Usuario } from '../seguridad/usuario.model';
import { Capacidad } from './capacidad.model';

export class Elemento {
    id: string;
    nombre?: string;
    numeroSerie?: string;
    codigoInterno?: string;
    activoFijo?: boolean;
    disponibleRegistroServicio?: boolean;
    fechaInicioGarantia?: Date;
    fechaFinGarantia?: Date;
    fechaInstalacion?: Date;
    cubreMantenimientoPreventivo?: boolean;
    cubreMantenimientoCorrectivo?: boolean;
    cubreRepuestos?: boolean;
    observacion?: string;
    fechaCreacion?: Date;
    estado?: string;
    version?: number;
    empresa?: Empresa;
    puntoServicio?: PuntoServicio;
    ubicacion?: Ubicacion;
    //Unificacion producto-elemento
    codigo?: string;
    stock?: number;
    stockMinimo?: number;
    stockMaximo?: number;
    precioFob?: number;
    cantidad?: number;
    unidadMedida?: UnidadMedida;
    tipoProducto?: TipoProducto;
    empresaContratante?: EmpresaContratante;
    marca?: Marca;
    modelo?: Modelo;
    capacidad?: Capacidad;
    elementoRaiz?: Elemento;
    usuarioContacto?: Usuario;
    validado?: boolean;
    equipoRentado?: boolean;

    constructor() {
        this.id = null;
        this.nombre = null;
        this.numeroSerie = null;
        this.codigoInterno = null;
        this.activoFijo = false;
        this.disponibleRegistroServicio = true;
        this.fechaInicioGarantia = null;
        this.fechaFinGarantia = null;
        this.fechaInstalacion = null;
        this.cubreMantenimientoPreventivo = null;
        this.cubreMantenimientoCorrectivo = null;
        this.cubreRepuestos = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.estado = null;
        this.version = null;
        this.empresa = new Empresa();
        this.puntoServicio = new PuntoServicio();
        this.ubicacion = new Ubicacion();
        this.codigo = null;
        this.stock = null;
        this.stockMinimo = null;
        this.stockMaximo = null;
        this.precioFob = null;
        this.cantidad = null;
        this.unidadMedida = new UnidadMedida();
        this.tipoProducto = new TipoProducto();
        this.empresaContratante = new EmpresaContratante();
        this.marca = new Marca();
        this.modelo = new Modelo();
        this.elementoRaiz = null;
        this.usuarioContacto = null;
        this.validado = null;
        this.equipoRentado = null;
    }
}
