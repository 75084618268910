import { Servicio } from './servicio.model';

export class EpRepuestoUtilizado {
  id?: string;
  nombre?: string;
  marca?: string;
  modelo?: string;
  codigo?: string;
  cantidad?: number;
  cantidadValidada?: number;
  regularizado?: boolean;
  observacionRegularizacion?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  servicio?: Servicio;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.marca = null;
    this.modelo = null;
    this.codigo = null;
    this.cantidad = null;
    this.cantidadValidada = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
  }
}
