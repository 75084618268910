export const toolbarOptions = [
    ['bold', 'italic', 'underline'],        // toggled buttons
    [{ 'align': [] }],
    ['blockquote', 'code-block', 'link' ],
  
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      
    ['clean']                                         // remove formatting button
  ];

export const formats = [
    'bold',
    'italic',
    'underline',         
    'align',
    'blockquote', 
    'code-block', 
    'link',
    'list',
    'indent',    
  ];