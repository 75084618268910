import { Injectable } from '@angular/core';

import { GridSettings } from './grid-settings.interface';
import { UsuarioService } from '../../seguridad/usuario.service';

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

@Injectable({
    providedIn: 'root'
})
export class StatePersistingKendoService {

    constructor(
        private usuarioService: UsuarioService
    ) { }

    public get<T>(token: string): T {
        const userConfig = this.usuarioService.usuario.userConfig ? JSON.parse(this.usuarioService.usuario.userConfig) : [];
        const settings = userConfig.find((item) => item.token === token);
        return settings ? settings.gridConfig : settings;
    }

    public set<T>(token: string, gridConfig: GridSettings): Promise<void> {

        return new Promise((resolve, reject) => {
            // Obtiene la configuración del usuario
            const userConfig = this.usuarioService.usuario.userConfig ? JSON.parse(this.usuarioService.usuario.userConfig) : [];

            // Valida si el token ya existe en la configuración
            const index = userConfig.findIndex((item) => item.token === token);
            if (index !== -1) {
                // Si el token existe actualiza el valor
                userConfig[index].gridConfig = gridConfig;
            } else {
                // Si el token no existe añade el objeto al array
                userConfig.push({ token: token, gridConfig: gridConfig });
            }

            // Procede a guardar la configuración del usuario
            this.usuarioService.saveUserConfig(JSON.stringify(userConfig)).subscribe(resp => {
                this.usuarioService.usuario.userConfig = JSON.stringify(userConfig);
                resolve();
            });
        });

    }

    public remove(token: string): Promise<void> {
        return new Promise((resolve, reject) => {
            // Obtiene la configuración del usuario
            const userConfig = this.usuarioService.usuario.userConfig ? JSON.parse(this.usuarioService.usuario.userConfig) : [];

            // Valida si el token existe en la configuración
            const index = userConfig.findIndex((item) => item.token === token);
            if (index !== -1) {
                // Si el token existe, elimina el objeto correspondiente
                userConfig.splice(index, 1);

                // Procede a guardar la configuración del usuario actualizada
                this.usuarioService.saveUserConfig(JSON.stringify(userConfig)).subscribe(resp => {
                    this.usuarioService.usuario.userConfig = JSON.stringify(userConfig);
                    resolve();
                });
            } else {
                // Si el token no se encuentra, resuelve la promesa sin hacer cambios
                resolve();
            }
        });
    }

}