import { Acceso } from './acceso.model';
import { Rol } from './rol.model';

export class Accion {
    crear: boolean;
    editar?: boolean;
    ver?: boolean;
    eliminar?: boolean;
    exportar?: boolean;
    acceso?: Acceso;
    rol?: Rol;

    constructor() {
        this.crear = false;
        this.editar = false;
        this.ver = false;
        this.eliminar = false;
        this.exportar = false;
        this.acceso = null;
        this.rol = null;
    }
}
