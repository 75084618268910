//Angular
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

// RXJS
import { NavItem, NavItemType } from './pages.interfaces';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

//Enums
import { SizeModal } from '../config/size-modal.enum';

//Modelos
import { ContenidoNoticia, PoliticaSeguridad } from '../models/models.index';

//Servicios
import { ContenidoNoticiaService, PoliticasSeguridadService, UsuarioService } from '../services/service.index';

//Componentes
import { NavbarComponent } from '../shared/navbar/navbar.component';
import { PerfilPersonaComponent } from '../shared/perfil-persona/perfil-persona.component';
import PerfectScrollbar from 'perfect-scrollbar';
// import {
//   RegistroEmpresaSuscriptoraComponent
// } from './empresa-suscriptoras/registro-empresa-suscriptora/registro-empresa-suscriptora.component';
import { MaterialApoyoComponent } from './soporte/material-apoyo/material-apoyo.component';
import { PoliticaAprobarComponent } from '../public/politica-aprobar/politica-aprobar.component';


import { RegistroEmpresaSuscriptoraComponent } from './refactoring/modulo-empresa-suscriptora/empresa-suscriptora/registro-empresa-suscriptora/registro-empresa-suscriptora.component';

declare const $: any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit, AfterViewInit {

  public navItems: NavItem[];
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  url: string;
  location: Location;

  @ViewChild('sidebar', { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;

  mostrarApp: boolean;

  constructor(private _usuarioService: UsuarioService,
    private _contenidoNoticiaService: ContenidoNoticiaService,
    private _politicasSeguridadService: PoliticasSeguridadService,
    private dialog: MatDialog,
    private router: Router,
    private _overlay: Overlay,
    location: Location,
  ) {
    if (this._usuarioService.requiereAceptarTerminosCondiciones() && this._usuarioService.isSuperadministrador) {
      this.cargarRegistroEmpresaSuscriptora();
      // Si el usuario es nuevo llama al modal para cambiar la contraseña
    } else if (this._usuarioService.isNuevo()) {
      this.cargarPerfil();
      // Si el usuario tiene que aceptar las politicas de seguridad.
    } else if (!this._usuarioService.politicasSeguridadAceptada()) {
      this.verificarAceptacionPoliticas()
    }
    this.location = location;
  }

  ngOnInit() {

    this.cargarNotificacionNoticiaUsuario();

    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
    }
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.navbar.sidebarClose();
      });

    this.navItems = [
      { type: NavItemType.NavbarLeft, title: 'Dashboard', iconClass: 'fa fa-dashboard' },

      {
        type: NavItemType.NavbarRight,
        title: '',
        iconClass: 'fa fa-bell-o',
        numNotifications: 5,
        dropdownItems: [
          { title: 'Notification 1' },
          { title: 'Notification 2' },
          { title: 'Notification 3' },
          { title: 'Notification 4' },
          { title: 'Another Notification' }
        ]
      },
      {
        type: NavItemType.NavbarRight,
        title: '',
        iconClass: 'fa fa-list',

        dropdownItems: [
          { iconClass: 'pe-7s-mail', title: 'Messages' },
          { iconClass: 'pe-7s-help1', title: 'Help Center' },
          { iconClass: 'pe-7s-tools', title: 'Settings' },
          'separator',
          { iconClass: 'pe-7s-lock', title: 'Lock Screen' },
          { iconClass: 'pe-7s-close-circle', title: 'Log Out' }
        ]
      },
      { type: NavItemType.NavbarLeft, title: 'Search', iconClass: 'fa fa-search' },

      { type: NavItemType.NavbarLeft, title: 'Account' },
      {
        type: NavItemType.NavbarLeft,
        title: 'Dropdown',
        dropdownItems: [
          { title: 'Action' },
          { title: 'Another action' },
          { title: 'Something' },
          { title: 'Another action' },
          { title: 'Something' },
          'separator',
          { title: 'Separated link' },
        ]
      },
      { type: NavItemType.NavbarLeft, title: 'Log out' }
    ];
  }
  ngAfterViewInit() {
    this.runOnRouteChange();
  }
  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
      return true;
    } else {
      return false;
    }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }


  /**
   * Función que permite cargar el modal de un registro
   */
  cargarPerfil(): void {
    this.dialog.open(PerfilPersonaComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.large,
      data: {
        usuario: this._usuarioService.usuario
      }
    });
  }
  /**
   * Función que permite cargar el modal de un registro
   */
  cargarRegistroEmpresaSuscriptora(): void {
    this.dialog.open(RegistroEmpresaSuscriptoraComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.small,
      data: {
        idEmpresaContratante: this._usuarioService.usuario.empresaContratante.id,
      }
    });
  }

  //#region NotificacionNoticia
  /**
   * Función que permite mostrar en pantalla la notificación del sistema
   * @returns Notificación o en caso de que no cumpla con las condiciones continua con la ejecución
   */
  cargarNotificacionNoticiaUsuario() {
    this._contenidoNoticiaService.obtenerContenidosNoticiaUsuario().subscribe((contenidosNoticia: ContenidoNoticia[]) => {
      if (!this._usuarioService.usuario.notificacionNoticia || this._usuarioService.isNuevo()) {
        return;
      }

      if (contenidosNoticia && contenidosNoticia.length > 0) {
        this.cargarModalSoporte();
      }
    });
  }

  cargarModalSoporte() {
    const dialogRef = this.dialog.open(MaterialApoyoComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: SizeModal.large,
      data: {
        url: 'https://view.genial.ly/6617dfa4b3aba20014a821f4'
      }
    })
  }

  async verificarAceptacionPoliticas() {

    let politicaSeguridad: any = await this.getUltimaPoliticaSeguridadFinalizada();
    if (politicaSeguridad?.id && politicaSeguridad?.politicaFinalizada) {
      document.body.style.overflow = 'hidden';
      const dialogRef = this.dialog.open(PoliticaAprobarComponent, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          idPoliticaSeguridad: politicaSeguridad.id,
          urlPoliticaSeguridad: politicaSeguridad.url,
          nombrePoliticaSeguridad: politicaSeguridad.nombre
        },
        scrollStrategy: this._overlay.scrollStrategies.noop(),
        width: SizeModal.small
      });
      dialogRef.afterClosed().subscribe((resp) => {
        document.body.style.overflow = '';
        if(!resp){
          this._usuarioService.logout();
        }
      });
    }
  }

  async getUltimaPoliticaSeguridadFinalizada() {
    return new Promise((resolve: any, reject) => {
      this._politicasSeguridadService.getUltimaPoliticaAceptada().subscribe(resp => {        
        resolve(resp);
      });
    });
  }
  //#endregion
}
