<app-placeholder-loading-card *ngIf="placeholderLoading"></app-placeholder-loading-card>
<form #form="ngForm" *ngIf="!placeholderLoading">
    <div class="card">
        <div class="card-header card-header-icon card-header-primary">
            <div class="row">
                <div class="col-8">
                    <div class="card-icon" data-background-color="orange">
                        <i class="material-icons">model_training</i>
                    </div>
                    <h4 class="card-title">{{'LO NUEVO' | uppercase}}
                    </h4>
                </div>
            </div>
        </div>
        <div class="card-body">
            <mat-dialog-content>
                <kendo-scrollview [data]="items" [arrows]="true" [animate]="true" 
                [pageable]="true" width="100%" height="450px">
                    <ng-template let-item="item">
                        <quill-view-html id="prueba" *ngIf="item.contenido" [content]="item.contenido">
                        </quill-view-html>
                    </ng-template>
                </kendo-scrollview>
            </mat-dialog-content>
        </div>

        <div class="card-footer">
            <div class="col-lg-12 col-md-12 d-flex justify-content-end">
                <button type="button" (click)="closeModal()" class="btn btn-sm btn-danger">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>
</form>