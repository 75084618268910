// Angular
import {
  Component,
  Inject,
  ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';

// Dialogo Angular/Material
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

// Modelos
import { Persona, ProcesoRegistro, EmpresaContratante } from '../../models/models.index';

// Enums
import { SizeModal } from '../../config/size-modal.enum';

// RXJS
import { BehaviorSubject } from 'rxjs';

// Kendo JQuery
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

// Componentes
import { ImgCropperComponent } from '../img-cropper/img-cropper.component';

// Servicios
import {
  UsuarioService,
  PersonaService,
  MensajesService,
  ValidarFormularioService,
  EmpresaContratanteService
} from '../../services/service.index';
import { EncriptacionAesService } from '../../services/seguridad/encriptacion-aes.service';

export const enum PasswordCheckStrength {
  Corta,
  Comun,
  Debil,
  Normal,
  Fuerte
}

export enum Colors {
  primary = 'primary',
  accent = 'accent',
  warn = 'warn'
}

export enum Criteria {
  at_least_twelve_chars,
  at_least_one_lower_case_char,
  at_least_one_upper_case_char,
  at_least_one_digit_char,
  at_least_one_special_char
}

@Component({
  selector: 'app-perfil-persona',
  templateUrl: './perfil-persona.component.html',
  styles: []
})
export class PerfilPersonaComponent {
  // Inicialización de variables para el proceso.
  @ViewChild('form', { static: false }) public form: NgForm;
  procesoRegistro: ProcesoRegistro;
  persona: Persona;
  cambiarContrasena: boolean;
  contrasenaConfirmacion: string;
  solicitarContrasenaActual: boolean;

  // Empresa Contratante
  filtroEmpresaContratante = '';
  empresaContratanteTmp: EmpresaContratante = new EmpresaContratante();
  empresasContratantes: GridDataResult = { data: [], total: 0 };
  stateSubjectEmpresaContratante = new BehaviorSubject<string>(null);
  public stateEmpresaContratante: State = {
    skip: 0,
    take: 25,
    sort: [
      // Orden por defecto
      { dir: 'asc', field: 'nombreCorto' }
    ],
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    }
  };

  // Manejo de contraseña
  criteriaMap = new Map<Criteria, RegExp>();
  containAtLeastChars: boolean;
  containAtLeastOneLowerCaseLetter: boolean;
  containAtLeastOneUpperCaseLetter: boolean;
  containAtLeastOneDigit: boolean;
  containAtLeastOneSpecialChar: boolean;
  _strength: number;
  helpPassword: string;

  // Inicialización de variables para el check de contrasena
  newPasswordStrength: number;
  private commonPasswordPatterns: RegExp = /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;

  // Manejo de contrasena
  hideContrasenaActual: boolean;
  hideContrasenaNueva: boolean;
  hideContrasenaConfirmacion: boolean;
  showDetails: boolean;
  porcentajeSeguridad: number = 0;
  mensajes: string;

  /**
   * Contructor del componente
   * @constructor
   * @param {PersonaService} _personaService Servicio de persona.
   * @param {MensajesService} _mensajeServices Servicio para el manejo de mensajes del sistema.
   * @param {ValidarFormularioService} _validarFormularioService Servicio para validar los formularios.
   * @param {MatDialog} dialog Diálogo Material.
   * @param {MatDialogRef} dialogRef Referencia del diálogo.
   * @param {MAT_DIALOG_DATA} data Data manejada en el diálogo.
   */
  constructor(
    private _personaService: PersonaService,
    private _encriptacionAesService: EncriptacionAesService,
    private _mensajeService: MensajesService,
    private _empresaContratanteService: EmpresaContratanteService,
    public _validarFormularioService: ValidarFormularioService,
    private dialog: MatDialog,
    private _usuarioService: UsuarioService,
    public dialogRef: MatDialogRef<PerfilPersonaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // Persona
    this.persona = new Persona();
    
    this.persona.nombreCompleto = data.usuario.persona.nombreCompleto;
    this.persona.fotografia = data.usuario.persona.fotografia;
    this.persona.contrasena = '';
    this.contrasenaConfirmacion = '';
    this.procesoRegistro = new ProcesoRegistro();

    // Contrasenas ocultas
    this.hideContrasenaActual = true;
    this.hideContrasenaNueva = true;
    this.hideContrasenaConfirmacion = true;

    // Si es un usuario nuevo debe obligatoriamente ingresar las contrasenas
    this.cambiarContrasena = false;
    this.procesoRegistro.titulo = 'Perfil de usuario';
    if (data.usuario && data.usuario.nuevo) {
      this.cambiarContrasena = true;
    }

    // Criterios de contraseña
    this.criteriaMap.set(Criteria.at_least_twelve_chars, RegExp(/^.{8,63}$/));
    this.criteriaMap.set(Criteria.at_least_one_lower_case_char, RegExp(/^(?=.*?[a-z])/));
    this.criteriaMap.set(Criteria.at_least_one_upper_case_char, RegExp(/^(?=.*?[A-Z])/));
    this.criteriaMap.set(Criteria.at_least_one_digit_char, RegExp(/^(?=.*?[0-9])/));
    this.criteriaMap.set(Criteria.at_least_one_special_char, RegExp(/^(?=.*?["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));


    this.cargarEmpresaContratante().then(() => {
      if (this.empresasContratantes.total > 1) {
        for (const obj of this.empresasContratantes.data) {
          if (obj.id === this._usuarioService.getEmpresaContratanteDefault()) {
            this.empresaContratanteTmp = obj;
          }
        }
        if (!this.empresaContratanteTmp) {
          this.empresaContratanteTmp = this.empresasContratantes.data[0];
        }
      } else {
        this.empresaContratanteTmp = this.empresasContratantes.data[0];
      }
    });

  }

  /**
   * Función para crear o editar un registro
   * @param {NgForm} forma - Información del formulario
   */
  save(forma: NgForm) {

    if (this.cambiarContrasena && this._strength !== 100) {
      this._mensajeService.error({
        mensaje: 'Contraseña débil',
        detalle: 'Las contraseña no cumple con las polticas de seguridad.'
      });
      return;
    }

    if (this.persona.contrasena === this.contrasenaConfirmacion) {
      if (forma.dirty && forma.valid && !this.procesoRegistro.enProceso) {
        this.procesoRegistro.enEjecucion();
        const persona: any = {
          id: null,
          contrasenaAnterior: this.cambiarContrasena && this.persona.contrasenaAnterior
            ? (this._encriptacionAesService.am(this.persona.contrasenaAnterior))
            : null,
          contrasena: this.cambiarContrasena
            ? (this._encriptacionAesService.am(this.persona.contrasena))
            : null,
          fotografia: this.persona.fotografia,
          idEmpresaContratanteDefault: this.empresaContratanteTmp.id
        };

        this._personaService.savePerfil(persona).subscribe(response => {
          if (response.success) {
            this._usuarioService.generarNuevoToken().subscribe(() => {
              this.procesoRegistro.finalizado();
              this.dialogRef.close();
              window.location.reload();
            });
          }
          this.procesoRegistro.finalizado();
        });
      } else if (!forma.valid) {
        this._validarFormularioService.validateAllFormFields(forma.control);
      }
    } else {
      this._mensajeService.error({
        mensaje: 'Contraseñas invalida',
        detalle: 'Las contraseña nueva y la confirmación no coinciden.'
      });
    }
  }

  /**
   * Función para cerrar el modal
   */
  closeModal() {
    this._validarFormularioService.confirmCloseModal(
      this.form.dirty || this.data.dirty,
      this.dialogRef
    );
  }

  // #region Fotografía

  cargarInputFile() {
    const element: HTMLElement = document.getElementById(
      'inputFile'
    ) as HTMLElement;
    element.click();
  }

  cancelarFotografia() {
    this.form.control.markAsDirty();
    this.persona.fotografia = '../../../assets/img/defaultUser.png';
  }

  cargarPersonalizarImagen(event) {
    const dialogRef = this.dialog.open(ImgCropperComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: SizeModal.medium,
      data: {
        event: event
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.form.control.markAsDirty();
      this.persona.fotografia = result.fotografia;
    });
  }
  // #endregion

  ///////////////////////////////
  ////  Empresas Contrantes ////
  /////////////////////////////

  /**
   * Función para cargar las Empresas Contrantes
   */
  cargarEmpresaContratante(): Promise<string> {
    return new Promise((resolve, reject) => {
      this._empresaContratanteService
        .getModal(this.stateEmpresaContratante)
        .subscribe(empresasContratantes => {
          this.empresasContratantes = empresasContratantes;
          resolve('');
        });
    });
  }

  strengthChange(e) {
    this.porcentajeSeguridad = e;

    switch (e) {
      case 0:
        this.mensajes = "La contraseña es débil.";
        break;
      case 2:
        this.mensajes = "La contraseña es medianamente fuerte.";
        break;
      case 3:
        this.mensajes = "La contraseña es fuerte.";
        break;
      case 4:
        this.mensajes = "La contraseña es muy fuerte.";
        break;
      default:
        this.mensajes = "Ingrese una contrasena segura";
        break;
    }
  }

  // Manejo de contraseña
  calculatePasswordStrength(contrasena: string) {

    const requirements: Array<boolean> = [];
    const unit = 100 / 5;

    this.helpPassword = 'La contraseña al menos debe tener: ';

    requirements.push(
      this._containAtLeastTwelveChars(contrasena),
      this._containAtLeastOneLowerCaseLetter(contrasena),
      this._containAtLeastOneUpperCaseLetter(contrasena),
      this._containAtLeastOneDigit(contrasena),
      this._containAtLeastOneSpecialChar(contrasena)
    );

    this.helpPassword = this.helpPassword.substring(0, this.helpPassword.length - 1);
    this.helpPassword += '.';
    this._strength = requirements.filter(v => v).length * unit;
  }

  private _containAtLeastTwelveChars(password): boolean {
    this.containAtLeastChars = password.length >= 8;

    if (!this.containAtLeastChars) {
      this.helpPassword += ' 8 carácteres,';
    }

    return this.containAtLeastChars;
  }

  private _containAtLeastOneLowerCaseLetter(password): boolean {
    this.containAtLeastOneLowerCaseLetter = this.criteriaMap.get(Criteria.at_least_one_lower_case_char).test(password);

    if (!this.containAtLeastOneLowerCaseLetter) {
      this.helpPassword += ' 1 letra minúscula,';
    }
    return this.containAtLeastOneLowerCaseLetter;
  }

  private _containAtLeastOneUpperCaseLetter(password): boolean {
    this.containAtLeastOneUpperCaseLetter = this.criteriaMap.get(Criteria.at_least_one_upper_case_char).test(password);

    if (!this.containAtLeastOneUpperCaseLetter) {
      this.helpPassword += ' 1 letra mayúscula,';
    }
    return this.containAtLeastOneUpperCaseLetter;
  }

  private _containAtLeastOneDigit(password): boolean {
    this.containAtLeastOneDigit = this.criteriaMap.get(Criteria.at_least_one_digit_char).test(password);

    if (!this.containAtLeastOneDigit) {
      this.helpPassword += ' 1 número,';
    }
    return this.containAtLeastOneDigit;
  }

  private _containAtLeastOneSpecialChar(password): boolean {
    this.containAtLeastOneSpecialChar = this.criteriaMap.get(Criteria.at_least_one_special_char).test(password);

    if (!this.containAtLeastOneSpecialChar) {
      this.helpPassword += ' 1 carácter especial,';
    }
    return this.containAtLeastOneSpecialChar;
  }

  get strength(): number {
    return this._strength ? this._strength : 0;
  }

  get color(): string {
    if (this._strength <= 20) {
      return Colors.warn;
    } else if (this._strength <= 80) {
      return Colors.accent;
    } else {
      return Colors.primary;
    }
  }

  // Expected length of all passwords
  public static get MinimumLength(): number {
    return 5;
  }

  //
  // Checks if the given password matches a set of common password
  //
  public isPasswordCommon(password: string): boolean {
    return this.commonPasswordPatterns.test(password);
  }

  //
  // Returns the strength of the current password
  //
  public checkNewPasswordStrength(password: string) {
    // Build up the strenth of our password
    let numberOfElements = 0;
    numberOfElements = /.*[a-z].*/.test(password) ? ++numberOfElements : numberOfElements; // Lowercase letters
    numberOfElements = /.*[A-Z].*/.test(password) ? ++numberOfElements : numberOfElements; // Uppercase letters
    numberOfElements = /.*[0-9].*/.test(password) ? ++numberOfElements : numberOfElements; // Numbers
    numberOfElements = /[^a-zA-Z0-9]/.test(password) ? ++numberOfElements : numberOfElements; // Special characters (inc. space)
    // Assume we have a poor password already
    this.newPasswordStrength = PasswordCheckStrength.Corta;

    // Check then strenth of this password using some simple rules
    if (password === null || password.length < PerfilPersonaComponent.MinimumLength) {
      this.newPasswordStrength = PasswordCheckStrength.Corta;
    } else if (this.isPasswordCommon(password) === true) {
      this.newPasswordStrength = PasswordCheckStrength.Comun;
    } else if (numberOfElements === 0 || numberOfElements === 1 || numberOfElements === 2) {
      this.newPasswordStrength = PasswordCheckStrength.Debil;
    } else if (numberOfElements === 3) {
      this.newPasswordStrength = PasswordCheckStrength.Normal;
    } else {
      this.newPasswordStrength = PasswordCheckStrength.Fuerte;
    }
  }

}
