// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { map, catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// State, kendo
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

@Injectable({
  providedIn: 'root'
})
export class InventarioComponentesService extends AbtractCrudBasicoService{

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName' , urlconsulta
    super(
      http,
      _usuarioService,
      _kendoService,
      _mensajeService,
      'consultaInventarioComponentes',
      '',
      'consultas/inventario/componentes'
    );
  }

  getConsultaReporteComponentes(state) {
    this.loadingGrid = true;
    const stateGrid: State = JSON.parse(JSON.stringify(state));

    const headers = new HttpHeaders({
      'token_autorizado': this._usuarioService.token_autorizado,
      'Content-Type': 'application/json',
      'empresa_contratante': this._usuarioService.idEmpresaContratanteGlobal
    });

    let url = `${url_servicio}/${this.urlConsulta}/`;
    url += `${this.serialize({ data: JSON.stringify(stateGrid) })}`;

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        if(response.data) {
          this.loadingGrid = false;
          return response.data ? ({
            data: response.data,
            total: parseInt(response.total , 10),
            aggregates: response.aggregates
          } as GridDataResult)
          : response;
        } else {
          this.loadingGrid = false;
          return response;
        }
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  // Serializa el state del GRID
  serialize(obj: any): string {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
