import { Pipe, PipeTransform } from '@angular/core';

// Moment
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  /**
   * Función para validar si existe la imagen de las entidades del sistema
   * @param value URL de la imagen
   * @param args Nombre de la entidad para validar la imagen, por defecto Usuario
   */
  transform(date: Date, args: string = 'dateTime'): any {

    if(!date){
      return;

    }

    const newDate = new moment(date).toDate();
    moment.locale('es');
    switch (args) {
      case 'yyyy':
        return new moment(newDate).format('YYYY');
      case 'date':
        return new moment(newDate).format('DD-MMM-YYYY');
      case 'time':
        return new moment(newDate).format('HH:mm');
      case 'dateTime':
        return new moment(newDate).format('DD-MMM-YYYY HH:mm');
      case 'getName':
        return new moment(newDate).format('DDMMYYYYHHmm');
      case 'dateExcel':
        return new moment(newDate).format('DD/MM/YYYY');
      case 'dateTimeExcel':
        return new moment(newDate).format('DD/MM/YYYY HH:mm');
      case 'timeExcel':
        return new moment(newDate).format('HH:mm');
      default:
        return new moment(newDate).format('DD-MMM-YYYY HH:mm');
    }
  }
}
