import { Checklist } from './checklist.model';
import { TipoCategoriaChecklist } from './tipo-categoria-checklist.model';

export class CategoriaChecklist {
  id?: string;
  fechaCreacion?: Date;
  version?: number;
  checklist?: Checklist;
  tipoCategoriaChecklist?: TipoCategoriaChecklist;

  constructor() {
    this.id = null;
    this.fechaCreacion = null;
    this.version = null;
    this.checklist = null;
    this.tipoCategoriaChecklist = null;
  }
}
