<div class="wrapper wrapper-full-page">
  <div id="wrapper" class="error-page">
    <div class="error-box">
      <div class="error-body text-center">
        <img src="../../../assets/img/error-404-dcgs.png" height=291 width=581>
        <br>
        <a [routerLink]="['/dashboard']" class="btn btn-primary btn-rounded waves-effect waves-light m-b-40">Volver a la
          Página Principal</a>
      </div>
    </div>
  </div>

  <footer class="footer" style="width: 100% !important; margin-left: 0px !important;">
    <div class="container text-center">
      <div class="copyright">
        Desarrollado por <a class="help" href="https://www.decision.com.ec/" target="_blank"><b>DECISIÓN
            c.a.</b></a> {{version}}
        | Copyright &copy; 2013 - {{fechaCopyright | dateTimeFormat: 'yyyy'}} |
        <a class="help" [routerLink]="['/terminos-condiciones']"><b>Términos y privacidad.</b></a> | <a class="help"
          href="https://www.decision.com.ec/decisioncloud-gestion-servicios/" target="_blank"><b>Mayor
            información.</b></a>
      </div>
    </div>
  </footer>
</div>