<div class="row">
    <div class="col-12">
        <div class="ph-item">
            <div class="ph-col-10">
                <div class="label-floating ancho-total">
                    <input id="buscar" #buscar placeholder="Buscar..." name="buscar" type="search" class="form-control" />
                </div>
            </div>
            <div class="ph-col-2">
                <div class="col-12 text-right">
                    <button class="btn btn-primary btn-round btn-fab">                          
                    <span class="mat-button-wrapper">
                      <i class="material-icons">add</i>
                    </span>
                 </button>
                </div>
            </div>

            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-12 empty"></div>
                </div>
            </div>

            <div class="ph-col-6">
                <div class="ph-row">
                    <div class="ph-col-12 big"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12 big"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12 big"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12 big"></div>
                </div>
            </div>


            <div class="ph-col-6">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>

            <div class="ph-col-6">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>

            <div class="ph-col-6">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>

            <div class="ph-col-6">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>

            <div class="ph-col-6">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-2">
                <div class="ph-row">
                    <div class="ph-col-12"></div>
                </div>
            </div>

            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-12 empty"></div>
                </div>
            </div>
        </div>
    </div>
</div>