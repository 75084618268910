export enum MimeTypeImg {
    allowedMimeType = 'image/png,image/jpeg',
    notAllowedMimeType = 'application/x-ms-dos-executable,' +
    'application/octet-stream,' +
    'application/x-msdownload,' +
    'application/exe,' +
    'application/x-exe,' +
    'application/dos-exe,' +
    'vms/exe,' +
    'application/x-winexe,' +
    'application/msdos-windows,' +
    'application/x-msdos-program' +
    'application/x-msi' +
    'application/pdf' +
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
    'text/csv' +
    'audio/mpeg' +
    ''
}

export enum MimeTypeFile {
    allowedMimeType = 'application/pdf' +
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
    'text/csv',
    notAllowedMimeType = 'application/x-ms-dos-executable,' +
    'application/octet-stream,' +
    'application/x-msdownload,' +
    'application/exe,' +
    'application/x-exe,' +
    'application/dos-exe,' +
    'vms/exe,' +
    'application/x-winexe,' +
    'application/msdos-windows,' +
    'application/x-msdos-program' +
    'application/x-msi' +
    'image/png,image/jpeg' +
    ''
}


export enum MimeTypeAll {
    allowedMimeType = 'application/pdf' +
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
    'text/csv' +
    'image/png,image/jpeg',
    notAllowedMimeType = 'application/x-ms-dos-executable,' +
    'application/octet-stream,' +
    'application/x-msdownload,' +
    'application/exe,' +
    'application/x-exe,' +
    'application/dos-exe,' +
    'vms/exe,' +
    'application/x-winexe,' +
    'application/msdos-windows,' +
    'application/x-msdos-program' +
    'application/x-msi' +

    ''
}
