// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';

@Injectable({
    providedIn: 'root'
})
export class ZonaService extends AbtractCrudBasicoService {
    /**
     * Contructor del Servicio
     * @constructor
     * @param {HttpClient} http Servicio http de angular.
     * @param {UsuarioService} _usuarioService Servicio de usuario.
     * @param {KendoService} _kendoService Servicio kendo.
     * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
     */
    constructor(
        public http: HttpClient,
        public _usuarioService: UsuarioService,
        public _kendoService: KendoService,
        public _mensajeService: MensajesService
    ) {
        // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
        super(
            http,
            _usuarioService,
            _kendoService,
            _mensajeService,
            'zona',
            'zona'
        );
    }
}
