//Angular
import { Injectable } from '@angular/core';

//Servicios
import { DateTimeFormatPipe } from 'src/app/pipes/date-time-format.pipe';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { State } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DocumentsMethodsService {

  constructor(
    private dateTimeFormatpipe: DateTimeFormatPipe
  ) { }

  //Genera el nombre del archivo con sus respectiva extension pdf/xlsx
  obtenerNombreArchivo(nombreArchivo: string, tipoDocumento: string): string {
    return `${nombreArchivo}${this.dateTimeFormatpipe.transform(
      new Date(),
      'getName'
    )}.${tipoDocumento}`;
  }

  //Exporta a un documento .xlsx 
  allData = (state: any, servicio: any, kendoView = false): Promise<ExcelExportData> => {
    // Carga de datos para kendo Grid

    const stateLocal: State = JSON.parse(JSON.stringify(state));
    stateLocal.take = null;
    
    if(kendoView){
      return servicio
      .getKendoView(stateLocal)
      .pipe(
        map((data: any) => {
          const result: ExcelExportData = {
            data: data.data,
            group: stateLocal.group
          };
          return result;
        })
      )
      .toPromise();
    } else {
      return servicio
      .getKendo(stateLocal)
      .pipe(
        map((data: any) => {
          const result: ExcelExportData = {
            data: data.data,
            group: stateLocal.group
          };
          return result;
        })
      )
      .toPromise();
    }    
  }
}
