import { ChecklistData } from './checklist-data.model';
import { RespuestaChecklist } from './respuesta-checklist.model';
import { PreguntaChecklist } from './pregunta-checklist.model';

export class ChecklistDetalle {
  id?: string;
  texto?: string;
  valor?: number;
  fila?: number;
  columna?: number;
  correcta?: boolean;
  observacion?: string;
  checklistData?: ChecklistData;
  pregunta?: PreguntaChecklist;
  respuesta?: RespuestaChecklist;

  constructor() {
    this.id = null;
    this.texto = null;
    this.valor = null;
    this.fila = null;
    this.columna = null;
    this.correcta = null;
    this.observacion = null;
    this.checklistData = null;
    this.pregunta = null;
    this.respuesta = null;
  }
}
