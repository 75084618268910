import { Injectable } from '@angular/core';

// Kendo
import { State } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root'
})
export class StatesMethodsService {

  constructor() { }


  stateGenericoDefaultBasico(take: number, orden: any, campoOrdenar: string ): State {
    return {
      skip: 0,
      take: take,
      sort: [
        // Orden por defecto
        { dir: orden, field: campoOrdenar }
      ],
      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: []
      }
    };
  }
}
