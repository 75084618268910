//Angular
import { Component, Inject, OnInit } from '@angular/core';

//Modelos
import { ContenidoNoticia } from 'src/app/models/models.index';

//Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-template-notificacion-noticia',
  templateUrl: './template-notificacion-noticia.component.html',
  styles: [`
    :host ::ng-deep quill-view-html .ql-editor {
      padding: 0px !important;
    }
  `]
})
export class TemplateNotificacionNoticiaComponent implements OnInit {

  
  items: ContenidoNoticia[] = [];
  placeholderLoading: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<TemplateNotificacionNoticiaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(this.data.contenidosNoticia && this.data.contenidosNoticia.length > 0){
      this.items = this.data.contenidosNoticia;
      this.placeholderLoading = false;
    }else{
      this.closeModal();
    }
  }

  ngOnInit() {
  }

  closeModal(){
    this.dialogRef.close();
  }

}
