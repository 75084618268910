export class TipoCobertura {
    id: string;
    nombre?: string;
    observacion?: string;
    fechaCreacion?: Date;
    recargo?: number;
    version?: number;
    estado?: string;
    constructor() {
        this.id = null;
        this.nombre = null;
        this.observacion = null;
        this.fechaCreacion = null;
        this.version = null;
        this.recargo = null;
    }
}
