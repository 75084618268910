import { TipoRecargo } from "./tipo-recargo.model";
import { HorarioTrabajoEmpleado } from "./horario-trabajo-persona.model";

export class DetalleHorarioTrabajoEmpleado {
  id: string;
  dia?: string;
  orden?: number;
  horaInicio?: Date;
  horaFin?: Date;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  horarioTrabajoEmpleado?: HorarioTrabajoEmpleado;
  tipoRecargo?: TipoRecargo;
  constructor() {
    this.id = null;
    this.dia = null;
    this.orden = null;
    this.horaInicio = null;
    this.horaFin = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.horarioTrabajoEmpleado = null;
    this.tipoRecargo = null;
  }
}
