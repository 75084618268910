<form #form="ngForm" (submit)="save(form)" class="animated fadeIn fast"
      style="font-size: 12px !important">
    <div class="card">
        <div class="card-header card-header-icon card-header-warning">
            <div class="card-icon" data-background-color="orange">
                <i class="material-icons">account_circle</i>
            </div>
            <h4 class="card-title">
                {{procesoRegistro.titulo}}:
                <small>{{persona.nombreCompleto}}</small>
            </h4>
        </div>
        <div class="card-body">
            <mat-dialog-content>
                <div class="row">
                    <div class="col-md-6 text-center">
                        <div class="row" class="titulo">
                            <span class="gap8">
                                <i class="material-icons">account_circle</i>
                                <span>FOTOGRAFÍA</span>
                            </span>
                        </div>
                        <!-- ========== -->
                        <!-- Fotografía -->
                        <!-- ========== -->
                        <div class="thumbnail img-circle">
                            <img style="max-height: 200px" [src]="persona.fotografia">
                        </div>
                        <input id="inputFile" type="file"
                               (change)="cargarPersonalizarImagen($event)" hidden="true" />
                        <button type="button"
                                [disabled]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                (click)="cargarInputFile()" class="btn btn-round mat-raised-button">
                            <span class="mat-button-wrapper">
                                <i class="material-icons">add_photo_alternate</i>
                            </span>
                        </button>
                        <button type="button"
                                [disabled]="procesoRegistro.enDetalle || procesoRegistro.enProceso"
                                (click)="cancelarFotografia()"
                                class="btn btn-round mat-raised-button" mat-raised-button="">
                            <span class="mat-button-wrapper">
                                <i class="material-icons">close</i>
                            </span>
                        </button>
                        <!-- ================ -->
                        <!-- FIN - Fotografía -->
                        <!-- ================ -->
                    </div>

                    <div class="col-md-6 margin-input">

                        <div class="row" class="titulo" *ngIf="empresasContratantes.total > 1">
                            <span class="gap8">
                                <i class="material-icons">lock</i>
                                <span>PROVEEDOR DE SERVICIO</span>
                            </span>
                        </div>

                        <div class="col-12" *ngIf="empresasContratantes.total > 1">

                            <!-- EMPRESA -->
                            <div class="col-md-12 margin-input">
                                <div class="kendoWithIcon">
                                    <kendo-floatinglabel text="Empresa proveedora">
                                        <kendo-combobox id="empresaContratante"
                                                        name="empresaContratante"
                                                        [(ngModel)]="empresaContratanteTmp"
                                                        required
                                                        [data]="empresasContratantes.data"
                                                        [textField]="'nombreCorto'"
                                                        [valueField]="'id'"
                                                        [placeholder]="'Empresa Contratante'"
                                                        [suggest]="true" [filterable]="true">
                                            <ng-template kendoDropDownListNoDataTemplate>
                                                <h4>Información no encontrada<br /><br />
                                                </h4>
                                            </ng-template>
                                        </kendo-combobox>
                                    </kendo-floatinglabel>
                                    <a class="cursor help"
                                       matTooltip="Usted se encuentra registrado en dos o más empresas proveedoras, elija la empresa con la que requiere iniciar sesión por defecto."
                                       matTooltipPosition="above">
                                        <i class="material-icons">info</i>
                                    </a>
                                </div>
                                <div
                                     [ngSwitch]="_validarFormularioService.isFieldValid(form, 'empresaContratante')">
                                    <kendo-formerror class="errorKendo" *ngSwitchCase="true">
                                        El campo <strong>'Empresa Contratante'</strong> es
                                        requerido.
                                    </kendo-formerror>
                                </div>
                            </div>
                        </div>

                        <div class="row" class="titulo" >
                            <span class="gap8">
                                <i class="material-icons">lock</i>
                                <span>CONTRASEÑA</span>
                            </span>
                        </div>

                        
                        <!-- ==================== -->
                        <!-- MANEJO DE CONTRASENA -->
                        <!-- ==================== -->
                        <div class="col-12 margin-input text-right"
                             *ngIf="data.usuario && !data.usuario.nuevo">
                            <mat-slide-toggle id="cambiarContrasena"
                                              [disabled]="procesoRegistro.enProceso || (data.usuario && data.usuario.nuevo)"
                                              name="cambiarContrasena"
                                              [(ngModel)]="cambiarContrasena">
                                Cambiar contraseña
                            </mat-slide-toggle>
                        </div>

                        <div class="col-12 margin-input"
                             *ngIf="cambiarContrasena && !data.usuario.nuevo">
                            <mat-form-field>
                                <input matInput placeholder="Contraseña actual" required
                                       [disabled]="procesoRegistro.enProceso" minlength="6"
                                       maxlength="24" id="contrasenaActual" name="contrasenaActual"
                                       [(ngModel)]="persona.contrasenaAnterior"
                                       [type]="hideContrasenaActual ? 'password' : 'text'">
                                <mat-icon class="cursor" matSuffix
                                          (click)="hideContrasenaActual = !hideContrasenaActual">{{hideContrasenaActual
                                    ? 'visibility' : 'visibility_off'}}</mat-icon>
                                <mat-error
                                           *ngIf="_validarFormularioService.isFieldValid(form, 'contrasenaActual')">
                                    El campo <strong>'Confirmar contraseña'</strong> es requerido
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="cambiarContrasena">
                            <mat-form-field>
                                <input matInput placeholder="Nueva contraseña" required
                                       [disabled]="procesoRegistro.enProceso" minlength="8"  maxlength="24"
                                       name="contrasenaNueva"
                                       (keyup)="calculatePasswordStrength(persona.contrasena)"
                                       [(ngModel)]="persona.contrasena"
                                       [type]="hideContrasenaNueva ? 'password' : 'text'">
                                <mat-icon class="cursor" matSuffix
                                          (click)="hideContrasenaNueva = !hideContrasenaNueva">
                                    {{hideContrasenaNueva ? 'visibility' :
                                    'visibility_off'}}</mat-icon>
                                <mat-hint align="end" style="color: red"
                                          *ngIf="helpPassword && (!containAtLeastChars || !containAtLeastOneLowerCaseLetter || !containAtLeastOneUpperCaseLetter || !containAtLeastOneDigit || !containAtLeastOneSpecialChar)">
                                    {{helpPassword}}
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="col-12" *ngIf="cambiarContrasena"
                             [style.margin-top]="(!containAtLeastChars || !containAtLeastOneLowerCaseLetter || !containAtLeastOneUpperCaseLetter || !containAtLeastOneDigit || !containAtLeastOneSpecialChar) ? '24px' : null">
                            <mat-form-field>
                                <input matInput placeholder="Confirmar contraseña" required
                                       [disabled]="procesoRegistro.enProceso || _strength != 100"
                                       minlength="8"  maxlength="24" name="contrasenaConfirmacion"
                                       [(ngModel)]="contrasenaConfirmacion"
                                       [type]="hideContrasenaConfirmacion ? 'password' : 'text'">
                                <mat-icon class="cursor" matSuffix
                                          (click)="hideContrasenaConfirmacion = !hideContrasenaConfirmacion">
                                    {{hideContrasenaConfirmacion ? 'visibility' :
                                    'visibility_off'}}</mat-icon>
                                <mat-hint align="end" style="color: #4caf50"
                                          *ngIf="persona.contrasena.length > 4 && persona.contrasena === contrasenaConfirmacion">
                                    Contraseña confirmada correctamente.</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-12 margin-input text-right" style="color: red"
                         *ngIf="data.usuario.nuevo">
                        <mat-hint align="end">Por favor ingrese la nueva contraseña para
                            continuar.</mat-hint>
                    </div>
                </div>
            </mat-dialog-content>
        </div>
        <div class="card-footer">
            <div class="col-lg-6 col-md-6 text-left">
                <div class="category form-category">
                    <span style="color: red">*</span> Campos requeridos
                </div>
            </div>
            <div class="col-lg-6 col-md-6 text-right">
                <button *ngIf="!procesoRegistro.enDetalle" [disabled]="procesoRegistro.enProceso"
                        type="submit" class="btn btn-sm btn-primary">
                    <i class="material-icons" *ngIf="!procesoRegistro.enProceso">save</i>
                    <i class="material-icons fa-spin" *ngIf="procesoRegistro.enProceso">sync</i>
                </button>
                <button type="button" (click)="closeModal()"
                        [disabled]="procesoRegistro.enProceso || data.usuario.nuevo "
                        class="btn btn-sm btn-danger">
                    <i class="material-icons">close</i>
                </button>
            </div>
        </div>
    </div>
</form>