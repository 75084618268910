export class TipoEstadoServicio {
  id: string;
  nombre?: string;
  observacion?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
  }
}
