import { PreguntaChecklist } from './pregunta-checklist.model';

export class RespuestaChecklist {
  id?: string;
  numero?: number;
  numeroInicial?: number;
  numeroFinal?: number;
  respuesta?: string;
  correcta?: boolean;
  fila?: number;
  columna?: number;
  pregunta?: PreguntaChecklist;

  constructor() {
    this.id = null;
    this.numero = null;
    this.numeroInicial = null;
    this.numeroFinal = null;
    this.respuesta = null;
    this.fila = null;
    this.columna = null;
    this.pregunta = null;
  }
}
