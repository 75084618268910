// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// RXJS
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Archivo de configuración
import { url_servicio } from '../../config/config';

// Servicios
import { UsuarioService } from '../seguridad/usuario.service';
import { KendoService } from '../utilidades/kendo/kendo.service';
import { MensajesService } from '../utilidades/mensajes.service';

// Servicio Abstracto
import { AbtractCrudBasicoService } from '../utilidades/abstract-crud-basico.service';
import { PreguntaChecklist } from '../../models/models.index';

// SweetAlert
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class PreguntaChecklistService extends AbtractCrudBasicoService {
  /**
   * Contructor del Servicio
   * @constructor
   * @param {HttpClient} http Servicio http de angular.
   * @param {UsuarioService} _usuarioService Servicio de usuario.
   * @param {KendoService} _kendoService Servicio kendo.
   * @param {MensajesService} _mensajeService Servicio para el manejo de mensajes.
   */
  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService,
    public _kendoService: KendoService,
    public _mensajeService: MensajesService
  ) {
    // http, _usuarioService, _kendoService, _mensajeService, 'pathName', 'tableName'
    super(http, _usuarioService, _kendoService, _mensajeService, 'pregunta');
  }

  /**
   * Servicio para obtener las preguntas del checklist
   */
  getPreguntas(idChecklist: string): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/${this.pathName.toLowerCase()}/porchecklist/${idChecklist}`;
    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Función para ordenar las preguntas
   * @param {PreguntaChecklist[]} preguntas - Accesos a ordenar
   */
  ordenar(perguntas: PreguntaChecklist[]) {
    // Headers
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // URL
    const url = `${url_servicio}/${this.tableName}/orden`;
    return this.http.put(url, perguntas, { headers }).pipe(
      map((response: any) => {
        if (response.success) {
          Swal.fire('Información', 'Preguntas ordenadas con éxito', 'success');
        } else {
          Swal.fire(response.error.mensaje, response.error.detalle, 'error');
        }
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }

  /**
   * Servicio para obtener la ultima accion del orden de acceso
   */
  getLogOrden(): any {
    // Obtiene el header de la petición
    const headers = new HttpHeaders({
      token_autorizado: this._usuarioService.token_autorizado,
      'Content-Type': 'application/json'
    });
    // Arma la url de la petición
    const url = `${url_servicio}/logauditoria/${this.tableName}/PREGUNTA_ORDEN`;
    // Petición get para obtener el ultimo log de la entidad

    return this.http.get(url, { headers }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(err => {
        this._mensajeService.errorCritico();
        return observableThrowError(err);
      })
    );
  }
}
