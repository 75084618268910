import { Servicio } from "./servicio.model";
import { Usuario } from "../seguridad/usuario.model";

export class HoraServicioTransporte {
  id?: string;
  fechaInicio?: Date;
  fechaFin?: Date;
  totalHoras?: number;
  fechaCreacion?: Date;
  version?: number;
  servicio?: Servicio;
  usuario?: Usuario;
  zonaHoraria?: string;

  constructor() {
    this.id = null;
    this.fechaInicio = null;
    this.fechaFin = null;
    this.fechaCreacion = null;
    this.version = null;
    this.servicio = null;
    this.usuario = null;
    this.zonaHoraria = null;
  }
}
