import { TipoMarcaVerificacion } from './tipo-marca-verificacion.model';
import { EmpresaContratante } from '../empresa-contratante.model';

export class MarcaVerificacion {
  id: string;
  nombre?: string;
  usoInterno?: boolean;
  controlFecha?: boolean;
  gestionable?: boolean;
  etiquetaControlFecha?: boolean;
  observacion?: string;
  fechaCreacion?: Date;
  estado?: string;
  version?: number;
  tipoMarcaVerificacion?: TipoMarcaVerificacion;
  empresaContratante?: EmpresaContratante;

  constructor() {
    this.id = null;
    this.nombre = null;
    this.usoInterno = null;
    this.controlFecha = null;
    this.gestionable = null;
    this.etiquetaControlFecha = null;
    this.observacion = null;
    this.fechaCreacion = null;
    this.estado = null;
    this.version = null;
    this.tipoMarcaVerificacion = new TipoMarcaVerificacion();
    this.empresaContratante = new EmpresaContratante();
  }
}
